import React, { Component } from 'react';
import { Formik, ErrorMessage, FormikProps, FormikActions } from 'formik';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import { Log } from 'ng2-logger';

import * as Types from '../../store/types';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { BackupFormValidation } from './validations/backup-form-val';
import { BackupFormInitialValues } from '../../store/constants/backup-const';

import Spinner from '../../components/templates/spinner';

import Translator from '../../services/translate-factory';
const T = Translator.create();

const equal = require('deep-equal');
const Logger = Log.create('BackupForm');

function getInitialState(): Types.IBackupFormState {
  const initialValues: Types.IBackupFormState = {
    model: Object.assign({}, BackupFormInitialValues)
  };
  return Object.assign({}, initialValues);
}

class BackupForm extends Component<Types.IBackupFormProps, Types.IBackupFormState> {
  state: Types.IBackupFormState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    document.onkeyup = (e: KeyboardEvent) => {
      if (this.props.formIsOpen && e.key === 'Escape') {
        this.setClose();
      }
    };
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.onClose(refresh);
    }
  };

  setCloseForm = () => {
    this.setClose();
  };

  onFormSave = (model: Types.IBackupItem, FormActions: FormikActions<Types.IBackupItem>) => {

    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (result && result.code) {
        let errors: any = {};
        if (result.details) {
          const validations: Array<Types.IValidationResponse> = result.details;
          validations.forEach((m: Types.IValidationResponse) => {
            errors[m.field] = m.message[0];
          });
        }

        FormActions.setErrors(errors);
      }
      if (status === 200 || status === 201) {
        this.setClose(true);
      }
    };

    if (this.props.backupId) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.backup.BACKUP_UPDATE, model, 'backup-form-spin', resultCallback)
      );
    } else {
      this.props.dispatch(
        Actions.ApiRequest(Constants.backup.BACKUP_CREATE, model, 'backup-form-spin', resultCallback)
      );
    }
    FormActions.setSubmitting(false);
  };

  static getDerivedStateFromProps(props: Types.IBackupFormProps, state: Types.IBackupFormState) {
    let hasNewState: boolean = false;
    if (props.backupId && props.backupId != state.model.backup_id) {
      state.model.backup_id = props.backupId;
      props.dispatch(Actions.ApiRequest(Constants.backup.BACKUP_GET_BY_ID, props.backupId, 'backup-form-spin'));
      hasNewState = true;
    }
    if (props.form && props.backupId && props.backupId == state.model.backup_id) {
      state.model = props.form;
      hasNewState = true;
    }
    if (hasNewState) {
      return state;
    } else if (!props.backupId && state.model.backup_id) {
      return getInitialState();
    } else return null;
  }

  render() {
    return (
      <Modal modalClassName="modal-from-right" className="pt-0" isOpen={this.props.formIsOpen}>
        <Spinner name="backup-form-spin" />
        <Formik
          enableReinitialize={true}
          initialValues={this.state.model}
          onSubmit={(values, actions) => {
            this.onFormSave(values, actions);
          }}
          validationSchema={BackupFormValidation(T)}
        >
          {(props: FormikProps<Types.IBackupItem>) => {
            const { values, handleChange, errors, handleBlur, handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit}>
                <div className="" id="addNew">
                  <div className="modal-dialog" role="document">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title">
                          <span>
                            {this.props.backupId ? <><i className="material-icons mr-2">edit</i>{T.t('gen_update_backup')}</> : <><i className="material-icons mr-2">add_circle_outline</i> {T.t('gen_add_backup')}</>}
                          </span>
                        </h5>
                        <button
                          id='button_close'
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                          onClick={this.setCloseForm}
                        >
                          <i className="material-icons">close</i>
                          <span>ESC</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <div className="row">
                          <div className="col-md-12 form-input form-group with-icon">
                            <textarea
                              name="description"
                              className="form-input"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              rows={2}
                              id="description"
                              value={values.description || ''}
                              placeholder={T.t('gen_description')}
                            />
                            <span className="highlight" />
                            <span className="bar" />
                            <label htmlFor="description" />
                            {errors && errors.description && props.submitCount > 0 && (
                              <div className="error">{errors && errors.description}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="modal-footer d-block">
                        <div className="row">
                          <div className="col-md">
                            <button
                              id='button_cancel'
                              type="button"
                              data-dismiss="modal"
                              className="secondary xl min-auto"
                              aria-label="Close"
                              onClick={this.setCloseForm}
                            >
                              {T.t('gen_cancel')}
                            </button>
                          </div>
                          <div className="col-md text-md-right">
                            <button
                              id='button_save'
                              type="button"
                              data-dismiss="modal"
                              aria-label="alert-success"
                              onClick={() => props.handleSubmit()}
                              className="primary xl"
                            >
                              <i className="material-icons mr-2">save</i> {T.t('gen_save')}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IBackupFormProps): Types.IBackupFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IBackupFormProps = Object.assign({}, ownProps, {
    form: store.state.backup_page && store.state.backup_page.form ? store.state.backup_page.form : getInitialState(),
    selectOptions: store.state.select_options && store.state.select_options.backupsPage
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.backup_page) {
    return (
      !!equal(
        prev.state.backup_page && prev.state.backup_page.form,
        next.state.backup_page && next.state.backup_page.form
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.backupsPage,
        next.state.select_options && next.state.select_options.backupsPage
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(BackupForm);

export default container;
