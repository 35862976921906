import React from 'react';
import { Log } from 'ng2-logger';
import PlainLoyout from '../layouts/plain-layout';
import imgLogo from '../../assets/logos/favicon.png';
import ctLogo from '../../assets/logos/ct_long.png';
import ctLogoNavy from '../../assets/logos/ct_long_navy.png';
import LoginForm from './login-form';
import Spinner from '../../components/templates/spinner';
import AppFactory from '../../services/app-factory';
import * as GT from '../../tools/general-tools';
import { routes as Routes } from '../../store/constants/routes';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import CourseSolutionModal from './course-solution-modal';
import ExamSolutionModal from './exam-solution-modal';
import TermSelectorModal from './term/term-selector-solution-modal';
import * as Types from '../../store/types';
import { connect } from 'react-redux';
import Translator from '../../services/translate-factory';
import advancityLogo from '../../assets_aplan/icon/advancity_logo.png';
import { UserRole } from '../../store/constants/enums';
import PublicSketches from '../sketches/public-sketches';
import { SettingsEventModuleStatus } from '../../store/constants/setting-const';
import PublicEventCalendar from '../event-period/public';
import { TermType } from '../../store/constants/general';

const L = Log.create('LoginPage');
const AF = AppFactory.create();
const T = Translator.create();

declare const appSettings: Types.IAppSettings;
class LoginPage extends React.Component<Types.ILoginPageProps, Types.ILoginPageState> {
  state: Types.ILoginPageState = {
    activeArea: 'login',
    loginForm: {
      username_loginpage: '',
      password_loginpage: '',
      account_id: ''
    },
    forgotForm: {
      email: '',
      account_id: '',
      token: '',
      password: '',
      password_confirm: '',
      usernameOrEmail: ''
    },
    termDefinationModalIsOpen: false,
    term_id: -1,
    term_type: -1,
    solution_id: -1,
    publicSolution: null,
    institutionName: ''
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  getPublicSolution = (term_type: number) => {
    this.props.dispatch(
      Actions.ApiRequest(
        Constants.solution.COURSE_SOLUTION_PUBLIC, term_type, 'solution-public-spinner'
      )
    );
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);

    this.getGeneralSettings();

    this.state.solution_id = Number(window.location.pathname.substr(window.location.pathname.lastIndexOf("/") + 1, window.location.pathname.length));

    let language_default = this.props.general_settings && this.props.general_settings.language_default;
    if (language_default && language_default.length) {
      T.changeLanguage(language_default, true);
    }

    if ('/login/course/' + window.location.pathname.substr(window.location.pathname.lastIndexOf("/") + 1, window.location.pathname.length) == window.location.pathname) {

      this.getPublicSolution(0);

      setTimeout(() => {
        const solution = this.props.publicSolution && this.props.publicSolution.find((term: any) => term.solutionId == this.state.solution_id);
        this.state.publicSolution = solution;

        if (solution) {
          this.onTermChange(solution.termId, solution.term_type, this.state.solution_id, this.state.institutionName);

          this.setState({
            ...this.state,
            termDefinationModalIsOpen: !this.state.termDefinationModalIsOpen
          });
          if (this.state.termDefinationModalIsOpen && this.state.term_type === 1) {
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN, '/exam/' + this.state.solution_id)));
          } else if (this.state.termDefinationModalIsOpen && this.state.term_type === 0) {
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN, '/course/' + this.state.solution_id)));
          } else {
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN)));
          }
        } else {
          this.props.dispatch(
            Actions.ShowModal({
              body: T.t('gen_warning_course_public_solution_unpublished'),
              name: 'course_public_solution',
              icon: 'info',
              iconColor: 'blue',
              cancel: T.t('gen_ok')
            })
          );
        }

      }, 400);
    }

    if ('/login/exam/' + window.location.pathname.substr(window.location.pathname.lastIndexOf("/") + 1, window.location.pathname.length) == window.location.pathname) {

      this.getPublicSolution(1);

      setTimeout(() => {
        const solution = this.props.publicSolution && this.props.publicSolution.find((term: any) => term.solutionId == this.state.solution_id);
        this.state.publicSolution = solution;

        if (solution) {
          this.onTermChange(solution.termId, solution.term_type, this.state.solution_id, this.state.institutionName);

          this.setState({
            ...this.state,
            termDefinationModalIsOpen: !this.state.termDefinationModalIsOpen
          });
          if (this.state.termDefinationModalIsOpen && this.state.term_type === 1) {
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN, '/exam/' + this.state.solution_id)));
          } else if (this.state.termDefinationModalIsOpen && this.state.term_type === 0) {
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN, '/course/' + this.state.solution_id)));
          } else {
            this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN)));
          }
        } else {
          this.props.dispatch(
            Actions.ShowModal({
              body: T.t('gen_warning_exam_public_solution_unpublished'),
              name: 'exam_public_solution',
              icon: 'info',
              iconColor: 'blue',
              cancel: T.t('gen_ok')
            })
          );
        }

      }, 400);
    }

    let eventModuleRoles: string[] = [UserRole.EVENT_MANAGER, UserRole.EVENT_USER, UserRole.STAFF];
    if (AF.hasLoggedOn()) {
      if (this.props.user && eventModuleRoles.includes(this.props.user.role)) {
        window.location.pathname = GT.Route(Routes.STATIC_CLASSROOM);
      } else {
        window.location.pathname = GT.Route(Routes.EXAM_PERIODS);
      }
    }
  }

  getGeneralSettings() {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status == 200) {
        let generalSettings: any = result;
        let language_default = generalSettings && generalSettings.language_default;
        if (language_default && language_default.length) {
          T.changeLanguage(language_default, true);
        }
      }
    };
    this.props.dispatch(Actions.ApiRequest(Constants.setting.GET_GENERAL_SETTINGS, '', 'general-settings-spin', resultCallback));
  }

  switchTermDefinationModalStatus = (term_type: number) => {

    this.getPublicSolution(term_type);

    this.showModal();

  };

  showModal = (confirmDisabled: boolean = false, noCloseButton: boolean | undefined = undefined) => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_select_term'),
        body: <TermSelectorModal
          publicSolution={this.props.publicSolution}
          onTermChange={this.onTermChange}
        />,
        name: 'term_select',
        icon: '',
        iconColor: 'red',
        confirm: T.t('gen_select'),
        cancel: T.t('gen_cancel'),
        onConfirm: this.onTermSelect,
        confirmDisabled: confirmDisabled,
        noCloseButton: false
      })
    );
  };

  onTermSelect = () => {
    this.setState({
      ...this.state,
      termDefinationModalIsOpen: !this.state.termDefinationModalIsOpen
    });

    if (!this.state.termDefinationModalIsOpen && this.state.term_type === 1) {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN, '/exam/' + this.state.solution_id)));
    } else if (!this.state.termDefinationModalIsOpen && this.state.term_type === 0) {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN, '/course/' + this.state.solution_id)));
    } else if (this.state.term_type === TermType.EVENT) {
      this.openCalendar(this.state.term_id);
    } else {
      this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN)));
    }
  };

  onTermChange = (term_id: number, term_type: number, solution_id: number, institutionName: string) => {
    if (this.props.term_id === -1 && term_id > 0) {
      this.showModal(false);
    }
    this.state.term_id = term_id;
    this.state.term_type = term_type;
    this.state.solution_id = solution_id;
    this.state.institutionName = institutionName;
    this.setState(this.state);
  };

  publicSketchesOnClick = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN + Routes.SKETCHES)));
  }

  openCalendar = (term_id: number) => {
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.LOGIN + `/${term_id}` + Routes.EVENT_PERIOD_CALENDAR)));
  }

  static getDerivedStateFromProps(props: Types.ILoginPageProps, state: Types.ILoginPageState) {
    let hasNewState: boolean = false;
    if (props.publicSolution) {
      hasNewState = true;
      state.publicSolution = props.publicSolution;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  checkEventModuleActive = () => {
    const { general_settings } = this.props;
    return (
      general_settings && general_settings.event_module_status === SettingsEventModuleStatus.Active
    );
  };

  checkSketchesActive = () => {
    const campusSketches = this.props.general_settings && this.props.general_settings.show_public_campus_settlements
    const buildingSketches = this.props.general_settings && this.props.general_settings.show_public_building_settlements
    return campusSketches || buildingSketches
  }

  checkEventsActive = () => {
    return this.props.general_settings && this.props.general_settings.show_public_event_calendar
  }

  checkPublicSolutionActive = () => {
    return this.props.general_settings && (this.props.general_settings.show_public_exam_solution || this.props.general_settings.show_public_course_solution)
  }

  checkPublicCourseActive = () => {
    return this.props.general_settings && this.props.general_settings.show_public_course_solution
  }

  checkPublicExamActive = () => {
    return this.props.general_settings && this.props.general_settings.show_public_exam_solution
  }

  render() {
    if (AF.hasLoggedOn()) return <React.Fragment />;
    return (
      <PlainLoyout>
        <div className="login-wrapper">
          <div className="min-container">
            <div className="login-block">
              <div style={{ padding: "-30px" }}>
                <Spinner name="login-form-spin" />
                <div className='ctLogoContainer'>
                  <img className="ctLogo" src={ctLogo} alt="CT Logo" />
                  <span className="ctSubTitle">{T.t('gen_aplan')}</span>
                </div>
              </div>
              {this.checkPublicSolutionActive() ? (
                <div className="white-container tw-my-4">
                  <h4 className="primary-glyph show-programs-tag">{T.t("gen_academic_plans")}</h4>
                  <div className="login-button-container">
                    {this.checkPublicCourseActive() ? (
                      <button className="tertiary xl" type="submit" onClick={() => this.switchTermDefinationModalStatus(TermType.COURSE)}>
                        {T.t('gen_course_solution')}
                      </button>
                    )
                      : null}
                    {this.checkPublicExamActive() ? (
                      <button className="tertiary xl" type="submit" onClick={() => this.switchTermDefinationModalStatus(TermType.EXAM)}>
                        {T.t('gen_exam_solution')}
                      </button>
                    )
                      : null}
                  </div>
                </div>
              ) : null}
              {this.checkEventModuleActive() && (this.checkSketchesActive() || this.checkEventsActive())
                && <div className="white-container tw-my-4">
                  <h4 className="primary-glyph show-programs-tag">{T.t('gen_event_and_venue_planning')}</h4>
                  <div className="login-button-container">
                    {this.checkEventsActive() &&
                      <button className="tertiary xl" type="submit" onClick={() => this.switchTermDefinationModalStatus(TermType.EVENT)}>
                        {T.t('gen_events')}
                      </button>}
                    {this.checkSketchesActive() &&
                      <button className="tertiary xl" type="submit" onClick={() => this.publicSketchesOnClick()}>
                        {T.t('sketches')}
                      </button>
                    }
                  </div>
                </div>}
              <div className='tw-my-12'>
                <LoginForm />
              </div>
              <div style={{ paddingTop: "60px" }}>
                <div className="dropdown">
                  <button
                    className="ghost"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i className="material-icons mr-1">language</i>
                    <i className="material-icons ml-3 arrow">unfold_more</i>
                  </button>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a className="dropdown-item" href="#" onClick={() => T.changeLanguage('tr-TR', true)}>
                      Türkçe
                    </a>
                    <a className="dropdown-item" href="#" onClick={() => T.changeLanguage('en-EN', true)}>
                      English
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex login-block-footer">
              <a href="https://constructor.tech" target="_blank" className="tw-mr-3">
                <img src={ctLogoNavy} alt="Constructor Tech Logo" />
                <span>2001-{new Date().getFullYear()} © {T.t("gen_all_rights_reserved")} Constructor Tech</span>
              </a>
            </div>
          </div>
        </div>
        {
          this.state.termDefinationModalIsOpen && this.state.term_type === 0 ? (
            <CourseSolutionModal
              modalIsOpen={this.state.termDefinationModalIsOpen}
              onClose={this.onTermSelect}
              selectedPublicSolution={this.state}
            />
          ) : null
        }
        {
          this.state.termDefinationModalIsOpen && this.state.term_type === 1 ? (
            <ExamSolutionModal
              modalIsOpen={this.state.termDefinationModalIsOpen}
              onClose={this.onTermSelect}
              selectedPublicSolution={this.state}
            />
          ) : null
        }
        <PublicSketches />
        <PublicEventCalendar />
      </PlainLoyout >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ILoginPageProps): Types.ILoginPageProps => {
  if (!store || !store.state) {
    return ownProps;
  }

  const newProps: any = Object.assign({}, ownProps, {
    account: store.state.account,
    definitions: store.state.definitions,
    publicSolution: store.state.solution_page && store.state.solution_page.publicSolution,
    settings: store.state.settings,
    general_settings: store.state.general_settings,
    user: store.state.user,
  });

  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps)(LoginPage);

export default container;