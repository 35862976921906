import React from 'react';
import { Log } from 'ng2-logger';
import PlainLoyout from '../layouts/plain-layout';
import imgLogoFooter from '../../assets/logos/ct_long_navy.png';
import imgLogo from '../../assets/logos/ct_long.png';
import PasswordForm from './password-form';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { connect } from 'react-redux';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

const L = Log.create('ForgotPwPage');
const T = Translator.create();
let url: string = window.location.href;
let token: string = url.substring(url.lastIndexOf('reNewPass?') + 10) || url.substring(url.lastIndexOf('renewpass?') + 10)

class ForgotPwPage extends React.Component<any, any> {

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);

    this.getGeneralSettings();

    let language_default = this.props.general_settings && this.props.general_settings.language_default;
    if (language_default && language_default.length) {
      T.changeLanguage(language_default, true);
    }
  }

  getGeneralSettings() {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status == 200) {
        let generalSettings: any = result;
        let language_default = generalSettings && generalSettings.language_default;
        if (language_default && language_default.length) {
          T.changeLanguage(language_default, true);
        }
      }
    };
    this.props.dispatch(Actions.ApiRequest(Constants.setting.GET_GENERAL_SETTINGS, '', 'general-settings-spin', resultCallback));
  }
  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  render() {
    return (
      <PlainLoyout>
        <div className="login-wrapper">
          <div className="min-container">
            <div className="login-block">
              <div className='ctLogoContainer'>
                <img className="ctLogo" src={imgLogo} alt="Constructor Schedule" />
                <span className="ctSubTitle">{T.t('gen_aplan')}</span>
              </div>
              <PasswordForm match={this.props.match} code={token} />
              <div className="dropdown">
                <button
                  className="secondary dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <i className="material-icons mr-1">language</i>
                  <i className="material-icons ml-3 arrow">unfold_more</i>
                </button>
                <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a className="dropdown-item" href="#" onClick={() => T.changeLanguage('tr-TR', true)}>
                    {T.t('gen_lang_tr')}
                  </a>
                  <a className="dropdown-item" href="#" onClick={() => T.changeLanguage('en-EN', true)}>
                    {T.t('gen_lang_en')}
                  </a>
                </div>
              </div>
            </div>
            <div className="d-flex login-block-footer">
              <a href="" data-toggle="modal" data-target="#coypright-modal">
                <img src={imgLogoFooter} alt="APlan" />
                <span>2001-{new Date().getFullYear()} © {T.t('gen_all_rights_reserved')} Constructor Tech</span>
              </a>
            </div>
          </div>
        </div>
      </PlainLoyout>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ILoginPageProps) => {
  if (!store || !store.state) {
    return ownProps;
  }

  const newProps: any = Object.assign({}, ownProps, {
    //token: '26WeQVeW6oUG0quKOtcnPDRYvYaAQ1SmbGqUB8VZNdQ='
  });

  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps)(ForgotPwPage);

export default container;
