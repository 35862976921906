import { Formik, FormikActions, FormikProps, ErrorMessage } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import moment from 'moment';
import Flatpickr from 'react-flatpickr';
import { Modal } from 'reactstrap';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import {
  ExamPeriodCourseTermLectureLocations,
  Grades
} from '../../../store/constants/course-const';
import { SolutionEditModalInitialValues } from '../../../store/constants/solution-const';
import MultiSelectionTableClassroom from './multi-selection-table-classroom';
import MultiSelectionTableUpdateProgram from './multi-selection-table-update-program';
import Translator from '../../../services/translate-factory';
import { SolutionEditModalValidation } from './validations/solution-edit-modal-val';
import * as GT from '../../../tools/general-tools';

const T = Translator.create();
const L = Log.create('SolutionEditModal');

let multiSelectedRow: boolean = false;
let saveButtonClicked: boolean = false;
let combined_values_selected: Array<any> = new Array<any>();

let instructor_ids_labels: Array<any> = new Array<any>();
let instructors_labels: Array<any> = new Array<any>();
let faculty_ids_labels: Array<any> = new Array<any>();
let faculties_labels: Array<any> = new Array<any>();
let program_ids_labels: Array<any> = new Array<any>();
let programs_labels: Array<any> = new Array<any>();
let sections_labels: Array<any> = new Array<any>();
let grades_labels: Array<any> = new Array<any>();

interface ISolutionEditModalExtend {
  initialCVS: Array<any>
}

function getInitialState(): Types.SolutionEditModalState & ISolutionEditModalExtend {
  const initialValues: Types.SolutionEditModalState & ISolutionEditModalExtend = {
    isAdded: false,
    alreadyInTheList: false,
    alreadyInTheProgramList: false,
    all_ids: [],
    selected_ids: [],
    week: '',
    locale: '',
    initialCVS: []
  };
  return Object.assign({}, initialValues);
}

class SolutionEditModal extends Component<any, Types.SolutionEditModalState & ISolutionEditModalExtend> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };
  constructor(props: any) {
    super(props)
    this.state.locale = GT.getLocaleFromLangCode();
  }
  componentDidMount() {
    this.initClassroom()
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);

    let newModel = {
      term_id: this.props.term_id,
      solution_id: this.props.solutionId,
      course_id: this.props.courseId,
    };
    this.props.dispatch(Actions.ApiRequest(Constants.solution.SOLUTION_GET_COURSE_PROGRAMS, newModel, 'solution-course-programs-list-spin'));
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    // this.initClassroom()
    this.spliceSubModalValues();
  }

  getSolutionCoursePrograms() {
    this.putToTable(this.createCourseProgramsFieldValues(), false);
  }

  spliceSubModalValues() {
    combined_values_selected && combined_values_selected.splice(0, combined_values_selected.length);
  }

  getProgramsByFacultiesAtSolution = (facultyId: Number) => {
    let model = {
      faculty_id: facultyId,
      term_id: this.props.term_id
    };

    this.props.dispatch(Actions.ApiRequest(Constants.solution.SOLUTION_COURSE_GET_PROGRAMS_BY_FACULTIES, model, 'solution-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.spliceSubModalValues();
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  static getDerivedStateFromProps(props: any, state: Types.SolutionEditModalState & ISolutionEditModalExtend) {
    let hasNewState: boolean = false;

    if (props && props.examDates) {
      hasNewState = true;
      const propsEndDate = props.examDates.end_date
      let propsEndHour = props.examDates.end_hour
      if (propsEndHour === "00:00") {
        propsEndHour = "23:55"
      }
      const startDate = moment(props.examDates.start_date).toDate();
      const endDate = moment(propsEndDate).toDate();
      const endHour = moment(propsEndHour, 'HH:mm').format('H');
      const max = parseInt(endHour, 10);

      state.minDate = moment(startDate).format('YYYY-DD-MM');
      state.minHour = props.examDates.start_hour;
      state.maxDate = moment(endDate).format('YYYY-DD-MM');
      const maxHour = moment(max, 'H').format('HH:mm');
      state.maxHour = maxHour;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onSolutionEdit(values: any, FormActions: FormikActions<Types.IFilterSolutionCourseEditModal>) {
    if (saveButtonClicked) {

      const resultCallback = (result: any, status: number) => {
        if (status === 200) {
          this.props.dispatch(Actions.Notification(result.course_id + ' ' + T.t('notification_all_data_for_courses_changed'), 'gen_success'));
          this.setClose(true);
        }
      };

      let newModel = {
        term_id: this.props.term_id,
        solution_id: this.props.solutionId,
        solution_view_course_id: this.props.solutionEvent.view_id,
        course_id: this.props.courseId,
        start_date: values.start_date && values.start_hour ? values.start_date + ' ' + values.start_hour : null,
        exam_duration: values.exam_duration ? values.exam_duration : this.props.solutionEvent.examDuration,
        //student_count: values.student_count ? values.student_count : this.props.solutionEvent.studentCount,
        //campus_id: values.campuses ? values.campuses.value : this.props.solutionEvent.campus === ExamPeriodCourseTermLectureLocations(T)[0].label ? ExamPeriodCourseTermLectureLocations(T)[0].value : 0,
        //instructor_ids: instructor_ids_labels ? instructor_ids_labels : [],
        //session_count: values.session_count ? values.session_count : this.props.solutionEvent.sessionCount,
        classroom_ids: values.campuses ?
          (values.campuses.value === ExamPeriodCourseTermLectureLocations(T)[0].value ?
            [ExamPeriodCourseTermLectureLocations(T)[0].value] : (combined_values_selected.map((item: any) => item.classroom_id) ? combined_values_selected.map((item: any) => item.classroom_id) : []))
          : combined_values_selected.map((item: any) => item.classroom_id) ? combined_values_selected.map((item: any) => item.classroom_id) : [],
        faculty_ids: combined_values_selected.map((item: any) => item.invigilator_id || 0),
        invigilator_ids: combined_values_selected.map((item: any) => item.invigilator_id || 0) ? combined_values_selected.map((item: any) => item.invigilator_id || 0) : [],
        student_counts: combined_values_selected.map((item: any) => item.student_count) ? combined_values_selected.map((item: any) => item.student_count) : [],
        // program_ids: program_ids_labels ? program_ids_labels : [],
        // faculty_ids: faculty_ids_labels ? faculty_ids_labels : [],
        // grades: grades_labels ? grades_labels : [],
        // sections: sections_labels ? sections_labels : ['1']
      };

      this.props.dispatch(
        Actions.ApiRequest(Constants.solution.SOLUTION_EDIT_COURSE, newModel, 'course-form-spin', resultCallback)
      );

      saveButtonClicked = false;
      FormActions.setSubmitting(false);
    }
  }

  disableEditButton = (values: Types.IFilterSolutionCourseEditModal) => {
    let disableValue: boolean = false;
    if (values.selected_ids) {
      if (values.selected_ids.includes(0)) {
        if (!values.start_date || !values.start_hour)
          disableValue = true;
      }
      return disableValue;
    }
    return true
  };

  refreshValuesClosedSelectedTypes = (values: Types.IFilterSolutionCourseEditModal) => {
    if (values.selected_types.length == 0) {
      values.start_date = null
      values.start_hour = null;
      values.exam_duration = undefined;
      values.course_code = "";
      values.course_name = "";
      values.campuses = [];
      values.session_count = undefined;
      values.student_count = "";
      values.faculties = [];
      values.grades = [];
      values.section = "";
      values.instructors = [];
      values.programs = [];
      values.invigilator = undefined;
      values.classroom = undefined;
      values.classroom_student_count = undefined;
      this.spliceSubModalValues();
    }
  }

  multiSelectedsClassroom(
    combined_values: any
  ) {
    combined_values_selected = combined_values;
  }

  initClassroom() {
    const parsedClassrooms = this.props.solutionEvent.classrooms
      .filter((x: { session: number }) => x.session === 1)
      .flatMap((val: {
        classroomId: number;
        code: string;
        classroomType: number;
        classroomName: string;
        classroomCode: string;
        session: number;
        classroomStudentCount: number;
        examCapacity: number;
        invigilators: Array<{ id: number; title: string; name: string }>;
      }, index: number) => {
        if (val.invigilators.length === 0) {
          return [{
            "classroom_id": val.classroomId,
            "classroom": {
              "label": val.classroomName + " (" + val.classroomCode + ") - " + val.examCapacity,
              "value": val.classroomId
            },
            "invigilator_id": undefined,
            "invigilator": undefined,
            "student_count": val.classroomStudentCount,
            "index": index + 1
          }];
        }

        return val.invigilators.map((invigilator, i) => ({
          "classroom_id": val.classroomId,
          "classroom": {
            "label": val.classroomName + " (" + val.classroomCode + ") - " + val.examCapacity,
            "value": val.classroomId
          },
          "invigilator_id": invigilator.id,
          "invigilator": {
            "label": invigilator.title + " " + invigilator.name,
            "value": invigilator.id
          },
          "student_count": val.classroomStudentCount,
          "index": index + 1
        }));
      });

    this.setState((prev) => ({
      ...prev,
      initialCVS: parsedClassrooms,
    }))
  }

  messageForEmptyClassrooms() {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_error'),
        body: (
          <div className="form-input form-group">
            <p>
              {T.t('gen_list_isnot_left_blank')}
            </p>
            <p className="text-center">{T.t('gen_select_classroom')}</p>
          </div>
        ),
        name: 'empty_classrooms_message',
        icon: '',
        iconColor: 'red',
        cancel: T.t('gen_close')
      })
    );
  }

  //#region MultiSelectionTableUpdateProgram

  putToTable = (values: any, plusButtonClicked: boolean) => {
    instructors_labels.length == 0 && plusButtonClicked == false ? instructors_labels = instructors_labels.concat(values.instructors.map((item: any) => item.label)) : instructors_labels = instructors_labels;
    instructor_ids_labels.length == 0 && plusButtonClicked == false ? instructor_ids_labels = instructor_ids_labels.concat(values.instructors.map((item: any) => item.value)) : instructor_ids_labels = instructor_ids_labels;
    instructor_ids_labels = values.instructors.value != undefined && values.instructors != null && plusButtonClicked ? instructor_ids_labels.concat(values.instructors.value) : instructor_ids_labels;
    instructors_labels = values.instructors.value != undefined && values.instructors != null && plusButtonClicked ? instructors_labels.concat(values.instructors.label) : instructors_labels;

    faculties_labels.length == 0 && plusButtonClicked == false ? faculties_labels = faculties_labels.concat(values.faculties.map((item: any) => item.label)) : faculties_labels = faculties_labels;
    faculty_ids_labels.length == 0 && plusButtonClicked == false ? faculty_ids_labels = faculty_ids_labels.concat(values.faculties.map((item: any) => item.value)) : faculty_ids_labels = faculty_ids_labels;
    faculty_ids_labels = values.faculties.value != undefined && values.faculties != null && plusButtonClicked ? faculty_ids_labels.concat(values.faculties.value) : faculty_ids_labels;
    faculties_labels = values.faculties.value != undefined && values.faculties != null && plusButtonClicked ? faculties_labels.concat(values.faculties.label) : faculties_labels;

    programs_labels.length == 0 && plusButtonClicked == false ? programs_labels = programs_labels.concat(values.programs.map((item: any) => item.label)) : programs_labels = programs_labels;
    program_ids_labels.length == 0 && plusButtonClicked == false ? program_ids_labels = program_ids_labels.concat(values.programs.map((item: any) => item.value)) : program_ids_labels = program_ids_labels;
    program_ids_labels = values.programs.value != undefined && values.programs != null && plusButtonClicked ? program_ids_labels.concat(values.programs.value) : program_ids_labels;
    programs_labels = values.programs.value != undefined && values.programs != null && plusButtonClicked ? programs_labels.concat(values.programs.label) : programs_labels;

    grades_labels.length == 0 && plusButtonClicked == false ? grades_labels = grades_labels.concat(values.grades.map((item: any) => item.value)) : grades_labels = grades_labels;
    grades_labels = values.grades.value != undefined && values.grades != null && plusButtonClicked ? grades_labels.concat(values.grades.label) : grades_labels;

    values.section = values.section == '' || values.section == undefined ? '1' : values.section;
    sections_labels = plusButtonClicked ? sections_labels.concat(values.section) : sections_labels = values.sections;

    this.setState((prev) => ({
      ...prev,
      isAdded: true
    }))

    plusButtonClicked = !plusButtonClicked;
  };

  multiSelecteds(
    instructor_ids: any, faculty_ids: any, program_ids: any, instructors: any, faculties: any, programs: any, sections: any, grades: any
  ) {
    instructor_ids_labels = instructor_ids;
    instructors_labels = instructors;
    faculty_ids_labels = faculty_ids;
    faculties_labels = faculties;
    program_ids_labels = program_ids;
    programs_labels = programs;
    sections_labels = sections;
    grades_labels = grades;
  }

  isIncludeCurrentForm = (values: any) => {
    if (faculty_ids_labels == undefined) {
      instructor_ids_labels = new Array<any>();
      instructors_labels = new Array<any>();
      faculty_ids_labels = new Array<any>();
      faculties_labels = new Array<any>();
      program_ids_labels = new Array<any>();
      programs_labels = new Array<any>();
      sections_labels = new Array<any>();
      grades_labels = new Array<any>();
    }

    let table_values = faculty_ids_labels && faculty_ids_labels.map((faculty_id_label: any, index: any) => (
      {
        values:
          instructor_ids_labels![index] + ',' +
          faculty_ids_labels![index] + ',' +
          program_ids_labels![index] + ',' +
          sections_labels![index] + ',' +
          grades_labels![index]
      }));
    if (table_values !== undefined) {
      let current_form_values = { values: values.instructor_id + ',' + values.faculty_id + ',' + values.program_id + ',' + values.section + ',' + values.grade_id }
      if (table_values!.some((e: { values: string; }) => e.values == current_form_values.values)) {
        this.state.alreadyInTheProgramList = true;
        this.state.isAdded = true;
      } else if (this.state.isAdded) {
        this.state.alreadyInTheProgramList = false;
        this.state.isAdded = false;
      } else {
        this.state.alreadyInTheProgramList = false;
        this.state.isAdded = false;
      }
    }
  };

  createCourseProgramsFieldValues = (): Types.ICourseItem => {
    let coursePrograms = this.props.courseDetails ? this.props.courseDetails : [];
    let fields: any = {
      instructors: coursePrograms ? coursePrograms.map((item: any) => ({ label: item.instructorName, value: item.instructorId })) : [],
      programs: coursePrograms ? coursePrograms.map((item: any) => ({ label: item.programName, value: item.programId })) : [],
      faculties: coursePrograms ? coursePrograms.map((item: any) => ({ label: item.facultyName, value: item.facultyId })) : [],
      sections: coursePrograms ? coursePrograms.map((item: any) => (item.section)) : [],
      grades: coursePrograms ? coursePrograms.map((item: any) => ({ label: item.grade, value: item.grade })) : [],
    };
    return fields;
  };

  //#endregion

  render() {
    let listOptions: Array<Types.ISelectOption> = [
      { label: T.t('gen_date') + ' ' + T.t('gen_time'), value: 0 },
      { label: T.t('gen_classroom') + ' - ' + T.t('gen_invigilator') + ' - ' + T.t('gen_number_students_classroom'), value: 1 }
    ];

    const campusOptions =
      this.props.solutionCourseAddSelectOptions && this.props.solutionCourseAddSelectOptions.campuses ? this.props.solutionCourseAddSelectOptions.campuses : [];
    const CampusSelectOptions = [...ExamPeriodCourseTermLectureLocations(T), ...campusOptions];

    const classroomOptions =
      this.props.solutionCourseAddSelectOptions && this.props.solutionCourseAddSelectOptions.classrooms ? this.props.solutionCourseAddSelectOptions.classrooms : [];
    const ClassroomSelectOptions = [...ExamPeriodCourseTermLectureLocations(T), ...classroomOptions];

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '732px', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_solution_edit')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="p-0 container-fluid">
              <Formik
                initialValues={SolutionEditModalInitialValues}
                enableReinitialize={true}
                //validationSchema={SolutionEditModalValidation(T)}
                onSubmit={(values, actions) => {
                  this.onSolutionEdit(values, actions);
                }}
              >
                {(props: FormikProps<Types.IFilterSolutionCourseEditModal>) => {
                  const { values, handleChange, errors, handleBlur, handleSubmit } = props;

                  this.refreshValuesClosedSelectedTypes(values);
                  this.isIncludeCurrentForm(values);
                  this.disableEditButton(values);

                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="alert alert-warning" role="alert" style={{ maxWidth: 'none' }}>
                            {T.t('gen_solution_edit_warning')}
                          </div>
                          <div className="col-md-12">
                            <div className="mb-3 add-custom-tag">
                              <div className="react-select-container">
                                <label>{T.t('gen_editable_data')}</label>
                                <Select
                                  id='select_type'
                                  className="react-select"
                                  isMulti={true}
                                  filterOption={(option: any, query: any) =>
                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                  }
                                  closeMenuOnSelect={false}
                                  options={listOptions}
                                  placeholder={T.t('gen_select_type')}
                                  value={props.values.selected_types ? props.values.selected_types : null}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: any = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('selected_types', list);
                                    props.setFieldValue(
                                      'selected_ids',
                                      list.map((item: any) => item.value)
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[0].value) ?
                            <div className="mt-3 ml-3 col-md-5">
                              <div className="react-select-container">
                                <div className="form-input form-group with-icon date-picker">
                                  <Flatpickr
                                    id='start_date'
                                    placeholder={T.t('gen_select_date')}
                                    value={this.state.start_date}
                                    options={{
                                      dateFormat: 'Y-d-m',
                                      clickOpens: true,
                                      allowInput: true,
                                      locale: this.state.locale
                                    }}
                                    onClose={(value) => {
                                      values.start_date = value.map((item) => moment(item).format('YYYY-MM-DD'))[0];
                                      this.setState(this.state);
                                    }}
                                  />
                                  <label style={{ top: "-13px" }}>{T.t('gen_start_date')}</label> <i className="material-icons">insert_invitation</i>
                                </div>
                              </div>
                              {(!values.start_date && values.start_hour) && props.submitCount > 0 ? (
                                <div className="error">{T.t('gen_cannot_leave_empty')}</div>
                              ) : null}
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[0].value) ?
                            <div className="col-md-5 form-input form-group with-icon">
                              <div className="mt-3 add-custom-tag">
                                <Flatpickr
                                  id='start_hour'
                                  value={this.state.start_hour}
                                  name="start_hour"
                                  placeholder={T.t('gen_select_time')}
                                  options={{
                                    enableTime: true,
                                    dateFormat: 'H:i',
                                    noCalendar: true,
                                    time_24hr: true,
                                    maxDate: this.state.maxHour ? this.state.maxHour : undefined,
                                    minDate: this.state.minHour ? this.state.minHour : undefined
                                  }}
                                  onClose={(value) => props.setFieldValue('start_hour', moment(value[0]).format('HH:mm'))}
                                />
                                <span className="highlight" />
                                <span className="bar" />
                                <label htmlFor="start-time-datepicker">{T.t('gen_start_time')}</label>
                              </div>
                              <ErrorMessage component="div" className="error" name="start_hour" />
                              {(values.start_date && !values.start_hour) && props.submitCount > 0 ? (
                                <div className="error">{T.t('gen_validation_start_hour')}</div>
                              ) : null}
                            </div> : null
                        }
                        {
                          props.values.selected_types.map((x: any) => x.value).includes(listOptions[1].value) ?
                            <div className="mb-3 col-12 text-md-center">
                              <MultiSelectionTableClassroom
                                initialCVS={this.state.initialCVS}
                                combined_values={combined_values_selected}
                                selected_course={0}
                                courseId={this.props.courseId}
                                multiSelectionObjectFunction={this.multiSelectedsClassroom}
                                campuses={this.props.solutionEvent && this.props.solutionEvent.campuses}
                                remoteCheck={true}
                              />
                            </div> : null
                        }
                        {
                          props.values.selected_types.length !== 0 ?
                            <div className="pt-3 pb-3 pr-5 col-12">
                              <button
                                id='button_change'
                                type="button"
                                className="float-right primary"
                                disabled={this.disableEditButton(values)}
                                onClick={() => {
                                  if (props.values.selected_types.map((x: any) => x.value).includes(listOptions[1].value) && combined_values_selected.length == 0) {
                                    this.messageForEmptyClassrooms();
                                  }
                                  else {
                                    props.handleSubmit();
                                    saveButtonClicked = true
                                  }
                                }}
                              >
                                {T.t('gen_change')}
                              </button>
                            </div> : null
                        }
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ICoursePageProps): Types.ICoursePageProps => {
  if (!store) {
    return ownProps;
  }

  const newProps: Types.ICoursePageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    solutionCourseAddSelectOptions: store.state.select_options && store.state.select_options.solutionCoursePage,
    examDates: store.state.examPeriodModal && store.state.examPeriodModal.exam_dates,
    programs_id_related_faculty: store.state.select_options && store.state.select_options.programs_id_related_faculty,
    courseDetails: store.state && store.state.solution_page && store.state.solution_page.courseDetails,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SolutionEditModal);

export default container;