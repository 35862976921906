import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Table } from 'reactstrap';
import XLSX from 'xlsx';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import Spinner from '../templates/spinner';
import { ExcelImportKeys } from './import-modal';
import Translator from '../../services/translate-factory';
import { ImportingKeySelectOptions } from '../../store/constants/planning-const';
import { TermType } from '../../store/constants/general';
import { ExcelFileInformation, formatExcel } from './format';
import { planningInstructorHoursMapping } from './format/column/merged';
import Warning from '../warning';

const T = Translator.create();

const SheetJSFT = ['xlsx', 'xls', 'csv', 'ods']
  .map(function (x) {
    return '.' + x;
  })
  .join(',');

class PlanningInstructorHoursImportForm extends React.Component<Types.IImportFormProps, Types.IImportFormState> {
  modalName = ExcelImportKeys.PlanningInstructorHours;
  craeteExcelBulkAction = Constants.planning.PLANNING_HOURS_INSTRUCTOR_CREATE_EXCEL_BULK;

  state: Types.IImportFormState = {
    options: {
      overrideData: false,
      importingKey: 'AVAILABLE',
      termId: -1
    },
    acceptedEntries: [],
    rejectedEntries: []
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    if (this.props.term_id) {
      this.state.options.termId = this.props.term_id;
      this.setState(this.state);
    }
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.props.dispatch(
      Actions.ApiRequest(this.craeteExcelBulkAction, {
        reset: true
      })
    );
  }

  post = () => {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status === 500) {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_error'),
            body: (
              <h6>
                {T.t('notification_excel_delete_cannot_be_performed')}
              </h6>
            ),
            name: this.modalName + '_error',
            icon: 'error_outline',
            iconColor: 'red'
          })
        );
        this.props.dispatch(
          Actions.ShowModal({
            name: this.modalName,
            cancel: T.t('gen_close'),
            confirm: T.t('gen_upload_file')
          })
        );
      } else if (status === 200 || status === 409) {
        this.props.dispatch(
          Actions.ShowModal({
            name: this.modalName,
            confirm: T.t('gen_download_result_as_excel'),
            cancel: T.t('gen_close'),
            onConfirm: this.props.apiResultTableToExcel,
            buttonPosition: 1
          })
        );
        if (this.props.onImport) {
          this.props.onImport();
        }
      } else {
        this.props.dispatch(
          Actions.ShowModal({
            name: this.modalName,
            body: <h6>{T.t('gen_unexpected_error_has_occurred_please_check_your_data')}</h6>,
            cancel: T.t('gen_close')
          })
        );
      }

      this.props.dispatch(
        Actions.ApiRequest(this.craeteExcelBulkAction, {
          reset: true
        })
      );
    };

    if (this.state.rejectedEntries.length === 0) {
      let postModel: Types.IImportPost = {
        options: this.state.options,
        items: this.state.acceptedEntries
      };
      this.props.dispatch(
        Actions.ApiRequest(this.craeteExcelBulkAction, postModel, this.modalName + '-spinner', resultCallback)
      );
      this.props.dispatch(Actions.ShowModal({ name: this.modalName, cancel: T.t('gen_close') }));
    }
  };

  excelToJSON = (file: File) => {
    let reader = new FileReader();
    reader.onload = (e: any) => {
      const term_id = this.props.term_id ? this.props.term_id : -1;
      const excelData: ExcelFileInformation = {
        file: file,
        fileType: this.modalName,
        term_id: term_id.toString()
      }
      const data = formatExcel<Types.IExcelPlanningInstructorHourRow>(e, planningInstructorHoursMapping, excelData)

      this.state.acceptedEntries = data;
      this.state.rejectedEntries = [];
      this.setState(this.state);
      if (this.state.acceptedEntries.length > 0) {
        this.post();
      }
    };
    reader.readAsBinaryString(file);
  };

  JSONToExcel = (data: any) => {
    let ws = XLSX.utils.json_to_sheet(data);
    let wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'import_report');
    XLSX.writeFile(wb, 'report.xlsx');
  };

  onFileSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files && files[0]) {
      this.excelToJSON(files[0]);
    }
    e.currentTarget.value = '';
  };

  translateImportingResult(result: string): string {
    let message = '';
    switch (result) {
      case 'rejected':
        message = T.t('gen_error_main');
        break;
      case 'added':
        message = T.t('gen_added');
        break;
      case 'updated':
        message = T.t('gen_updated');
        break;
    }

    return message;
  }

  render() {
    const exampleExcelFileName =
      this.props.term_type === 1 ? 'Example_Planning_Instructor_Hours_Import.xlsx' : 'Example_CourseTerm_Planning_Instructor_Hours_Import.xlsx';

    let formOptions = null;
    let apiResultTable = null;
    let validationErrorsTable = null;
    let items = this.props.results;
    let options = this.props.options;

    if (this.state.rejectedEntries.length > 0) {
      let validationErrorRow = this.state.rejectedEntries.map((r) => {
        return (
          <tr key={'importing-error-' + r.RowIndex}>
            <td>{'#' + (r.RowIndex + 1)}</td>
            <td>
              {r.ValidationErrors.map((v) => {
                return (
                  <Fragment key={v.Message}>
                    {(v.Field ? v.Field + ': ' : 'GENEL: ') + v.Message} <br />
                  </Fragment>
                );
              })}
            </td>
          </tr>
        );
      });

      validationErrorsTable = (
        <div className="mt-4" style={{ fontSize: '14px' }}>
          <Table responsive striped bordered size="sm">
            <thead>
              <tr>
                <th style={{ width: '15%' }}>{T.t('gen_line_number')}#</th>
                <th style={{ width: '85%' }}>{T.t('gen_error')}</th>
              </tr>
            </thead>
            <tbody>{validationErrorRow}</tbody>
          </Table>
        </div>
      );
    }

    if (items) {
      let rows = null;

      rows = items
        .sort((a, b) => (a.model.importingResult === 'rejected' || b.model.importingResult === 'updated' ? -1 : 1))
        .map((item: Types.IMultipleResponseItem<Types.IImportedPlanningInstructorHour>) => {
          let { model, state } = item;
          let importingKeyObject =
            options &&
            ImportingKeySelectOptions(T).find(
              (item: Types.ISelectOption) => item.value == (options ? options.importingKey : '')
            );
          return (
            <tr key={this.modalName + '-' + model.instructorCode} hidden={(model.importingResult !== 'rejected')}>
              <td>{this.translateImportingResult(model.importingResult)}</td>
              <td>{importingKeyObject ? importingKeyObject.label : ''}</td>
              <td>{model.instructorCode}</td>
              {this.props.term_type === 1 ? (
                <td>{model.date}</td>
              ) : null}
              <td>{model.day}</td>
              <td>{model.startHour}</td>
              <td>{model.endHour}</td>
              <td>
                {state
                  ? Array.isArray(state.details)
                    ? state.details.map((error) => {
                      return <label key={error.message}>{error.field + ': ' + error.message}</label>;
                    })
                    : state.details
                  : ''}
              </td>
            </tr>
          );
        });

      apiResultTable = (
        <div className="small mt-2">
          <h6>
            {T.t('gen_records_successfully_added_updated').replace('{0}', this.state.acceptedEntries.length).replace('{1}',
              items.filter(item => item.model.importingResult !== 'rejected').length)}
          </h6>
          <Table
            id="api-result-table"
            className="mt-3"
            responsive
            striped
            bordered
            size="sm"
            hidden={items.findIndex((item) => item.model.importingResult === 'rejected') < 0}
          >
            <thead>
              <tr>
                <th>{T.t('gen_result')}</th>
                <th>{T.t('gen_key_data')}</th>
                <th>{T.t('gen_instructor_code')}</th>
                {this.props.term_type === 1 ? (
                  <React.Fragment>
                    <th>{T.t('gen_date')}</th>
                  </React.Fragment>
                ) : null}
                <th>{T.t('gen_day')}</th>
                <th>{T.t('gen_start_time')}</th>
                <th>{T.t('gen_end_time')}</th>
                <th>{T.t('gen_error_message')}</th>
              </tr>
            </thead>
            <tbody>{rows}</tbody>
          </Table>
        </div>
      );
    } else {
      formOptions = (
        <div className="row">
          <Spinner name={this.modalName + '-spinner'} />
          <div className="col-12 col-md-12 col-sm-12">
            <p>
              <a href={process.env.PUBLIC_URL + '/files/' + exampleExcelFileName}> {T.t("excel_example_excel_file")} </a>
            </p>
          </div>
          <div className="col-12 col-md-12 col-sm-12">
            <div className="react-select-container">
              <label>{T.t("excel_base_information")}</label>
              <Select
                className="react-select"
                isSearchable={false}
                options={ImportingKeySelectOptions(T)}
                value={
                  this.state.options.importingKey
                    ? ImportingKeySelectOptions(T).find((k) => k.value === this.state.options.importingKey)
                    : null
                }
                onChange={(item: any) => {
                  this.state.options.importingKey = item.value;
                  this.setState(this.state);
                }}
              />
            </div>
          </div>
          <div className='col-md-12'>
            <Warning
              show
              warningKey={this.state.options.importingKey}
              warnings={{
                'AVAILABLE': T.t('gen_excel_merge_warning_planning', { key: T.t('gen_hours_availabilities'), items: T.t("gen_instructors") }),
                'NON-AVAILABLE': T.t('gen_excel_merge_warning_planning', { key: T.t('gen_hours_non_availabilities'), items: T.t("gen_instructors") }),
              }}
            />
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        {formOptions}
        {validationErrorsTable}
        {apiResultTable}
        <input id="excel_file_input" type="file" className="d-none form-control" accept={SheetJSFT} onChange={this.onFileSelected} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IImportFormProps): Types.IImportFormProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.IImportFormProps = Object.assign({}, ownProps, {
    results: store.state.planning_instructor_hours_import_result && store.state.planning_instructor_hours_import_result.items,
    options: store.state.planning_instructor_hours_import_result && store.state.planning_instructor_hours_import_result.options,
    term_id: store.state.term_id,
    term_type: store.state.term_type,
    user: store.state.user
  });
  return newProps;
};

const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return next.state.planning_instructor_hours_import_result === prev.state.planning_instructor_hours_import_result;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(PlanningInstructorHoursImportForm);

export default container;
