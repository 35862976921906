import { Formik, FormikActions, FormikProps, ErrorMessage } from 'formik';
import { Log, Logger } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import Announce from '../../components/templates/announce';
import { CardSubtitle, CardTitle, Modal } from 'reactstrap';
import Spinner from '../../components/templates/spinner';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
import { Collapse, CardBody, Card, CardHeader } from 'reactstrap';
import { DistributorSingleExamSearchInitialValues } from '../../store/constants/distributor-const';
import { ExamTypes, } from '../../store/constants/course-const';
import { UncontrolledTooltip } from 'reactstrap';
import AvailabilityCalendarModal from './availibility-calendar-modal';


const T = Translator.create();
const L = Log.create('SingleExamEditModal');

const initialValues: any = {
  distributorExamAvailabilityCalendarModalIsOpen: false,
  filters: DistributorSingleExamSearchInitialValues,
  filterIsOpen: true,
  data: undefined,
  classroomOptionsAreOpen: false,
  classroomOptionsClassroomId: -1,
  classroomOptionsInvigilatorId: -1,
  deletedList: [],
  collapse: [0, 1],
  term_id: -1,
  page: 1,
  total: -1,
  size: 1,
  subfilter_classroom_name: ''
};
function getInitialState(): any {
  return Object.assign({}, initialValues);
}

class ChangeSingleExamModal extends Component<any, any> {
  state: {
    distributorExamAvailabilityCalendarModalIsOpen?: boolean;
    filters: Types.IFilterDistributorSingleExam;
    filterIsOpen: boolean;
    data: any;
    classroomOptionsAreOpen: boolean;
    classroomOptionsClassroomId: number;
    classroomOptionsInvigilatorId: number;
    collapse: Array<Number>;
    term_id: number;
    size: number;
    page: number;
    total: number;
    subfilter_classroom_name: string
  } = getInitialState();
  componentRef: any;

  constructor(props: any) {
    super(props);
    this.componentRef = React.createRef();
    this.toggle = this.toggle.bind(this);
  }

  toggle(index: number) {
    this.state.collapse.includes(Number(index)) ? this.state.collapse = this.state.collapse.filter((item: any) => item != Number(index)) : this.state.collapse.push(Number(index));
    this.setState(this.state);
  }

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.state.filters.page = 1;
    this.state.filters.course_id = -1;
    this.state.page = 1;
    this.state.collapse = [0, 1];
    this.setState(this.state);
    this.getInfoSingleExamChangeDistributions();
    this.getSingleExamChangeSelectOptions();
    this.setState(this.state);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getSingleExamChangeSelectOptions() {
    const resultCallback = (result: any, status: number) => { };
    let model = {
      job_id: this.props.distribution_in_progress_status.job_id > 0 ? this.props.distribution_in_progress_status.job_id : this.props.jobId
    }
    this.props.dispatch(Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_SINGLE_EXAM_CHANGE_GET_SELECT_OPTIONS, model, 'distribution-single-exam-change-spin', resultCallback));
  }

  getInfoSingleExamChangeDistributions() {
    const resultCallback = (result: any, status: number) => {
      if (status === 200) {
        this.state.data = result;
        this.setState(this.state);
        this.forceUpdate();
      }
    };

    this.state.filters.job_id = this.props.distribution_in_progress_status.job_id > 0 ? this.props.distribution_in_progress_status.job_id : this.props.jobId;
    this.props.dispatch(
      Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_GET_INFO_SINGLE_EXAM_CHANGE, this.state.filters, 'distribution-single-exam-change-spin', resultCallback),
    );
  }

  getSolutionChangeSelectOptions(course_id_selected: number) {
    const resultCallback = (result: any, status: number) => {
      if (status === 200 && result.course_id_selected > 0) {
        this.switchAvailabilityCalendarModalStatus();
      }
    };

    let model = {
      job_id: this.props.distribution_in_progress_status.job_id > 0 ? this.props.distribution_in_progress_status.job_id : this.props.jobId,
      course_id_selected: course_id_selected
    }
    this.props.dispatch(Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_SOLUTION_CHANGE_GET_SELECT_OPTIONS, model, 'distribution-solution-change-spin', resultCallback));
  }

  onSingleExamChange(e: React.MouseEvent<HTMLButtonElement, MouseEvent>, exam: any) {
    if (e && e.currentTarget) {
      const resultCallback = (result: any, status: number) => {
        if (status === 200) {
          this.setCloseModal();
        }
      };
      const onConfirm = () => {
        let singleExamChanges = [];

        let classroomIds = exam.exam_classrooms.map(function (p: any) { return p.dll_room_id; });
        let invigilatorIds = exam.exam_classrooms.map(function (p: any) { return p.dll_invigilator_id; });

        for (let i = 0; i < classroomIds.length; i++) {
          let change = {
            job_id: this.state.filters.job_id,
            exam_id: exam.exam_id,
            timeslot_id: this.state.filters.timeslot_id != undefined && this.state.filters.timeslot_id > -1 ? this.state.filters.timeslot_id : exam.time_slot_id,
            room_id: classroomIds[i],
            invigilator_id: invigilatorIds[i],
            status: 1
          }
          singleExamChanges.push(change);
        }

        const model = {
          job_id: this.props.distribution_in_progress_status.job_id > 0 ? this.props.distribution_in_progress_status.job_id : this.props.jobId,
          changes: singleExamChanges
        };
        this.props.dispatch(
          Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_SINGLE_EXAM_CHANGE, model, 'distribution-single-exam-change-spin', resultCallback)
        );
      };
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_distribution_change_single_exam_title'),
          body: T.t('gen_distribution_change_single_exam_body'),
          name: 'distribution_change_single_exam',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_no'),
          onConfirm: onConfirm
        })
      );
    }
  }

  getAllIndexes(arr: any, val: any) {
    var indexes = [], i;
    for (i = 0; i < arr.length; i++)
      if (arr[i] === val)
        indexes.push(i);
    return indexes;
  }

  onFilterSingleExam(course_id: number) {
    this.state.filters.course_id = course_id;
    this.state.filters.timeslot_id = -1;
    this.state.filters.room_ids = [];
    this.state.filters.invigilator_ids = [];
    this.state.filterIsOpen = true;
    this.state.collapse = [0, 1];
    this.state.classroomOptionsAreOpen = false;
    this.setState(this.state);
    this.getInfoSingleExamChangeDistributions();
  }

  onChangeTimeSlot(timeslot_id: number) {
    this.state.filters.timeslot_id = timeslot_id;
    this.state.collapse = [0, 1];
    this.state.classroomOptionsAreOpen = false;
    this.setState(this.state);
    this.getInfoSingleExamChangeDistributions();
  }

  onChangeInvigilator(exam: any, classroom_id: number, invigilator_id: number, assigned_invigilator_id: number) {
    let classroomIds = exam.exam_classrooms.map(function (p: any) { return p.dll_room_id; });
    let invigilatorIds = exam.exam_classrooms.map(function (p: any) { return p.dll_invigilator_id; });

    let classroomIndexes = this.getAllIndexes(classroomIds, classroom_id);
    let invigilatorIndexes = this.getAllIndexes(invigilatorIds, invigilator_id);

    let indexIntersections = classroomIndexes.filter(value => invigilatorIndexes.includes(value));

    if (indexIntersections.length) {
      let index = indexIntersections[0];
      invigilatorIds[index] = assigned_invigilator_id;

      this.state.filters.room_ids = classroomIds;
      this.state.filters.invigilator_ids = invigilatorIds;
      this.state.collapse = [0, 1];
      this.state.classroomOptionsAreOpen = false;
      this.setState(this.state);
      this.getInfoSingleExamChangeDistributions();
    }
  }

  onChangeClassroom(exam: any, classroom_id: number, invigilator_id: number, assigned_classroom_id: number) {
    let classroomIds = exam.exam_classrooms.map(function (p: any) { return p.dll_room_id; });
    let invigilatorIds = exam.exam_classrooms.map(function (p: any) { return p.dll_invigilator_id; });

    let classroomIndexes = this.getAllIndexes(classroomIds, classroom_id);
    let invigilatorIndexes = this.getAllIndexes(invigilatorIds, invigilator_id);

    let indexIntersections = classroomIndexes.filter(value => invigilatorIndexes.includes(value));

    if (indexIntersections.length) {
      let index = indexIntersections[0];
      classroomIds[index] = assigned_classroom_id;
      invigilatorIds[index] = -1;

      this.state.filters.room_ids = classroomIds;
      this.state.filters.invigilator_ids = invigilatorIds;
      this.state.collapse = [0, 1];
      this.state.classroomOptionsAreOpen = false;
      this.setState(this.state);
      this.getInfoSingleExamChangeDistributions();
    }
  }

  onDeleteClassroom(exam: any, classroom_id: number, invigilator_id: number) {
    let classroomIds = exam.exam_classrooms.map(function (p: any) { return p.dll_room_id; });
    let invigilatorIds = exam.exam_classrooms.map(function (p: any) { return p.dll_invigilator_id; });

    let classroomIndexes = this.getAllIndexes(classroomIds, classroom_id);
    let invigilatorIndexes = this.getAllIndexes(invigilatorIds, invigilator_id);

    let indexIntersections = classroomIndexes.filter(value => invigilatorIndexes.includes(value));

    if (indexIntersections.length) {
      let index = indexIntersections[0];

      classroomIds.splice(index, 1);
      if (classroomIds.length == 0) {
        classroomIds.push(-1);
      }

      invigilatorIds.splice(index, 1);
      if (invigilatorIds.length == 0) {
        invigilatorIds.push(-1);
      }

      this.state.filters.room_ids = classroomIds;
      this.state.filters.invigilator_ids = invigilatorIds;
      this.state.collapse = [0, 1];
      this.state.classroomOptionsAreOpen = false;
      this.setState(this.state);
      this.getInfoSingleExamChangeDistributions();
    }
  }

  onAddClassroom(exam: any) {
    let classroomIds = exam.exam_classrooms.map(function (p: any) { return p.dll_room_id; });
    let invigilatorIds = exam.exam_classrooms.map(function (p: any) { return p.dll_invigilator_id; });

    classroomIds.push(-1);
    invigilatorIds.push(-1);

    this.state.filters.room_ids = classroomIds;
    this.state.filters.invigilator_ids = invigilatorIds;
    this.state.collapse = [0, 1];
    this.state.classroomOptionsAreOpen = false;
    this.setState(this.state);
    this.getInfoSingleExamChangeDistributions();
  }

  onFormReset = () => {
    this.state.filters = Object.assign({}, DistributorSingleExamSearchInitialValues);
    this.setState(this.state);
    this.getInfoSingleExamChangeDistributions();
  };

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.getInfoSingleExamChangeDistributions();
      this.getSingleExamChangeSelectOptions();
      this.setState(this.state);
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  switchAvailabilityCalendarModalStatus = () => {
    if (this.state.distributorExamAvailabilityCalendarModalIsOpen) {
      this.getSolutionChangeSelectOptions(0);
    }
    this.setState({
      ...this.state,
      distributorExamAvailabilityCalendarModalIsOpen: !this.state.distributorExamAvailabilityCalendarModalIsOpen
    });
  };

  switchClassroomOptionsTableStatus = (classroomId: number, invigilatorId: number) => {
    this.setState({
      ...this.state,
      subfilter_classroom_name: '',
      classroomOptionsClassroomId: classroomId,
      classroomOptionsInvigilatorId: invigilatorId,
      classroomOptionsAreOpen: !this.state.classroomOptionsAreOpen
    });
  };

  handleChangeSubFilter(e: React.ChangeEvent<HTMLInputElement>) {
    if (e && e.target && e.target.value && e.target.value.length) {
      this.state.subfilter_classroom_name = e.target.value;
    } else {
      this.state.subfilter_classroom_name = '';
    }
    this.setState(this.state);
  }

  render() {
    let exam = this.state.data && this.state.data.exam_id >= 0 ? this.state.data : undefined;

    let classrooms = this.state.data && this.state.data.available_rooms ? this.state.data.available_rooms : [];
    if (this.state.subfilter_classroom_name && this.state.subfilter_classroom_name.length) {
      classrooms = classrooms.filter((classroom: any) => (classroom.name.toLowerCase().includes(this.state.subfilter_classroom_name.toLowerCase())))
    }

    const scrollToTop = () => {
      this.componentRef.current.scrollIntoView({ block: 'nearest' });
    }

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div ref={this.componentRef}></div>
        <div className="modal-content">
          <Spinner name="distribution-single-exam-change-spin" />
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_change_single_exam')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                }}
              >
                {(props: FormikProps<Types.IDistributorCourseTimeSlotEditModel>) => {
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        {/* FILTER STARTS HERE  */}
                        <div
                          className={`white-container mb-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                          id="advance-search"
                        >
                          <div className="advance-search d-block ml-4 mr-4" style={{}}>
                            <Formik
                              initialValues={DistributorSingleExamSearchInitialValues}
                              enableReinitialize={true}
                              onSubmit={(values, Actions) => {
                                scrollToTop();
                              }}
                              onReset={this.onFormReset}
                            >
                              {(props: FormikProps<Types.IFilterDistributorSingleExam>) => {
                                return (
                                  <form onSubmit={props.handleSubmit}>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_exam_search')}</label>
                                            <Select
                                              id='select_exam'
                                              className="react-select"
                                              isMulti={false}
                                              closeMenuOnSelect={true}
                                              options={
                                                this.props.selectOptions && this.props.selectOptions.exams
                                                  ? this.props.selectOptions.exams
                                                  : []
                                              }
                                              placeholder={T.t('gen_select_exam')}
                                              value={props.values.course_id_select}
                                              onChange={(option: any) => {
                                                props.setFieldValue('course_ids_select', option);
                                                props.setFieldValue('course_id', option.value);
                                                this.onFilterSingleExam(option.value);
                                                scrollToTop();
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_exam')}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <hr />
                                    <hr />
                                  </form>
                                );
                              }}
                            </Formik>
                          </div>
                        </div>
                        {/* EXAM STARTS HERE  */}
                        <div className="col-12">
                          {exam && exam.exam_id >= 0 ? (
                            <Card key={exam.exam_id} >
                              <CardHeader data-event={exam.exam_id}>
                                <CardTitle data-event={exam.exam_id}>{exam.course_info}</CardTitle>
                                <CardSubtitle data-event={exam.exam_id}>{exam.exam_info + " " + exam.exam_date}</CardSubtitle>
                              </CardHeader>
                              {/* TIME SLOT CHANGE STARTS HERE  */}
                              <Card key={0} >
                                <CardHeader onClick={() => this.toggle(0)} data-event={0}>
                                  <div className="float-right">
                                    {
                                      this.state.collapse.includes(0) ?
                                        <i className="material-icons pr-1 pl-1 mb-1" onSelect={() => this.toggle(0)} data-event={0}>expand_more</i> :
                                        <i className="material-icons pr-1 pl-1 mb-1" onSelect={() => this.toggle(0)} data-event={0}>navigate_next</i>
                                    }
                                  </div>
                                  <CardTitle data-event={0} onSelect={() => this.toggle(0)}>{T.t('gen_time_slot_change')}</CardTitle>
                                </CardHeader>
                                <Collapse isOpen={this.state.collapse.includes(0) ? true : false}>
                                  <div className="row">
                                    <div className="col-7">
                                    </div>
                                    <div className="col-5">
                                      <th className="text-center">
                                        {T.t('gen_available_time_slot') + ": "}
                                      </th>
                                      <td style={{ width: '55%' }} className="text-center" data-label={T.t('gen_available_time_slot')} key={'select_available_time_slot-'}>
                                        <div className="add-custom-tag">
                                          <div className="react-select-container">
                                            <Select
                                              id={'select_available_time_slot-' + exam.exam_id}
                                              name={'select_available_time_slot-' + exam.exam_id}
                                              className="react-select mt-1 ml-3"
                                              isMulti={false}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={true}
                                              options={exam.available_time_slots}
                                              placeholder={T.t('gen_select_available_time_slot')}
                                              value={exam.selected_time_slot}
                                              onChange={(option: any) => {
                                                this.onChangeTimeSlot(option.value);
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                      <td className="text-center" data-label={T.t('course_availability_calendar_in_planning')} key={'course_availability_calendar_in_planning-'}>
                                        <button
                                          id='button_course_availability_calendar_in_planning'
                                          className="tertiary mt-1 ml-5 mr-5"
                                          data-toggle="tooltip"
                                          title={T.t('gen_availability_calendar_in_planning')}
                                          onClick={() => this.getSolutionChangeSelectOptions(exam.course_id)}
                                        >
                                          <i className="material-icons">calendar_month</i>
                                        </button>
                                      </td>
                                    </div>
                                  </div>
                                </Collapse>
                              </Card>
                              {/* CLASSROOM AND INVIGILATOR CHANGE STARTS HERE  */}
                              <Card key={1} >
                                <CardHeader onClick={() => this.toggle(1)} data-event={1}>
                                  <div className="float-right">
                                    {
                                      this.state.collapse.includes(1) ?
                                        <i className="material-icons pr-1 pl-1 mb-1" onSelect={() => this.toggle(1)} data-event={1}>expand_more</i> :
                                        <i className="material-icons pr-1 pl-1 mb-1" onSelect={() => this.toggle(1)} data-event={1}>navigate_next</i>
                                    }
                                  </div>

                                  <CardTitle>
                                    <div id="classroom_and_invigilator" data-event={1} onSelect={() => this.toggle(1)}>{T.t('gen_classroom_and_invigilator_change')}<i className="material-icons mb-1">info_outlined</i></div>
                                    <UncontrolledTooltip placement="top" target="classroom_and_invigilator">
                                      {exam.current_classrooms}
                                    </UncontrolledTooltip>
                                  </CardTitle>
                                </CardHeader>
                                <Collapse isOpen={this.state.collapse.includes(1) ? true : false}>
                                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover filter-table">
                                    <thead>
                                      <tr>
                                        <th className="text-center" scope="col">
                                          {T.t('gen_date')}
                                        </th>
                                        <th className="text-center" scope="col">
                                          {T.t('gen_campus_and_classroom')}
                                        </th>
                                        <th className="text-center" scope="col">
                                          {T.t('gen_invigilator')}
                                        </th>
                                        <th className="text-center" scope="col">
                                        </th>
                                        <th className="text-right" scope="col">
                                          {T.t('gen_actions')}
                                        </th>
                                        <th className="text-center" scope="col">
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {exam.exam_classrooms.map((classroom: any, index_classroom: any) => {
                                        return (
                                          <tr key={'status-history-detail-' + index_classroom}>
                                            <td style={{ width: '17%' }} scope="row" data-label={T.t('gen_date')} className="text-center">
                                              {classroom && classroom.date}
                                            </td>
                                            <td style={{ width: '17%' }} scope="row" data-label={T.t('gen_campus_and_classroom')} className="text-center">
                                              {classroom && classroom.classroom_name}
                                            </td>
                                            <td style={{ width: '25%' }} className="text-center" data-label={T.t('gen_invigilator')} key={'select_invigilator-' + index_classroom}>
                                              <div className="add-custom-tag mt-3">
                                                <div className="react-select-container">
                                                  <Select
                                                    id={'select_invigilator-' + index_classroom}
                                                    name={'select_invigilator-' + index_classroom}
                                                    className="react-select"
                                                    isMulti={false}
                                                    filterOption={(option: any, query: any) =>
                                                      option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                    }
                                                    closeMenuOnSelect={true}
                                                    options={classroom.available_invigilators}
                                                    placeholder={T.t('select_invigilator')}
                                                    value={classroom.selected_invigilator}
                                                    isDisabled={classroom.dll_room_id == -1 || classroom.dll_room_id == -2}
                                                    onChange={(option: any) => {
                                                      this.onChangeInvigilator(exam, classroom.dll_room_id, classroom.dll_invigilator_id, option.value);
                                                    }}
                                                    noOptionsMessage={(): string => T.t('gen_select_no_invigilator')}
                                                  />
                                                </div>
                                              </div>
                                            </td>
                                            <td style={{
                                              width: '2%',
                                              visibility: (classroom && (classroom.dll_room_id == -2 || classroom.dll_room_id == -1) ? 'hidden' : 'visible')
                                            }} className="text-center" data-label={T.t('change_exam_classroom')} key={'change_exam_classroom-' + index_classroom}>
                                              <button
                                                id={"button_change_exam_classroom" + index_classroom}
                                                className="tertiary mr-2 ml-3"
                                                data-toggle="tooltip"
                                                title={T.t('gen_change_exam_classroom')}
                                                disabled={classroom && (classroom.dll_room_id == -1 || classroom.dll_room_id == -2)}
                                                onClick={() => this.switchClassroomOptionsTableStatus(classroom.dll_room_id, classroom.dll_invigilator_id)}
                                              >
                                                <i className="material-icons align-items-center justify-content-center">cached</i>
                                              </button>
                                            </td>
                                            <td style={{
                                              width: '2%',
                                              visibility: (classroom && (classroom.dll_room_id == -2 || classroom.dll_room_id == -1) ? 'hidden' : 'visible')
                                            }}
                                              className="text-center" data-label={T.t('delete_exam_classroom')} key={'delete_exam_classroom-' + index_classroom}>
                                              <button
                                                id='button_delete_exam_classroom'
                                                className="danger mr-2"
                                                data-toggle="tooltip"
                                                title={T.t('gen_delete_exam_classroom')}
                                                disabled={classroom && (classroom.dll_room_id == -2 || classroom.dll_room_id == -1)}
                                                onClick={() => {
                                                  this.onDeleteClassroom(exam, classroom.dll_room_id, classroom.dll_invigilator_id);
                                                }}
                                              >
                                                <i className="material-icons align-items-center justify-content-center">delete_sweep</i>
                                              </button>
                                            </td>
                                            <td style={{
                                              width: '2%',
                                              visibility: (classroom && classroom.dll_room_id == -2 ? 'hidden' : 'visible')
                                            }} className="text-center" data-label={T.t('add_exam_classroom')} key={'add_exam_classroom-' + index_classroom}>
                                              <button
                                                id='button_add_exam_classroom'
                                                className="primary mr-2"
                                                data-toggle="tooltip"
                                                title={T.t('gen_add_exam_classroom')}
                                                disabled={classroom && classroom.dll_room_id == -2}
                                                onClick={() => {
                                                  if (classroom.dll_room_id == -1) {
                                                    this.switchClassroomOptionsTableStatus(classroom.dll_room_id, classroom.dll_invigilator_id)
                                                  } else {
                                                    this.onAddClassroom(exam);
                                                  }
                                                }}
                                              >
                                                <i className="material-icons align-items-center justify-content-center">add_circle_outline</i>
                                              </button>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                  <div className="row">
                                    <div className="col-md-4 pt-3">
                                      {this.state.classroomOptionsAreOpen && exam.exam_classrooms && exam.exam_classrooms.length ?
                                        <div className="form-input form-group date-picker">
                                          <input
                                            id="subfilter_classroom_name"
                                            name="subfilter_classroom_name"
                                            value={this.state.subfilter_classroom_name}
                                            onChange={(e) => this.handleChangeSubFilter(e)}
                                            type="text"
                                          />
                                          <span className="highlight" />
                                          <span className="bar" />
                                          <label htmlFor="subfilter_classroom_name">{T.t('gen_classroom')}</label>
                                          <i className="material-icons">title</i>
                                        </div>
                                        : null}
                                    </div>
                                    <div className="col-md-2 pt-4">
                                      {this.state.classroomOptionsAreOpen && exam.exam_classrooms && classrooms.length == 0 ?
                                        <label
                                          className="col-md-12 text-left mt-3"
                                          style={{ color: 'red' }}
                                          id="noClassroomsFoundLabel">
                                          {T.t('gen_no_classrooms_found')}
                                        </label>
                                        : null}
                                    </div>
                                  </div>
                                  <div className="row table-scrollable-td">
                                    <div className="col-12">
                                      {this.state.classroomOptionsAreOpen && exam.exam_classrooms && exam.exam_classrooms.length ?
                                        <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                                          <thead>
                                            <tr>
                                              <th scope="col" className="text-center">
                                                {T.t('gen_classroom')}
                                              </th>
                                              <th scope="col" className="text-center">
                                                {T.t('gen_building')}
                                              </th>
                                              <th scope="col" className="text-center">
                                                {T.t('gen_campus')}
                                              </th>
                                              <th scope="col" className="d-none d-lg-table-cell d-xl-table-cell text-right">
                                                <span className="text-center">{T.t('gen_select') + " / " + T.t('gen_add')}</span>
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {classrooms.map((option: any) => (
                                              <tr key={'classroom-' + option.dll_room_id} data-title={option.classroom_id}>
                                                <td scope="row" data-label={T.t('gen_classroom')} className="text-center">
                                                  {option.name + " (" + T.t('gen_capacity') + ": " + option.exam_capacity + ")"}
                                                </td>
                                                <td data-label={T.t('gen_building')} className="text-center">
                                                  {option.building_name}
                                                </td>
                                                <td data-label={T.t('gen_campus')} className="text-center">
                                                  {option.campus_name}
                                                </td>
                                                <td data-label={T.t('gen_add')} className="table-buttons">
                                                  <div className="table-buttons-wrapper">
                                                    <button
                                                      id='button_access_time'
                                                      data-toggle="tooltip"
                                                      data-id={option.classroom_id}
                                                      title={T.t('gen_change_classroom')}
                                                      className="ghost square btn-sm table-button"
                                                      onClick={(e) =>
                                                        this.onChangeClassroom(exam, this.state.classroomOptionsClassroomId, this.state.classroomOptionsInvigilatorId, option.classroom_id)
                                                      }
                                                    >
                                                      <span className="d-block" data-toggle="modal" data-target="#addNew">
                                                        <i className="material-icons lg">post_add</i>
                                                      </span>
                                                    </button>
                                                  </div>
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                        : null}
                                    </div>
                                  </div>
                                </Collapse>
                              </Card>
                            </Card>
                          ) : (
                            <div className="row">
                              <div className="col-md-12 col-xs-12 text-center">
                                <Announce title={T.t('gen_notification_exam_not_selected')} />
                              </div>
                            </div>)
                          }
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
            <div className="row">
              <label
                className="col-md-12 text-center ml-3"
                style={{ color: exam && exam.exam_id >= 0 ? (exam.validation ? 'green' : 'red') : 'red' }}
                id="exampleModalLabel">
                {exam && exam.exam_id >= 0 ?
                  (ExamTypes(T)[0].value >= exam.exam_type ?
                    (this.state.filters.room_ids && this.state.filters.room_ids.length > 0 ? T.t('gen_student_count') + ": " + exam.student_count + ", " + T.t('gen_total') + " " + T.t('gen_classroom_capacity') + ": " + exam.classrooms_capacity : "")
                    : "")
                  : ""}
              </label>
            </div>
            <div className="row-options justify-content-end">
              <div className="page-sorting d-flex align-items-end justify-content-end" style={{ marginRight: '5px' }}>
                <div className="row">
                  <button
                    id='button_save'
                    type="button"
                    className="primary mt-md-0 mt-2 mb-md-0 mb-2 mr-3"
                    disabled={exam && exam.exam_id >= 0 ? !exam.validation : true}
                    onClick={(e) => {
                      this.onSingleExamChange(e, exam);
                    }}
                  >
                    {T.t('gen_save')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          this.state.distributorExamAvailabilityCalendarModalIsOpen && (
            <AvailabilityCalendarModal
              modalIsOpen={this.state.distributorExamAvailabilityCalendarModalIsOpen}
              onClose={this.switchAvailabilityCalendarModalStatus}
            />
          )
        }
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IDistributorPageProps): Types.IDistributorPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IDistributorPageProps = Object.assign({}, ownProps, {
    result: store.state.distributor_single_exam_change_page && store.state.distributor_single_exam_change_page.results,
    term_type: store.state.term_type,
    term_id: store.state.term_id,
    distribution_in_progress_status: store.state.distribution_in_progress_status,
    selectOptions: store.state.select_options && store.state.select_options.distributorSingleExamChangePage,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.examPeriodModal) {
    return (
      !!equal(
        prev.state.distributor_single_exam_change_page && prev.state.distributor_single_exam_change_page.results,
        next.state.distributor_single_exam_change_page && next.state.distributor_single_exam_change_page.results
      ) &&
      !!equal(
        prev.state && prev.state.term_type,
        next.state && next.state.term_type
      ) &&
      !!equal(
        prev.state && prev.state.term_id,
        next.state && next.state.term_id
      ) &&
      !!equal(
        prev.state && prev.state.distribution_in_progress_status,
        next.state && next.state.distribution_in_progress_status
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.distributorSingleExamChangePage,
        next.state.select_options && next.state.select_options.distributorSingleExamChangePage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ChangeSingleExamModal);

export default container;