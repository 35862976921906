import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import * as GT from '../../../tools/general-tools';
import { routes as Routes } from '../../../store/constants/routes';
import { EducationTypeSelectOptions, CourseTypes, ChangeAlldataModalInitialValues, ActivityTermLectureLocations, CourseEnvironments, ActivityTypes } from '../../../store/constants/activity-const';
import Checkbox from '../../../components/checkboxes/single-checkbox';
import Translator from '../../../services/translate-factory';
import { filterClassroomTypesForEventModuleStatus } from '../../../util/sort';
import { localizeSelectOptions } from '../../../util/language';
import { ClassroomTypes } from '../../../store/constants/setting-const';

const T = Translator.create();

const L = Log.create('ChangeAllDataModal');

function getInitialState(): Types.ChangeAllDataModalState {
  const initialValues: Types.ChangeAllDataModalState = {
    all_ids: [],
    selected_ids: []
  };
  return Object.assign({}, initialValues);
}

class ChangeAllDataModal extends Component<any, Types.ChangeAllDataModalState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  static getDerivedStateFromProps(props: Types.IClassroomPageProps, state: Types.ChangeAllDataModalState) {
    let hasNewState: boolean = false;
    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onChangeAllData(values: any, FormActions: FormikActions<Types.IFilterChangeAllDataModal>) {
    let model;
    const resultCallback = (status: number) => {
      if (status == 200) {
        this.props.dispatch(Actions.Notification('notification_all_data_for_courses_changed', 'gen_success'))
        setTimeout(() => {
          window.location.reload()
        }, 1000);
      }
    };
    if (values.selected_type_ids == 0) {
      model = {
        selected_type_id: 0,
        term_id: this.props.term_id,
        lecture_location_id: values.lecture_locations.value,
        activity_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 1) {
      model = {
        selected_type_id: 1,
        term_id: this.props.term_id,
        course_type: values.course_type.value,
        activity_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 2) {
      model = {
        selected_type_id: 2,
        term_id: this.props.term_id,
        education_type: values.education_type.value,
        activity_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 3) {
      model = {
        selected_type_id: 3,
        term_id: this.props.term_id,
        instructor_ids: values.instructor_ids,
        activity_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 4) {
      model = {
        selected_type_id: 4,
        term_id: this.props.term_id,
        status: values.status,
        activity_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 5) {
      model = {
        selected_type_id: 5,
        term_id: this.props.term_id,
        course_environment: values.course_environment,
        activity_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 6) {
      model = {
        selected_type_id: 6,
        term_id: this.props.term_id,
        classroom_type: values.classroom_type.value,
        activity_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 7) {
      model = {
        selected_type_id: 7,
        term_id: this.props.term_id,
        course_language: values.course_language,
        activity_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 8) {
      model = {
        selected_type_id: 8,
        term_id: this.props.term_id,
        section: values.section,
        activity_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 9) {
      model = {
        selected_type_id: 9,
        term_id: this.props.term_id,
        student_count: values.student_count,
        activity_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 10) {
      model = {
        selected_type_id: 10,
        term_id: this.props.term_id,
        lesson_count: values.lesson_count,
        activity_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 11) {
      model = {
        selected_type_id: 11,
        term_id: this.props.term_id,
        activity_type: values.activity_type,
        activity_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 12) {
      model = {
        selected_type_id: 12,
        term_id: this.props.term_id,
        description: values.description,
        activity_nos: this.props.selected_ids
      }
    } else if (values.selected_type_ids == 13) {
      model = {
        selected_type_id: 13,
        term_id: this.props.term_id,
        week: values.week,
        activity_nos: this.props.selected_ids
      }
    }

    this.props.dispatch(
      Actions.ApiRequest(Constants.activity.ACTIVITY_CHANGE_ALL_DATA, model, 'activity-list-spin', resultCallback)
    );
  }

  render() {
    let listOptions = [
      { label: T.t('gen_course_language'), value: 7 },
      { label: T.t('gen_course_environment'), value: 5 },
      { label: T.t('gen_lecture_location'), value: 0 },
      { label: T.t('gen_course_type'), value: 1 },
      { label: T.t('gen_classroom_type'), value: 6 },
      { label: T.t('gen_education_type'), value: 2 },
      { label: T.t('gen_instructors'), value: 3 },
      { label: T.t('gen_status'), value: 4 },
      { label: T.t('gen_section'), value: 8 },
      { label: T.t('gen_student_count'), value: 9 },
      { label: T.t('gen_lesson_count'), value: 10 },
      { label: T.t('gen_activity_type'), value: 11 },
      { label: T.t('gen_description'), value: 12 },
      { label: T.t('gen_week'), value: 13 }
    ]
    const campusOptions =
      this.props.selectOptions && this.props.selectOptions.campuses ? this.props.selectOptions.campuses : [];
    const allSelectOptions = [...ActivityTermLectureLocations(T), ...campusOptions].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));

    let activityTypes = ActivityTypes(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_activity_types && this.props.selectOptions.additional_activity_types.length > 0) {
      activityTypes = [...ActivityTypes(T), ...this.props.selectOptions.additional_activity_types].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }

    let Weeks: Types.ISelectOption[] = GT.convertNumberToSelectOptionType(this.props.selectOptions && this.props.selectOptions.total_weeks ? this.props.selectOptions.total_weeks : 0);
    const WeekSelectOptions = [{ label: T.t('gen_select_all'), value: -1 }, ...Weeks];

    let classroomTypes: Types.ISelectOption[] = [];
    if (this.props.selectOptions) {
      classroomTypes = localizeSelectOptions(this.props.selectOptions.classroom_types_active, ClassroomTypes(T));
    }

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '700px', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_changing_all_data')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <Formik
                initialValues={ChangeAlldataModalInitialValues}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                  this.onChangeAllData(values, actions);
                }}
              >
                {(props: FormikProps<Types.IFilterChangeAllDataModal>) => {
                  const { values, handleChange, errors, handleBlur, handleSubmit } = props;
                  return (
                    <form onSubmit={props.handleSubmit}>
                      <div className="row">
                        <div className="col-12">
                          <div className="col-md-12 pb-3">
                            {T.t('gen_change_all_data_description')}
                          </div>
                          <div className="col-md-12">
                            <div className="add-custom-tag mb-3">
                              <div className="react-select-container">
                                <label>{T.t('gen_change_all_data')}</label>
                                <Select
                                  id='select_type'
                                  className="react-select"
                                  isMulti={false}
                                  closeMenuOnSelect={true}
                                  options={listOptions}
                                  placeholder={T.t('gen_select_type')}
                                  value={props.values.selected_types ? props.values.selected_types : null}
                                  onChange={(
                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                  ) => {
                                    const list: any = options
                                      ? (options as Array<Types.ISelectOption>)
                                      : [];
                                    props.setFieldValue('selected_types', list);
                                    props.setFieldValue(
                                      'selected_type_ids',
                                      list.value
                                    );
                                  }}
                                  noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                />
                              </div>
                            </div>
                          </div>
                          {
                            props.values.selected_type_ids === 7 ?
                              <div className="col-md-8">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_course_language')}</label>
                                    <Select
                                      id='select_course_language'
                                      className="react-select"
                                      isMulti={false}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={true}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.course_languages_active
                                          ? this.props.selectOptions.course_languages_active
                                          : []
                                      }
                                      placeholder={T.t('gen_select_course_language')}
                                      value={props.values.course_language_select}
                                      onChange={(option: any) => {
                                        props.setFieldValue('course_language', option.value);
                                        props.setFieldValue('course_language_select', option);
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_course_language')}
                                    />
                                  </div>
                                </div>
                              </div> :
                              props.values.selected_type_ids === 5 ?
                                <div className="col-md-8">
                                  <div className="add-custom-tag mb-3">
                                    <div className="react-select-container">
                                      <label>{T.t('gen_course_environment')}</label>
                                      <Select
                                        id='select_course_environment'
                                        className="react-select"
                                        isMulti={false}
                                        filterOption={(option: any, query: any) =>
                                          option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                        }
                                        closeMenuOnSelect={true}
                                        options={CourseEnvironments(T)}
                                        placeholder={T.t('gen_select_course_environment')}
                                        value={
                                          props.values.course_environment != undefined
                                            ? CourseEnvironments(T).find(
                                              (option) => option.value === props.values.course_environment
                                            )
                                            : null
                                        }
                                        onChange={(option: any) => {
                                          props.setFieldValue('course_environment', option.value)
                                          props.setFieldValue('course_environment_select', option);
                                        }}
                                        noOptionsMessage={(): string => T.t('gen_select_no_course_environment')}
                                      />
                                    </div>
                                  </div>
                                </div> :
                                props.values.selected_type_ids === 0 ?
                                  <div className="col-md-8">
                                    <div className="add-custom-tag mb-3">
                                      <div className="react-select-container">
                                        <label>{T.t('gen_lecture_location')}</label>
                                        <Select
                                          id='select_location'
                                          className="react-select"
                                          isMulti={false}
                                          closeMenuOnSelect={true}
                                          options={allSelectOptions}
                                          placeholder={T.t('gen_select_location')}
                                          value={props.values.lecture_locations ? props.values.lecture_locations : null}
                                          onChange={(
                                            options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                          ) => {
                                            const list: Array<Types.ISelectOption> = options
                                              ? (options as Array<Types.ISelectOption>)
                                              : [];
                                            props.setFieldValue('lecture_locations', list);
                                          }}
                                          noOptionsMessage={(): string => T.t('gen_select_no_location')}
                                        />
                                      </div>
                                    </div>
                                  </div> :
                                  props.values.selected_type_ids === 1 ?
                                    <div className="col-md-8">
                                      <div className="add-custom-tag mb-3">
                                        <div className="react-select-container">
                                          <label>{T.t('gen_course_type')}</label>
                                          <Select
                                            id='select_course'
                                            className="react-select"
                                            isMulti={false}
                                            closeMenuOnSelect={true}
                                            options={CourseTypes(T)}
                                            placeholder={T.t('gen_select_type')}
                                            value={props.values.course_type ? props.values.course_type : null}
                                            onChange={(
                                              options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                            ) => {
                                              const list: Array<Types.ISelectOption> = options
                                                ? (options as Array<Types.ISelectOption>)
                                                : [];
                                              props.setFieldValue('course_type', list);
                                            }}
                                            noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                          />
                                        </div>
                                      </div>
                                    </div> :
                                    props.values.selected_type_ids === 6 ?
                                      <div className="col-md-8">
                                        <div className="add-custom-tag mb-3">
                                          <div className="react-select-container">
                                            <label>{T.t('gen_classroom_type')}</label>
                                            <Select
                                              id='classroom_type'
                                              className="react-select"
                                              isMulti={false}
                                              filterOption={(option: any, query: any) =>
                                                option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                              }
                                              closeMenuOnSelect={true}
                                              options={classroomTypes.filter(filterClassroomTypesForEventModuleStatus(this.props.general_settings))}
                                              value={classroomTypes.find((option) => option.value == values.classroom_type)}
                                              placeholder={T.t('gen_select_type')}
                                              onChange={(
                                                options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                              ) => {
                                                const list: Array<Types.ISelectOption> = options
                                                  ? (options as Array<Types.ISelectOption>)
                                                  : [];
                                                props.setFieldValue('classroom_type', list);
                                              }}
                                              noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                            />
                                          </div>
                                        </div>
                                      </div> :
                                      props.values.selected_type_ids === 2 ?
                                        <div className="col-md-8">
                                          <div className="add-custom-tag mb-3">
                                            <div className="react-select-container">
                                              <label>{T.t('gen_education_type')}</label>
                                              <Select
                                                id='select_education_type'
                                                className="react-select"
                                                isMulti={false}
                                                closeMenuOnSelect={true}
                                                options={EducationTypeSelectOptions(T)}
                                                placeholder={T.t('gen_select_education_type')}
                                                value={props.values.education_type ? props.values.education_type : null}
                                                onChange={(
                                                  options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                ) => {
                                                  const list: Array<Types.ISelectOption> = options
                                                    ? (options as Array<Types.ISelectOption>)
                                                    : [];
                                                  props.setFieldValue('education_type', list);
                                                }}
                                                noOptionsMessage={(): string => T.t('gen_select_no_education_type')}
                                              />
                                            </div>
                                          </div>
                                        </div> :
                                        props.values.selected_type_ids === 3 ?
                                          <div className="col-md-8">
                                            <div className="add-custom-tag mb-3">
                                              <div className="react-select-container">
                                                <label>{T.t('gen_instructors')}</label>
                                                <Select
                                                  id='select_instructor'
                                                  className="react-select"
                                                  isMulti={true}
                                                  filterOption={(option: any, query: any) =>
                                                    option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                  }
                                                  closeMenuOnSelect={false}
                                                  options={
                                                    this.props.selectOptions && this.props.selectOptions.instructors
                                                      ? this.props.selectOptions.instructors
                                                      : []
                                                  }
                                                  placeholder={T.t('gen_select_instructor')}
                                                  value={props.values.instructors}
                                                  onChange={(
                                                    options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                  ) => {
                                                    const list: Array<Types.ISelectOption> = options
                                                      ? (options as Array<Types.ISelectOption>)
                                                      : [];
                                                    props.setFieldValue('instructors', list);
                                                    props.setFieldValue(
                                                      'instructor_ids',
                                                      list.map((item) => item.value)
                                                    );
                                                  }}
                                                  noOptionsMessage={(): string => T.t('gen_select_no_instructor')}
                                                />
                                              </div>
                                            </div>
                                          </div> :
                                          props.values.selected_type_ids === 4 ?
                                            <div className="col-md-8">
                                              <div className="text-left">
                                                <h6>{T.t('gen_status')}</h6>
                                                <div className="tick-radio position-relative d-inline-block">
                                                  <Checkbox name="status" />
                                                </div>
                                              </div>
                                            </div> :
                                            props.values.selected_type_ids === 8 ?
                                              <div className="col-md-6 form-input form-group with-icon">
                                                <input
                                                  id="section"
                                                  name="section"
                                                  value={props.values.section}
                                                  onChange={handleChange}
                                                  onBlur={handleBlur}
                                                  type="text"
                                                />
                                                <span className="highlight" />
                                                <span className="bar" />
                                                <label htmlFor="section">{T.t('gen_section')}</label>
                                              </div> :
                                              props.values.selected_type_ids === 9 ?
                                                <div className="col-md-6 form-input form-group with-icon">
                                                  <input
                                                    id="student_count"
                                                    name="student_count"
                                                    value={props.values.student_count}
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                    type="number"
                                                  />
                                                  <span className="highlight" />
                                                  <span className="bar" />
                                                  <label htmlFor="student_count">{T.t('gen_student_count')}</label>
                                                </div> :
                                                props.values.selected_type_ids === 10 ?
                                                  <div className="col-md-6 form-input form-group with-icon">
                                                    <input
                                                      id="lesson_count"
                                                      name="lesson_count"
                                                      value={props.values.lesson_count}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      type="number"
                                                    />
                                                    <span className="highlight" />
                                                    <span className="bar" />
                                                    <label htmlFor="lesson_count">{T.t('gen_lesson_count')}</label>
                                                  </div> :
                                                  props.values.selected_type_ids === 11 ?
                                                    <div className="col-md-8">
                                                      <div className="add-custom-tag mb-3">
                                                        <div className="react-select-container">
                                                          <label>{T.t('gen_activity_type')}</label>
                                                          <Select
                                                            id='select_activity_type'
                                                            className="react-select"
                                                            isMulti={false}
                                                            filterOption={(option: any, query: any) =>
                                                              option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                            }
                                                            closeMenuOnSelect={true}
                                                            options={activityTypes}
                                                            placeholder={T.t('gen_select_activity_type')}
                                                            value={
                                                              props.values.activity_type != undefined && props.values.activity_type != null
                                                                ? activityTypes.find((option) => option.value == (props.values.activity_type && props.values.activity_type.value))
                                                                : null
                                                            }
                                                            onChange={(option: any) => {
                                                              props.setFieldValue('activity_type', option.value)
                                                              props.setFieldValue('activity_type_select', option);
                                                            }}
                                                            noOptionsMessage={(): string => T.t('gen_select_no_activity_type')}
                                                          />
                                                        </div>
                                                      </div>
                                                    </div> :
                                                    props.values.selected_type_ids === 12 ?
                                                      <div className="col-md-6 form-input form-group with-icon">
                                                        <input
                                                          id="description"
                                                          name="description"
                                                          value={props.values.description}
                                                          onChange={handleChange}
                                                          onBlur={handleBlur}
                                                          type="text"
                                                        />
                                                        <span className="highlight" />
                                                        <span className="bar" />
                                                        <label htmlFor="description">{T.t('gen_description')}</label>
                                                      </div> :
                                                      props.values.selected_type_ids === 13 ?
                                                        <div className="col-md-8">
                                                          <div className="add-custom-tag mb-3">
                                                            <div className="react-select-container">
                                                              <label>{T.t('gen_week')}</label>
                                                              <Select
                                                                id='select_week'
                                                                className="react-select"
                                                                isMulti={true}
                                                                filterOption={(option: any, query: any) =>
                                                                  option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                                                }
                                                                closeMenuOnSelect={false}
                                                                options={WeekSelectOptions}
                                                                placeholder={T.t('gen_select_week')}
                                                                value={props.values.weeks}
                                                                onChange={(
                                                                  options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                                                ) => {
                                                                  const list: Array<Types.ISelectOption> = options
                                                                    ? (options as Array<Types.ISelectOption>)
                                                                    : [];
                                                                  props.setFieldValue('weeks',
                                                                    list.map(item => item.value).includes(-1) ? Weeks : list
                                                                  );
                                                                  props.setFieldValue(
                                                                    'week',
                                                                    list.map(item => item.value).includes(-1) ? Weeks.map((item: any) => item.value).join(",") : list.map(item => item.value).join(",")
                                                                  );
                                                                }}
                                                                noOptionsMessage={(): string => T.t('gen_select_no_week')}
                                                              />
                                                            </div>
                                                          </div>
                                                        </div> :
                                                        null
                          }
                          {
                            props.values.selected_type_ids !== undefined ?
                              <div className="col-6 pt-3 pb-3">
                                <button
                                  id='button_change'
                                  type="button"
                                  className="primary"
                                  onClick={() => props.handleSubmit()}
                                >
                                  {T.t('gen_change')}
                                </button>
                              </div> : null
                          }
                        </div>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal >
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IActivityPageProps): Types.IActivityPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IActivityPageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    selectOptions: store.state.select_options && store.state.select_options.activityPage,
    general_settings: store.state.general_settings,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.activity_page) {
    return (
      !!equal(
        prev.state.activity_page && prev.state.activity_page.results,
        next.state.activity_page && next.state.activity_page.results
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.activityPage,
        next.state.select_options && next.state.select_options.activityPage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ChangeAllDataModal);

export default container;
