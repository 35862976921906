import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';

export const constants = {
  COURSE_PERIOD_LIST_SEARCH: 'COURSE_PERIOD:LIST_SEARCH',
  COURSE_PERIOD_CREATE: 'COURSE_PERIOD:CREATE',
  COURSE_PERIOD_UPDATE: 'COURSE_PERIOD:UPDATE',
  COURSE_PERIOD_DELETE: 'COURSE_PERIOD:DELETE',
  COURSE_PERIOD_GET_BY_ID: 'COURSE_PERIOD:GET_BY_ID',
  COURSE_PERIOD_ADD_SCHEDULE: 'COURSE_PERIOD_ADD_SCHEDULE',
  COURSE_PERIOD_GET_LIST: 'EXAM_PERIOD_GET_LIST', //REDIRECT TO EXAM_PERIOD_GET_LIST
  ACTIVITY_RELATION_SEARCH: 'COURSE_PERIOD:ACTIVITY_RELATION_SEARCH',
  UPDATE_ACTIVITY_RELATION: 'COURSE_PERIOD:UPDATE_ACTIVITY_RELATION',
  POST_ACTIVITY_RELATION: 'COURSE_PERIOD:POST_ACTIVITY_RELATION',
  DELETE_ACTIVITY_RELATION: 'COURSE_PERIOD:DELETE_ACTIVITY_RELATION',
  ACTIVITY_DELETE_FROM_ACTIVITY_RELATION: 'COURSE_PERIOD:ACTIVITY_DELETE_FROM_ACTIVITY_RELATION',
  COURSE_TERM_HOURS_UPDATE: 'COURSE_TERM_HOURS_UPDATE',
  COURSE_TERM_ATTACHED_CLASSROOM: 'COURSE_TERM_ATTACHED_CLASSROOM',
  COURSE_TERM_DELETE_ATTACHED_CLASSROOM: 'COURSE_TERM_DELETE_ATTACHED_CLASSROOM'
};

export const CoursePeriodSearchInitialValues: Types.IFilterCoursePeriod = {
  name: '',
  status: [],
  terms: [],
  year: undefined,
  term_type: 0,
  page: 1,
  order_by: '',
  query: '',
  total: -1,
  size: 10
};

export const CoursePeriodFormInitialValues: Types.ITermItem = {
  term_id: undefined,
  status: 1,
  name: '',
  academic_term: undefined,
  term_type: 0,
  year: undefined,
  calendar_status: 0,
  description: ''
};

export const CourseCalendarInitialValues: Types.ICourseCalendarItem = {
  doesExist: false,
  time_interval: {
    doesExist: false,
    start_time: '08:00',
    end_time: '22:00',
    time_period: '01:00'
    // weekly_calendar: {}
  }
};

export const TermTypes = (T: Translator) => [
  { label: T.t('gen_term_fall'), value: 1 },
  { label: T.t('gen_term_spring'), value: 2 },
  { label: T.t('gen_term_summer'), value: 3 }
];

export const ActivityRelations = {
  Synchronous: 'ACTIVITY_RELATIONS:Synchronous',
  Asynchronous: 'ACTIVITY_RELATIONS:Asynchronous',
  Consecutive: 'ACTIVITY_RELATIONS:Consecutive',
  NonConsecutive: 'ACTIVITY_RELATIONS:NonConsecutive',
  SameDay: 'ACTIVITY_RELATIONS:SameDay',
  DifferentDay: 'ACTIVITY_RELATIONS:DifferentDay',
  DifferentDaySameTime: 'ACTIVITY_RELATIONS:DifferentDaySameTime',
  Conflictable: "ACTIVITY_RELATIONS:Conflictable"
};

export const ActivityRelationsTypeIds: Array<Types.ISelectOption> = [
  { label: ActivityRelations.Synchronous, value: 0 },
  { label: ActivityRelations.Asynchronous, value: 1 },
  { label: ActivityRelations.Consecutive, value: 2 },
  { label: ActivityRelations.NonConsecutive, value: 3 },
  { label: ActivityRelations.SameDay, value: 4 },
  { label: ActivityRelations.DifferentDay, value: 5 },
  { label: ActivityRelations.DifferentDaySameTime, value: 6 },
  { label: ActivityRelations.Conflictable, value: 7 }
];

export const ActivityRelationSearchInitialValues: Types.IFilterActivityRelation = {
  activity_noes: [],
  activity_noes_ids: [],
  course_codes: [],
  course_code: '',
  course_name: '',
  status: [],
  term_id: undefined,
  add_list: [],
  select_all: false,
  lecture_location: undefined,
  lecture_location_ids: undefined,
  campuses: undefined,
  campus_ids: undefined,
  instructors: [],
  instructor_ids: undefined,
  programs: [],
  program_ids: undefined,
  order_by: '',
  page: 1,
  query: '',
  total: -1,
  size: 10,
  activity_relation_type: 0
};

export const Days = (T: Translator, opts?: any) => [
  { label: T.t('gen_monday', opts), value: "Pazartesi" },
  { label: T.t('gen_tuesday', opts), value: "Salı" },
  { label: T.t('gen_wednesday', opts), value: "Çarşamba" },
  { label: T.t('gen_thursday', opts), value: "Perşembe" },
  { label: T.t('gen_friday', opts), value: "Cuma" },
  { label: T.t('gen_saturday', opts), value: "Cumartesi" },
  { label: T.t('gen_sunday', opts), value: "Pazar" }
];

export const ActivityRelationTypes = (T: Translator) => [
  { label: T.t('gen_activity_sync'), value: 0 },
  { label: T.t('gen_activity_async'), value: 1 },
  { label: T.t('gen_activity_consecutive'), value: 2 },
  { label: T.t('gen_activity_unconsecutive'), value: 3 },
  { label: T.t('gen_activity_same_day'), value: 4 },
  { label: T.t('gen_activity_diff_day'), value: 5 },
  { label: T.t('gen_activity_diff_day_same_time'), value: 6 },
  { label: T.t('gen_activity_conflictable'), value: 7 },
];