import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import { Log, Logger } from 'ng2-logger';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import * as Types from '../../store/types';
import { FacultySearchInitialValues } from '../../store/constants/faculty-const';
import { routes as Routes } from '../../store/constants/routes';
import * as GT from '../../tools/general-tools';
import FacultyForm from './faculty-form';
import FacultyImportForm from './faculty-import-form';
import SyncFacultyModal from './faculty-sync-modal';
import MultipleCheckbox from '../../components/checkboxes/multiple-checkbox';
import Spinner from '../../components/templates/spinner';
import MainLayout from '../layouts/main-layout';
import SimplePage from '../../components/templates/simple-page';
import APlanHeader from '../../components/templates/aplan-header';
import SortedColumn from '../../components/table/sorted-column';
import Paginate from '../../components/table/paginate';
import ImportModal, { ExcelImportKeys } from '../../components/excel-imports/import-modal';
import DownloadButton from '../../components/excel-imports/export';
import DownloadButtonSyncResult from '../../components/excel-imports/export-sync-result';
import { SectionTypes } from '../../store/constants/enums';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const L = Log.create('FacultyListPage');

class FacultyTable extends Component<Types.IFacultyPageProps, Types.IFacultyPageState> {
  state: Types.IFacultyPageState = {
    filters: FacultySearchInitialValues,
    filterIsOpen: false,
    facultyFormIsOpen: false,
    facultyImportFormIsOpen: false,
    integrationModalIsOpen: false,
    facultyId: undefined,
    all_ids: [],
    selected_ids: []
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    window.scrollTo(0, 0);
    if (window.location.pathname.startsWith("/static/") && this.props.term_id != -1) {
      this.props.dispatch(Actions.SetTermInfo(-1, -1));
    }
    this.init();
  }

  componentDidUpdate() {
    if (window.location.pathname.startsWith("/static/") && this.props.term_id != -1) {
      this.props.dispatch(Actions.SetTermInfo(-1, -1));
      this.props.dispatch(Actions.Notification('gen_selected_term_removed', 'gen_warning', 'warning'))
      this.init();
    }
  }

  init() {
    this.state = {
      filters: Object.assign({}, FacultySearchInitialValues),
      filterIsOpen: false,
      facultyFormIsOpen: false,
      facultyImportFormIsOpen: false,
      integrationModalIsOpen: false,
      facultyId: undefined,
      all_ids: [],
      selected_ids: []
    };
    this.state.filters.page = 1;
    this.searchFaculty();
    this.getFacultySelectOptions();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  searchFaculty() {
    this.props.dispatch(
      Actions.ApiRequest(Constants.faculty.FACULTY_LIST_SEARCH, this.state.filters, 'faculty-list-spin')
    );
  }

  getFacultySelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.faculty.FACULTY_GET_SELECT_OPTIONS, 'faculty-list-spin'));
  }

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchFaculty();
  };

  facultyFormIsOpen = () => {
    this.props.dispatch(Actions.Navigation(GT.Route(window.location.pathname, '/create')));
  };

  facultyImportFormIsOpen = () => {
    this.setState({
      ...this.state,
      facultyImportFormIsOpen: true
    });
  };

  facultyFormOnClose = (refresh: boolean) => {
    let path = window.location.pathname.startsWith('/static/') ? GT.Route(Routes.STATIC_FACULTY) : GT.Route(Routes.FACULTY)
    if (this.state.facultyFormIsOpen) {
      this.props.dispatch(Actions.Navigation(path));
    }
    if (refresh) {
      this.searchFaculty();
    }
  };

  facultyImportFormOnClose = (refresh: boolean) => {
    this.setState({
      ...this.state,
      facultyImportFormIsOpen: false
    });
    if (refresh) {
      this.searchFaculty();
    }
  };

  onSelectFaculty = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList
      });
    }
  };

  onPageChange = (page: number, size?: number) => {
    this.setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        page: page,
        size: size ? size : 10
      }
    }), this.searchFaculty)
  };

  onSizeChange = (size: number) => {
    this.setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        size: size
      }
    }), this.searchFaculty)
  }

  onFormReset = () => {
    this.state.filters = Object.assign({}, FacultySearchInitialValues);
    this.setState(this.state);
    this.searchFaculty();
  };

  onFilterFaculty(model: Types.IFilterFaculty, FormActions: FormikActions<Types.IFilterFaculty>) {
    this.setState(prev => ({
      ...prev,
      selected_ids: [],
      filters: {
        ...model,
        page: 1,
      },
      filterIsOpen: true,
    }));
    this.searchFaculty();
    FormActions.setSubmitting(false);
  }

  onFacultyEdit = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.currentTarget) {
      const id: string = e.currentTarget.dataset.id || '';
      this.props.dispatch(Actions.Navigation(GT.Route(window.location.pathname + '/' + id)));
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.props.dispatch(
          Actions.ApiRequest(
            Constants.faculty.FACULTY_LIST_SEARCH,
            this.state.filters,
            'faculty-list-spin',
            (response: any) =>
              this.setState({
                ...this.state,
                all_ids: response.all_ids,
                selected_ids: response.all_ids
              })
          )
        );
      } else {
        this.setState({
          ...this.state,
          all_ids: [],
          selected_ids: [],
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
    }
  };

  checkAllIdsSelected = (): boolean => {
    const all_ids = this.state.all_ids ? this.state.all_ids : [];
    const selected_ids = this.state.selected_ids ? this.state.selected_ids : [];
    let result: boolean = false;
    if (all_ids.length && selected_ids.length) {
      result = all_ids.every((item: number) => selected_ids.indexOf(item) !== -1);
    }
    return result;
  };

  onDeleteFaculty = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete_action'),
          body: T.t('gen_delete_faculty_question'),
          name: 'faculty_delete',
          icon: 'delete',
          iconColor: 'red',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (status: number) => {
              if (status == 200) {
                this.state.selected_ids = [];
                this.state.filters = FacultySearchInitialValues;
                this.setState(this.state);
                this.searchFaculty();
              }
            };

            const deleteList = this.state.selected_ids;
            this.props.dispatch(
              Actions.ApiRequest(Constants.faculty.FACULTY_DELETE, deleteList, 'faculty-list-spin', resultCallback)
            );
          }
        })
      );
    }
  };

  switchIntegrationModalStatus = () => {
    this.setState({
      ...this.state,
      integrationModalIsOpen: !this.state.integrationModalIsOpen
    });
  };

  static getDerivedStateFromProps(props: Types.IFacultyPageProps, state: Types.IFacultyPageState) {
    let hasNewState: boolean = false;
    if (props.match && props.match.params.id) {
      hasNewState = true;
      state.facultyFormIsOpen = true;
      if (props.match.params.id !== 'create') {
        state.facultyId = props.match.params.id;
      } else {
        state.facultyId = undefined;
      }
    } else {
      hasNewState = true;
      state.facultyFormIsOpen = false;
      state.facultyId = undefined;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  onFacultyImported = () => {
    this.searchFaculty();
  };

  render() {
    let facultyList = this.props.results;
    return (
      <MainLayout header={<APlanHeader />}>
        <SimplePage name="faculty-page">
          <div className="main list-campus">
            <div className="container-fluid">
              <h4 className="mb-4 mb-sm-0">{T.t("gen_faculties")}</h4>
              <Spinner name="faculty-list-spin" />
              {/* TABLE STARTS HERE  */}
              <div className="white-container mt-4">
                <div className="row align-items-center mb-1">
                  <div className="col-md-10 col-sm-4 col-12 with-gap">
                    {this.props.user && this.props.user.role === 's' ? (
                      this.state.selected_ids && this.state.selected_ids.length ? (
                        <React.Fragment>
                          <button
                            id='button_cancel_selection'
                            className="tertiary float-left"
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                selected_ids: []
                              });
                            }}
                          >
                            <i className="material-icons">close</i>
                            <span>
                              {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                            </span>
                          </button>
                          <button
                            id='button_delete_faculty'
                            className="danger"
                            onClick={this.onDeleteFaculty}
                          >
                            <i className="material-icons">delete_outline</i>
                            <span> {T.t('gen_delete_selected')}</span>
                          </button>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <button
                            id='button_add'
                            className="primary"
                            onClick={this.props.term_id === -1 ? this.facultyFormIsOpen : this.facultyImportFormIsOpen}
                          >
                            <i className="material-icons">add</i>
                            {T.t('gen_add_faculty')}
                          </button>
                          {this.props.term_id === -1 ? (
                            <React.Fragment>
                              <button
                                id='button_list_sync'
                                className="secondary"
                                onClick={this.switchIntegrationModalStatus}
                              >
                                <i className="material-icons">playlist_add</i>
                                {T.t('gen_list_sync_jobs')}
                              </button>
                              <ImportModal
                                title={T.t('gen_add_with_excel')}
                                componentKey={ExcelImportKeys.Faculties}
                                dispatch={this.props.dispatch}
                                onImport={this.onFacultyImported}
                              />
                              <DownloadButtonSyncResult title={'SyncFacultiesResult'} sectionType={SectionTypes.SyncFacultiesResult} />
                            </React.Fragment>
                          ) : null}
                          <DownloadButton title={'Fakülteler'} sectionType={SectionTypes.Faculties} />
                        </React.Fragment>
                      )
                    ) : null}
                  </div>
                  <div className="col-md-2 col-sm-8 col-12 text-right">
                    <div className="options align-items-center with-gap right">
                      <button
                        id='button_filter'
                        className="ghost"
                        onClick={() => {
                          this.state.filterIsOpen = !this.state.filterIsOpen;
                          this.setState(this.state);
                        }}
                      >
                        <i className="material-icons">filter_list</i>
                        <span>{T.t('gen_filter')}</span>
                      </button>
                    </div>
                  </div>
                </div>
                {/* FILTER STARTS HERE  */}
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3" style={{}}>
                    <Formik
                      initialValues={FacultySearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterFaculty(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterFaculty>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-4">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="faculty_code"
                                    name="faculty_code"
                                    value={props.values.faculty_code}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="faculty_code">{T.t('gen_code')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="name"
                                    name="name"
                                    value={props.values.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-3">
                              <div className="col-md-6">
                                <div className="text-left">
                                  <h6>{T.t('gen_status')}</h6>
                                  <div className="tick-radio position-relative mb-3 d-inline-block">
                                    <MultipleCheckbox name="status" text={T.t('gen_active')} value={1} />
                                  </div>
                                  <div className="tick-radio position-relative mb-3 d-inline-block ml-4">
                                    <MultipleCheckbox name="status" text={T.t('gen_passive')} value={0} />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6 with-gap">
                                <button
                                  id='button_arrow_upword'
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="ghost"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  id='button_delete_sweep'
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="danger"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  id='button_search'
                                  type="button"
                                  className="secondary xl"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                      <thead>
                        <tr>
                          {this.props.user && this.props.user.role === 's' ? (
                            <th data-cell="select">
                              <div className="tick-radio position-relative">
                                <input
                                  id='button_select'
                                  type="checkbox"
                                  className="form-radio"
                                  checked={this.checkAllIdsSelected()}
                                  onChange={this.onSelectAll}
                                />
                              </div>
                            </th>
                          ) : null}
                          <SortedColumn
                            datacell="status"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_status')}
                            sortkey="status"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="faculty_code"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_code')}
                            sortkey="faculty_code"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <SortedColumn
                            datacell="name"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_name')}
                            sortkey="name"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          />
                          <th scope="col" className="text-center">
                            {T.t('gen_connected_programs')}
                          </th>
                          <th scope="col" className="text-center">
                            {T.t('gen_description')}
                          </th>
                          {this.props.user && this.props.user.role === 's' ? (
                            <th scope="col" className="d-none d-lg-table-cell d-xl-table-cell text-center">
                              <span className="text-right">{T.t('gen_actions')}</span>
                            </th>
                          ) : null}
                        </tr>
                      </thead>
                      <tbody>
                        {facultyList && facultyList.length
                          ? facultyList.map((item: any) => {
                            return (
                              <tr key={'faculty-' + item.faculty_id} data-title={item.name}>
                                {this.props.user && this.props.user.role === 's' ? (
                                  <td data-cell="select">
                                    <div className="tick-radio position-relative">
                                      <input
                                        id='select_faculty'
                                        type="checkbox"
                                        className="form-radio"
                                        checked={
                                          this.state.selected_ids &&
                                          this.state.selected_ids.indexOf(item.faculty_id ? item.faculty_id : -1) > -1
                                        }
                                        data-id={item.faculty_id}
                                        onChange={this.onSelectFaculty}
                                      />
                                    </div>
                                  </td>
                                ) : null}
                                <td scope="row" data-label={T.t('gen_status')} className="text-center">
                                  <div className="tags ml-1 mr-4">
                                    <button
                                      id='status'
                                      className={
                                        `tw-p-1 small-tag text-uppercase` + (item.status == 1 ? ` co-green` : ` tag-red`)
                                      }
                                    >
                                      {GT.GetActiveStatus(item.status)}
                                    </button>
                                  </div>
                                </td>
                                <td scope="row" data-label={T.t('gen_code')} className="text-center">
                                  {item.faculty_code}
                                </td>
                                <td data-label={T.t('gen_name')} className="text-center">
                                  {item.name}
                                </td>
                                <td className="text-center">
                                  {item.programs && item.programs.length > 0 ?
                                    <div className="table-scrollable-td">{item.programs && item.programs.map((i: any, index: any) => (index == item.programs.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                                  }
                                </td>
                                <td data-label={T.t('gen_description')} className="text-center">
                                  {item.description && item.description.length ?
                                    <div className="table-scrollable-td">{item.description}</div> : '-'}
                                </td>
                                {this.props.user && this.props.user.role === 's' ? (
                                  <td data-label={T.t('gen_actions')} className="table-buttons text-center">
                                    <div className="table-buttons-wrapper">
                                      <button
                                        id='button_edit'
                                        data-toggle="tooltip"
                                        data-id={item.faculty_id}
                                        onClick={this.onFacultyEdit}
                                        title={T.t('gen_edit')}
                                        className="ghost square btn-sm table-button"
                                      >
                                        <span className="d-block" data-toggle="modal" data-target="#addNew">
                                          <i className="material-icons">edit</i>
                                        </span>
                                      </button>
                                    </div>
                                  </td>
                                ) : null}
                              </tr>
                            );
                          })
                          : null}
                      </tbody>
                    </table>
                    <div className="row-options justify-content-end">
                      <div
                        className="page-sorting d-flex align-items-center justify-content-center"
                        style={{ marginTop: '5px' }}
                      >
                        {this.props.results && this.props.results.length > 0 && (
                          <Paginate filters={this.props.filters} onPageChange={this.onPageChange} onSizeChange={this.onSizeChange} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SimplePage>
        <FacultyForm
          facultyId={this.state.facultyId}
          formIsOpen={this.state.facultyFormIsOpen}
          onClose={this.facultyFormOnClose}
        />
        <FacultyImportForm
          formIsOpen={this.state.facultyImportFormIsOpen}
          onClose={this.facultyImportFormOnClose}
        />
        {this.state.integrationModalIsOpen ? (
          <SyncFacultyModal
            modalIsOpen={this.state.integrationModalIsOpen}
            onClose={this.switchIntegrationModalStatus}
            onUpdateList={() => this.searchFaculty()}
          />
        ) : null}
      </MainLayout>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IFacultyPageProps): Types.IFacultyPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IFacultyPageProps = Object.assign({}, ownProps, {
    term_id: store.state.term_id,
    user: store.state.user,
    results: store.state.faculty_page && store.state.faculty_page.results,
    filters: store.state.faculty_page && store.state.faculty_page.filters,
    selectOptions: store.state.select_options && store.state.select_options.facultyPage
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.faculty_page) {
    return (
      !!equal(
        prev.state.user,
        next.state.user
      ) &&
      !!equal(
        prev.state.faculty_page && prev.state.faculty_page.results,
        next.state.faculty_page && next.state.faculty_page.results
      ) &&
      !!equal(
        prev.state.select_options && prev.state.select_options.facultyPage,
        next.state.select_options && next.state.select_options.facultyPage
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, { areStatesEqual })(FacultyTable);

export default container;
