import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Formik, FormikActions, FormikProps } from 'formik';
import { ValueType } from 'react-select/lib/types';
import Select from 'react-select';
import { Log } from 'ng2-logger';
import { Modal } from 'reactstrap';
import Paginate from '../../components/table/paginate';
import Announce from '../../components/templates/announce';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import { ClassroomSearchInitialValues } from '../../store/constants/classroom-const';
import { SyncCampusSearchInitialValuesModal } from '../../store/constants/sync-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import { routes as Routes } from '../../store/constants/routes';
import Translator from '../../services/translate-factory';

const T = Translator.create();
const L = Log.create('CampusIntegrationModal');

function getInitialState(): any {
  const initialValues: any = {
    filters: Object.assign({}, SyncCampusSearchInitialValuesModal),
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    selected_objects: []
  };
  return Object.assign({}, initialValues);
}

class SyncClassroomModal extends Component<any, any> {
  state = getInitialState();

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.getClassrooms();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  getClassrooms() {
    const model = {
      sync_type: 3,
      filters: this.state.filters
    }
    this.props.dispatch(
      Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_LIST_SYNC_JOBS, model, 'synchronization-spinner')
    );
  }

  onClassroomTransfer = () => {
    const model = {
      sync_job_id: this.props.synchronization.sync_job_id
    }
    this.props.dispatch(
      Actions.ApiRequest(Constants.synchronization.SYNCHRONIZATION_CLASSROOM_LIST_SEARCH, this.state.filters, 'synchronization-spinner')
    );
    this.props.dispatch(Actions.Navigation(GT.Route(Routes.SYNC_CLASSROOM)));
  };

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.props.dispatch(
        Actions.ApiRequest(Constants.integration.GET_INTEGRATION, { reset: true }, 'integration-spinner')
      );
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  /*onSelectCampus = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e && e.currentTarget) {
          let checkedList = Object.assign([], this.state.selected_ids);
          let objectList = Object.assign([], this.state.selected_objects);
          let id: string = e.currentTarget.dataset.id || '';
          let dataItem = e.currentTarget.dataset.item || '';
          L.data('onselectcampus_item', JSON.parse(dataItem));

          if (e.target.checked) {
              checkedList.push(id);
              objectList.push(JSON.parse(dataItem));
          } else {
              let index = checkedList.indexOf(id);
              let objectIndex = objectList.findIndex((obj: any) => obj.campus_code == id);
              if (index !== -1) {
                  checkedList.splice(index, 1);
                  objectList.splice(objectIndex, 1);
                  this.state.filters.select_all = false;
              }
              this.setState({
                  ...this.state,
                  filters: {
                      ...this.state.filters,
                      select_all: false
                  }
              });
          }
          this.setState({
              ...this.state,
              selected_ids: checkedList,
              selected_objects: objectList
          });
      }
  };*/

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.getClassrooms();
      } else {
        this.state.all_ids = [];
        this.state.selected_ids = [];
        this.state.filters.select_all = false;
        this.setState(this.state);
      }
    }
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.getClassrooms();
  };

  onPageChange = (page: number, size?: number) => {
    this.setState((prev: any) => ({
      ...prev,
      filters: {
        ...prev.filters,
        page: page,
        size: size ? size : 10
      }
    }), this.getClassrooms)
  };

  onSizeChange = (size: number) => {
    this.setState((prev: any) => ({
      ...prev,
      filters: {
        ...prev.filters,
        size: size
      }
    }), this.getClassrooms)
  }

  onFormReset = () => {
    this.state.filters = Object.assign({}, ClassroomSearchInitialValues);
    this.state.all_ids = [];
    this.setState(this.state);
    this.getClassrooms();
  };

  onFilterClassroom(model: Types.IFilterClassroom, FormActions: FormikActions<Types.IFilterClassroom>) {
    this.setState((prev: any) => ({
      ...prev,
      filters: {
        ...model,
        page: 1
      },
      filterIsOpen: true,
      selected_ids: [],
    }))
    this.getClassrooms();
    FormActions.setSubmitting(false);
  }

  static getDerivedStateFromProps(props: any, state: any) {
    let hasNewState: boolean = false;
    if (state.filters.select_all) {
      hasNewState = true;
      state.all_ids = props.all_ids;
      state.selected_ids = props.all_ids;
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let classroomList = this.props.synchronization && this.props.synchronization.listSyncJobs;
    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_sync_jobs')}
            </h6>
            <button
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                {/* Filter starts here */}
                <button
                  className="ghost float-right ml-3 mr-3"
                  style={{ margin: '5px' }}
                  onClick={() => {
                    this.state.filterIsOpen = !this.state.filterIsOpen;
                    this.setState(this.state);
                  }}
                >
                  <i className="material-icons mr-2">filter_list</i>
                  <span>{T.t('gen_filter')}</span>
                </button>
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3">
                    <Formik
                      initialValues={ClassroomSearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterClassroom(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterClassroom>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-4">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="classroom_code"
                                    name="classroom_code"
                                    value={props.values.classroom_code}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="classroom_code">{T.t('gen_code')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="name"
                                    name="name"
                                    value={props.values.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_classroom_type')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={props.values.classroom_types}
                                      placeholder={T.t('gen_select_type')}
                                      value={props.values.classroom_types ? props.values.classroom_types : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('classroom_types', list);
                                        props.setFieldValue(
                                          'classroom_type_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_campus_and_building')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.buildings
                                          ? this.props.selectOptions.buildings
                                          : []
                                      }
                                      placeholder={T.t('gen_select_building')}
                                      value={props.values.buildings ? props.values.buildings : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('buildings', list);
                                        props.setFieldValue(
                                          'building_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_building')}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_classroom_features')}</label>
                                    <Select
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={props.values.classroom_features}
                                      placeholder={T.t('gen_select_feature')}
                                      value={props.values.classroom_features}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('classroom_features', list);
                                        props.setFieldValue(
                                          'classroom_feature_codes',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_feature')}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 ghost mr-2"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none danger mt-md-0 mt-2 mb-md-0 mb-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  type="button"
                                  className="secondary xl mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                {/* Filter ends here */}
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th scope="col" className="text-center">{T.t('gen_actions')}</th>
                        <th scope="col" className="text-center">{T.t('gen_sync_type')}</th>
                        <th scope="col" className="text-center">{T.t('gen_job_parameters')}</th>
                        <th scope="col" className="text-center">{T.t('gen_user_id')}</th>
                        <th scope="col" className="text-center">{T.t('gen_term_id')}</th>
                        <th scope="col" className="text-center">{T.t('gen_year')}</th>
                        <th scope="col" className="text-center">{T.t('gen_term')}</th>
                        <th scope="col" className="text-center">{T.t('gen_status')}</th>
                        <th scope="col" className="text-center">{T.t('gen_attempted_count')}</th>
                        <th scope="col" className="text-center">{T.t('gen_completion_date')}</th>
                        <th scope="col" className="text-center">{T.t('gen_queue_date')}</th>
                        <th scope="col" className="text-center">{T.t('gen_sync_job_id')}</th>
                        <th scope="col" className="text-center">{T.t('gen_create_date')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {classroomList && classroomList.length ? (
                        classroomList.map((item: any) => {
                          return (
                            <tr key={item.sync_job_id}>
                              <td data-label={T.t('gen_actions')} className="table-buttons-visible text-center">
                                <div className="table-buttons-wrapper">
                                  <button
                                    data-toggle="tooltip"
                                    data-id={item.classroom_id}
                                    onClick={this.onClassroomTransfer}
                                    title={T.t('gen_edit')}
                                    className="ghost square btn-sm table-button"
                                  >
                                    <span className="d-block" data-toggle="modal" data-target="#addNew">
                                      <i className="material-icons">edit</i>
                                    </span>
                                  </button>
                                </div>
                              </td>
                              <td scope="row" data-label={T.t('gen_sync_type')} className="text-center">
                                {item.sync_type}
                              </td>
                              <td data-label={T.t('gen_job_parameters')} className="text-center">
                                {item.job_parameters}
                              </td>
                              <td data-label={T.t('gen_user_id')} className="text-center">
                                {item.user_id}
                              </td>
                              <td data-label={T.t('gen_term_id')} className="text-center">
                                {item.term_id}
                              </td>
                              <td data-label={T.t('gen_year')} className="text-center">
                                {item.year}
                              </td>
                              <td data-label={T.t('gen_term')} className="text-center">
                                {item.term}
                              </td>
                              <td data-label={T.t('gen_status')} className="text-center">
                                {
                                  item.status == '1' ? T.t('gen_started_transfer') :
                                    item.status == '2' ? T.t('gen_success') :
                                      item.status == '3' ? T.t('gen_failed') :
                                        item.status == '4' ? T.t('gen_started_sync') :
                                          item.status == '5' ? T.t('gen_success') :
                                            item.status == '6' ? T.t('gen_failed') :
                                              item.status
                                }
                              </td>
                              <td data-label={T.t('gen_attempt_count')} className="text-center">
                                {item.attempt_count}
                              </td>
                              <td data-label={T.t('gen_completion_date')} className="text-center">
                                {item.completion_date}
                              </td>
                              <td data-label={T.t('gen_queue_date')} className="text-center">
                                {item.queue_date}
                              </td>
                              <td data-label={T.t('gen_sync_job_id')} className="text-center">
                                {item.sync_job_id}
                              </td>
                              <td data-label={T.t('gen_created_date')} className="text-center">
                                {item.created_date}
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan={16}>
                            <Announce title={T.t('gen_no_records_found')} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    >
                      {this.props.results && this.props.results.length > 0 ? (
                        <Paginate filters={this.props.filters} onPageChange={this.onPageChange} onSizeChange={this.onSizeChange} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: any): any => {
  if (!store) {
    return ownProps;
  }
  const newProps: any = Object.assign({}, ownProps, {
    results: store.state.integration && store.state.integration.results,
    filters: store.state.integration && store.state.integration.filters,
    all_ids: store.state.integration && store.state.integration.all_ids,
    term_id: store.state.term_id,
    term_list: store.state.term_list,
    synchronization: store.state.synchronization
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.synchronization) {
    return (
      !!equal(
        prev.state.integration && prev.state.integration.results,
        next.state.integration && next.state.integration.results
      ) &&
      !!equal(
        prev.state.integration && prev.state.integration.filters,
        next.state.integration && next.state.integration.filters
      ) &&
      !!equal(
        prev.state.integration && prev.state.integration.all_ids,
        next.state.integration && next.state.integration.all_ids
      ) &&
      !!equal(prev.state.term_id, next.state.term_id) &&
      !!equal(prev.state.term_list, next.state.term_list) &&
      !!equal(prev.state.synchronization, next.state.synchronization)
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SyncClassroomModal);

export default container;
