import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';
import { IExtendedEvent } from '../../../store/types';
import { CapacityType } from '../add-place';

export const FormValidation = (T: Translator) => Yup.object().shape({
  name: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
  year: Yup.number().required(T.t('gen_cannot_leave_empty')),
  academic_terms: Yup.array().min(1, T.t('gen_cannot_leave_empty')),
  description: Yup.string().required(T.t('gen_cannot_leave_empty')).max(1000, T.t('gen_can_be_up_to_n').replace('{0}', '1000')),
  time_range: Yup.object().required(T.t('gen_cannot_leave_empty'))
});

export const EventFormValidation = (T: Translator) => Yup.object().shape({
  title: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200')),
  start_date: Yup.string().required(T.t('gen_cannot_leave_empty')),
  end_date: Yup.string().required(T.t('gen_cannot_leave_empty')),
  event_type: Yup.object().nullable().required(T.t('gen_cannot_leave_empty')),
  selectedPlace: Yup.object().required(T.t('gen_cannot_leave_empty')),
});

export const initialEventFormState = (): IExtendedEvent => ({
  event_id: undefined,
  title: '',
  start_date: null,
  end_date: null,
  description: '',
  allDay: false,
  status_approval: undefined,
  status_: undefined,
  event_type: undefined,

  building: undefined,
  classroom: undefined,
  responsibles: [],
  user_created_by: undefined,
  user_approved_by: undefined,
  selectedPlace: undefined,
  resource: null,
  changed_at: null,
  changed_by: '',

  // IExtendedEvent alanları
  buildings: [],
  classroom_floor: undefined,
  classroom_features: [],
  classroom_types: [],
  classroom_seating_types: [],
  classroom_seating_arrangements: [],
  lecture_capacity_min: undefined,
  lecture_capacity_max: undefined,
  exam_capacity_min: undefined,
  exam_capacity_max: undefined,
  isSearched: false,
  capacity_type: CapacityType.COURSE //its not working
})

/*

export interface IExtendedEvent extends RBCEvents {
  campuses?: Array<ISelectOption>
  buildings?: Array<ISelectOption>
  classroom_floor?: number
  classroom_features?: Array<ISelectOption>
  classroom_types?: Array<ISelectOption>
  classroom_seating_types?: Array<ISelectOption>
  classroom_seating_arrangements?: Array<ISelectOption>
  lecture_capacity_min?: number
  lecture_capacity_max?: number
  exam_capacity_min?: number
  exam_capacity_max?: number
  isSearched?: boolean,
}
RBCEvents{
    event_id?: number,
    title?: string,
  start_date?: any,
  description?: string
  end_date?: any,
  allDay?: boolean,
  status_approval?: RBCEventStatus,
  status_?: number,
  event_type?: ISelectOption,
  campus?: ISelectOption,
  building?: ISelectOption,
  classroom?: ISelectOption,
  responsibles?: Array<ISelectOption>,
  user_created_by?: ISelectOption,
  user_approved_by?: ISelectOption,
  
  selectedPlace?: IAvailablePlace,
  resource?: any,
  changed_at?: any
  changed_by?: string
  }

*/