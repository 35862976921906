import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import { Modal } from 'reactstrap';
import Paginate from '../../components/table/paginate';
import Announce from '../../components/templates/announce';
import SortedColumn from '../../components/table/sorted-column';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import {
  ClassroomSearchInitialValues,
  ClassroomHoursSearchInitialValues,
  ClassroomFeatureOptions
} from '../../store/constants/classroom-const';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import { ExamTypes } from '../../store/constants/course-const';
import Translator from '../../services/translate-factory';
import { filterClassroomTypesForEventModuleStatus } from '../../util/sort';
import { ClassroomTypesLabel } from '../../store/constants/setting-const';

const T = Translator.create();
const L = Log.create('ClassroomAddModal');

function getInitialState(): Types.IClassroomPageState {
  const initialValues: Types.IClassroomPageState = {
    filters: Object.assign({}, ClassroomHoursSearchInitialValues),
    filterIsOpen: false,
    all_ids: [],
    selected_ids: [],
    classroomFormIsOpen: false,
    classroomId: undefined,
    selected_classrooms: []
  };
  initialValues.filters.onlySelectWithoutClassroomHours = true;
  return Object.assign({}, initialValues);
}

class ClassroomAddModal extends Component<Types.IClassroomPageProps, Types.IClassroomPageState> {
  state = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.getClassroomSelectOptions();
    this.getBuildingsByCampusesAtClassrooms([]);
    this.filterByCourse();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  filterByCourse() {
    if (this.props.selectedCourse && this.props.courseList.length == 1) {
      if (this.props.selectedCourse.classroom_type) {
        this.state.filters.classroom_type_ids = [this.props.selectedCourse.classroom_type_select.value];
        this.state.filters.classroom_types = [this.props.selectedCourse.classroom_type_select];
      }
      if (this.props.courseList && this.props.courseList.length == 1 && this.props.selectedCourse.campuses) {
        this.state.filters.campus_ids = this.props.selectedCourse.campus_ids;
        this.state.filters.campuses = this.props.selectedCourse.campuses;
      }
      this.searchClassrooms();
    } else {
      this.searchClassrooms();
    }
  }

  searchClassrooms() {
    this.state.filters.term_id = this.props.term_id;
    this.state.filters.status = [1];
    this.state.filters.onlySelectWithoutClassroomHours = true;

    if (this.props.courseList && this.props.courseList.length == 1) {
      if (this.state.filters.campus_ids && this.props.selectedCourse) {
        if (this.state.filters.campus_ids.length == 0) {
          this.state.filters.campus_ids = this.props.selectedCourse.campus_ids;
        } else {
          let intersection = this.state.filters.campus_ids.filter(x => this.props.selectedCourse.campus_ids.includes(x));
          if (intersection && intersection.length > 0) {
            this.state.filters.campus_ids = intersection;
          } else {
            this.state.filters.campus_ids = [-1];
          }
        }
      } else if (this.props.selectedCourse && this.state.filters.campus_ids == undefined) {
        this.state.filters.campus_ids = this.props.selectedCourse.campus_ids;
      }
    }

    if (this.props.calledFromCourseTab) {
      this.props.dispatch(
        Actions.ApiRequest(
          Constants.exam_period.EXAM_PERIOD_GET_CLASSROOM_HOURS,
          this.state.filters,
          'ep-classroom-list'
        )
      );
    } else {
      this.props.dispatch(
        Actions.ApiRequest(Constants.classroom.CLASSROOM_LIST_SEARCH, this.state.filters, 'ep-classroom-list')
      );
    }
  }

  getClassroomSelectOptions() {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_SELECT_OPTIONS, 'classroom-list-spin'));
  }

  getBuildingsByCampusesAtClassrooms = (campusIds: any) => {
    this.props.dispatch(Actions.ApiRequest(Constants.classroom.CLASSROOM_GET_BUILDINGS_BY_CAMPUSES, campusIds, 'classroom-list-spin'));
  }

  setClose = (refresh: boolean = false) => {
    if (this.props.onClose) {
      this.setState(getInitialState());
      this.props.onClose(refresh);
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  onUpdateListFromModal = (refresh: boolean = false) => {
    if (this.props.onUpdateList) {
      this.props.onUpdateList(refresh);
    }
  };

  onSelectClassroom = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      let checkedList = Object.assign([], this.state.selected_ids);
      let stringID: string = e.currentTarget.dataset.id || '';
      let id = parseInt(stringID, 10);

      if (e.target.checked) {
        checkedList.push(id);
      } else {
        let index = checkedList.indexOf(id);
        if (index !== -1) {
          checkedList.splice(index, 1);
          this.state.filters.select_all = false;
        }
        this.setState({
          ...this.state,
          filters: {
            ...this.state.filters,
            select_all: false
          }
        });
      }
      this.setState({
        ...this.state,
        selected_ids: checkedList,
        selected_id: id
      });
    }
  };

  onSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e && e.currentTarget) {
      if (e.currentTarget.checked) {
        this.state.filters.select_all = true;
        this.setState(this.state);
        this.setState({
          ...this.state,
          selected_classrooms: [],
          selected_total_student_count: 0,
          selected_id: undefined
        });
        this.searchClassrooms();
      } else {
        this.state.all_ids = [];
        this.state.selected_ids = [];
        this.state.filters.select_all = false;
        this.state.selected_classrooms = [];
        this.setState(this.state);
      }
    }
  };

  addClassrooms = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      if (this.props.calledFromCourseTab && this.props.selectedCourseIDs && this.props.selectedCourseIDs.length) {
        if (this.state.selected_exam_type_project && this.state.selected_exam_type_project.length == 0 && this.state.selected_total_student_count && this.state.selected_total_exam_capacity && this.state.selected_total_student_count > this.state.selected_total_exam_capacity) {
          this.props.dispatch(
            Actions.ShowModal({
              title: T.t('gen_warning'),
              body: T.t('gen_classroom_assignment_by_student_count_info').replace('{0}', this.state.selected_total_student_count).replace('{1}', this.state.selected_total_exam_capacity),
              name: 'examPeriod_attach_classrooms',
              icon: 'error_outline',
              iconColor: 'red',
              cancel: T.t('gen_close')
            })
          );
        } else {
          this.props.dispatch(
            Actions.ShowModal({
              title: T.t('gen_classrooms_assign_courses_planning'),
              body: T.t('gen_assign_classroom_to_courses_question'),
              name: 'examPeriod_attach_classrooms',
              icon: 'warning',
              iconColor: 'red',
              confirm: T.t('gen_yes'),
              cancel: T.t('gen_cancel'),
              onConfirm: () => {
                const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
                  if (status == 200) {
                    this.setClose();
                    this.onUpdateListFromModal();
                  }
                };

                var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
                let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
                this.state.filters.term_id = id;
                this.state.filters.classroom_ids = this.state.selected_ids;
                this.state.filters.course_ids = this.props.selectedCourseIDs;

                this.props.dispatch(
                  Actions.ApiRequest(
                    Constants.exam_period.EXAM_PERIOD_ATTACH_CLASSROOMS_TO_COURSES,
                    this.state.filters,
                    'ep-instructor-list',
                    resultCallback
                  )
                );
              }
            })
          );
        }
      }
      else {
        this.props.dispatch(
          Actions.ShowModal({
            title: T.t('gen_classrooms_add_planning'),
            body: T.t('gen_add_classrooms_to_exam_schedule_question'),
            name: 'examPeriod_instructors_delete',
            icon: 'warning',
            iconColor: 'red',
            confirm: T.t('gen_yes'),
            cancel: T.t('gen_cancel'),
            onConfirm: () => {
              const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
                if (status == 200) {
                  this.setClose();
                  this.onUpdateListFromModal();
                  this.setState({
                    ...this.state,
                    selected_ids: []
                  });
                }
              };

              var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
              let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
              this.state.filters.term_id = id;
              this.state.filters.add_list = this.state.selected_ids;

              this.props.dispatch(
                Actions.ApiRequest(
                  Constants.exam_period.EXAM_PERIOD_ADD_CLASSROOMS_TO_PERIOD,
                  this.state.filters,
                  'ep-instructor-list',
                  resultCallback
                )
              );
            }
          })
        );
      }
    }
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.searchClassrooms();
  };

  onPageChange = (page: number, size?: number) => {
    this.setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        page: page,
        size: size ? size : 10
      }
    }), this.searchClassrooms)
  };

  onSizeChange = (size: number) => {
    this.setState(prev => ({
      ...prev,
      filters: {
        ...prev.filters,
        size: size
      }
    }), this.searchClassrooms)
  }

  onFormReset = () => {
    this.state.filters = Object.assign({}, ClassroomSearchInitialValues);
    if (this.props.courseList && this.props.courseList.length == 1) {
      this.state.filters.campus_ids = this.props.selectedCourse && this.props.selectedCourse.campus_ids;
    }
    this.state.filters.onlySelectWithoutClassroomHours = true;
    this.state.all_ids = [];
    this.setState(this.state);
    this.searchClassrooms();
  };

  onFilterClassroom(model: Types.IFilterClassroom, FormActions: FormikActions<Types.IFilterClassroom>) {
    this.setState(prev => ({
      ...prev,
      selected_ids: [],
      filters: {
        ...model,
        page: 1,
      },
      filterIsOpen: true,
    }));
    this.searchClassrooms();
    FormActions.setSubmitting(false);
  }

  static getDerivedStateFromProps(props: Types.IClassroomPageProps, state: Types.IClassroomPageState) {
    let hasNewState: boolean = false;
    if (state.filters.select_all) {
      hasNewState = true;
      state.all_ids = props.all_ids;
      state.selected_ids = props.all_ids;
    }

    if (props.calledFromCourseTab && props.calledFromCourseTab === true) {
      state.classroom_list = props.term_classrooms;
      if (props.total_exam_capacity && props.total_lecture_capacity) {
        hasNewState = true;
        state.selected_total_exam_capacity = props.total_exam_capacity;
        state.selected_total_lecture_capacity = props.total_lecture_capacity;
      }
    } else {
      state.results = props.results;
      if (props.defination_total_exam_capacity && props.defination_total_lecture_capacity) {
        hasNewState = true;
        state.selected_total_exam_capacity = props.defination_total_exam_capacity;
        state.selected_total_lecture_capacity = props.defination_total_lecture_capacity;
      }
    }

    let classroom_list = state.results ? state.results : state.classroom_list ? state.classroom_list.map((item: Types.IExamPeriod_ClassroomItem) => item.classroomInfo) : null;

    if (state.filters.select_all && classroom_list != null) {
      state.selected_classrooms = classroom_list;
    }

    if (state.selected_id && classroom_list != null) {
      state.selected_classroom = classroom_list.filter((classroom: any) => classroom.classroom_id == state.selected_id);
      let isAlreadyOnSelectedClassroom = state.selected_classrooms!.filter((classroom: any) => classroom.classroom_id == state.selected_id);
      if (isAlreadyOnSelectedClassroom.length != 0) {
        let index = state.selected_classrooms!.findIndex((item: any) => isAlreadyOnSelectedClassroom.map((item: any) => item.classroom_id).includes(Number(item.classroom_id)));
        state.selected_classrooms!.splice(index, 1);
      } else {
        state.selected_classrooms = state.selected_classroom != undefined && state.selected_classroom != null ? state.selected_classrooms!.concat(state.selected_classroom) : state.selected_classrooms;
      }
      state.selected_total_exam_capacity = state.selected_classrooms!.map((classroom: any) => classroom.exam_capacity).reduce((a, b) => a + b, 0);
      state.selected_total_lecture_capacity = state.selected_classrooms!.map((classroom: any) => classroom.lecture_capacity).reduce((a, b) => a + b, 0);
      state.selected_id = undefined;
    }

    if (props.courseList) {
      state.selected_total_student_count = props.courseList!.map((course: any) => course.student_count_total).reduce((a: any, b: any) => a + b, 0);
      if (props.courseList.length == 1) {
        state.exam_session_count = props.courseList!.map((course: any) => course.exam_session);
        if (state.exam_session_count && state.exam_session_count > 1 && state.selected_total_exam_capacity)
          state.selected_total_exam_capacity = state.exam_session_count * state.selected_total_exam_capacity

        state.selected_exam_type_project = props.courseList!.filter((course: any) => ExamTypes(T)[1].value == course.exam_type);
      }
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let classroomList = this.props.calledFromCourseTab === true ? this.state.classroom_list : this.state.results;

    let classroomFeatureOptions = ClassroomFeatureOptions(T);
    if (this.props.selectOptions && this.props.selectOptions.additional_classroom_features && this.props.selectOptions.additional_classroom_features.length > 0) {
      classroomFeatureOptions = [...ClassroomFeatureOptions(T), ...this.props.selectOptions.additional_classroom_features].sort((a, b) => (a.label.toLowerCase() >= b.label.toLocaleLowerCase() ? 1 : -1));
    }

    let ClassroomTable =
      this.state.results && this.state.results.length ? (
        this.state.results.map((item: Types.IClassroomItem) => {
          let classroomFeatures: any = [];
          item.feature_codes &&
            item.feature_codes.length &&
            item.feature_codes.map((featureCode: string) => {
              let feature = classroomFeatureOptions.find((item: Types.ISelectOption) =>
                item.value.localeCompare(featureCode, undefined, { sensitivity: 'accent' })
              );
              classroomFeatures.push(feature);
            });
          return (
            <tr key={'classroom-' + item.classroom_id} data-title={item.name}>
              <td data-cell="select">
                <div className="tick-radio position-relative">
                  <input
                    id='select_classroom'
                    type="checkbox"
                    className="form-radio"
                    checked={
                      this.state.selected_ids &&
                      this.state.selected_ids.indexOf(item.classroom_id ? item.classroom_id : -1) > -1
                    }
                    data-id={item.classroom_id}
                    onChange={this.onSelectClassroom}
                  />
                </div>
              </td>
              <td scope="row" data-label={T.t('gen_status')} className="text-center">
                <div className="tags ml-1 mr-4">
                  <button id='status' className={`small-tag text-uppercase` + (item.status == 1 ? ` co-green` : ` tag-red`)}>
                    {GT.GetActiveStatus(item.status)}
                  </button>
                </div>
              </td>
              <td scope="row" data-label={T.t('gen_code')} className="text-center">
                {item.classroom_code}
              </td>
              <td data-label={T.t('gen_name')} className="text-center">
                {item.name}
              </td>
              <td data-label={T.t('gen_campus_and_building')} className="text-center">
                {item.building_name}
              </td>
              <td data-label={T.t('gen_floor')} className="text-center">
                {item.building_floor}
              </td>
              <td data-label={T.t('gen_door_number')} className="text-center">
                {item.door_order}
              </td>
              <td data-label={T.t('gen_exam_capacity')} className="text-center">
                {item.exam_capacity}
              </td>
              <td data-label={T.t('gen_lecture_capacity')} className="text-center">
                {item.lecture_capacity}
              </td>
              <td data-label={T.t('gen_invigilator_count')} className="text-center">
                {item.invigilator_count}
              </td>
              <td data-label={T.t('gen_type')} className="text-center">
                {item.classroom_type}
              </td>
              <td className="text-center">
                {classroomFeatures && classroomFeatures.length > 0 ?
                  <div className="table-scrollable-td">{classroomFeatures && classroomFeatures.map((i: any, index: any) => (index == classroomFeatures.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                }
              </td>
              <td data-label={T.t('gen_description')} className="text-center">
                {item.description && item.description.length ?
                  <div className="table-scrollable-td">{item.description}</div> : '-'}
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={24}>
            <Announce title={T.t('gen_no_records_found')} />
          </td>
        </tr>
      );

    let TermClassroomTable =
      this.state.classroom_list && this.state.classroom_list.length ? (
        this.state.classroom_list.map((item: Types.IExamPeriod_ClassroomItem) => {
          let classroomFeatures: any = [];
          if (item.classroomInfo && item.classroomInfo.feature_codes) {
            item.classroomInfo.feature_codes.map((featureCode: string) => {
              let feature = classroomFeatureOptions.find((item: Types.ISelectOption) => item.value == featureCode);
              classroomFeatures.push(feature);
            });
          }

          return (
            <tr key={'classroom-' + item.classroom_id} data-title={item.classroomInfo && item.classroomInfo.name}>
              <td data-cell="select">
                <div className="tick-radio position-relative">
                  <input
                    id='select_classroom_id'
                    type="checkbox"
                    className="form-radio"
                    checked={
                      this.state.selected_ids &&
                      this.state.selected_ids.indexOf(item.classroom_id ? item.classroom_id : -1) > -1
                    }
                    data-id={item.classroom_id}
                    onChange={this.onSelectClassroom}
                  />
                </div>
              </td>
              <td scope="row" data-label={T.t('gen_status')} className="text-center">
                <div className="tags ml-1 mr-4">
                  <button
                    id='button_status'
                    className={
                      `tw-p-1 small-tag text-uppercase` +
                      (item.classroomInfo && item.classroomInfo.status == 1 ? ` co-green` : ` tag-red`)
                    }
                  >
                    {GT.GetActiveStatus(item.classroomInfo && item.classroomInfo.status)}
                  </button>
                </div>
              </td>
              <td data-label={T.t('gen_hours')} className="text-center">
                {item.status == 1 ? (
                  <span className="badge co-green" style={{ fontSize: '95%', textAlign: 'center' }}>
                    {T.t('gen_badge_assigned')}
                  </span>
                ) : (
                  <span className="badge badge-warning" style={{ fontSize: '95%', textAlign: 'center' }}>
                    {T.t('gen_badge_unassigned')}
                  </span>
                )}
              </td>
              <td data-label={T.t('gen_code')} className="text-center">
                {item.classroomInfo && item.classroomInfo.classroom_code}
              </td>
              <td data-label={T.t('gen_name')} className="text-center">
                {item.classroomInfo && item.classroomInfo.name}
              </td>
              <td data-label={T.t('gen_campus_and_building')} className="text-center">
                {item.classroomInfo && item.classroomInfo.building_name}
              </td>
              <td data-label={T.t('gen_floor')} className="text-center">
                {item.classroomInfo && item.classroomInfo.building_floor}
              </td>
              <td data-label={T.t('gen_door_number')} className="text-center">
                {item.classroomInfo && item.classroomInfo.door_order}
              </td>
              <td data-label={T.t('gen_exam_capacity')} className="text-center">
                {item.classroomInfo && item.classroomInfo.exam_capacity}
              </td>
              <td data-label={T.t('gen_lecture_capacity')} className="text-center">
                {item.classroomInfo && item.classroomInfo.lecture_capacity}
              </td>
              <td data-label={T.t('gen_invigilator_count')} className="text-center">
                {item.classroomInfo && item.classroomInfo.invigilator_count}
              </td>
              <td data-label={T.t('gen_type')} className="text-center">
                {item.classroomInfo && item.classroomInfo.classroom_type}
              </td>
              <td className="text-center">
                {classroomFeatures && classroomFeatures.length > 0 ?
                  <div className="table-scrollable-td">{classroomFeatures && classroomFeatures.map((i: any, index: any) => (index == classroomFeatures.length - 1) ? <>{i ? i.label : '-'}<br /></> : <>{i ? i.label : '-'},<br /></>)}</div> : '-'
                }
              </td>
              <td data-label={T.t('gen_description')} className="text-center">
                {item.classroomInfo && item.classroomInfo.description && item.classroomInfo.description.length ?
                  <div className="table-scrollable-td">{item.classroomInfo.description}</div> : '-'}
              </td>
            </tr>
          );
        })
      ) : (
        <tr>
          <td colSpan={24}>
            <Announce title={T.t('gen_no_records_found')} />
          </td>
        </tr>
      );
    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '100%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {this.props.calledFromCourseTab ? T.t('gen_transfer_classrooms') : T.t('gen_add_classrooms_to_list')}
            </h6>
            <button
              id='button_close'
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.setCloseModal}
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-12">
                  <div className="quick-actions">
                    <button
                      id='button_filter'
                      className="ghost float-right ml-3 mr-3"
                      style={{ margin: '5px' }}
                      onClick={() => {
                        this.state.filterIsOpen = !this.state.filterIsOpen;
                        this.setState(this.state);
                      }}
                    >
                      <i className="material-icons mr-2">filter_list</i>
                      <span>{T.t('gen_filter')}</span>
                    </button>
                    {this.state.selected_ids && this.state.selected_ids.length ? (
                      <React.Fragment>
                        <button
                          id='button_cancel_selection'
                          className="tertiary float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={() => {
                            this.setState({
                              ...this.state,
                              selected_ids: [],
                              selected_classrooms: [],
                              selected_total_exam_capacity: 0,
                              selected_total_lecture_capacity: 0,
                              selected_total_student_count: 0,
                              selected_id: undefined,
                              filters: {
                                ...this.state.filters,
                                select_all: false
                              }
                            });
                          }}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;({this.state.selected_ids.length})</b>
                          </span>
                        </button>
                        <button
                          id='add_definitions_button'
                          className="primary float-left pr-2 pl-2"
                          style={{ margin: '5px' }}
                          onClick={this.addClassrooms}
                        >
                          <i className="material-icons mr-2">input</i>
                          <span>{this.props.calledFromCourseTab ? T.t('gen_transper_selected') : T.t('gen_add_selecteds')}</span>
                        </button>
                        {this.state.selected_total_exam_capacity ? <>
                          {this.state.selected_total_student_count ? <>
                            <span className="action-info float-left pr-2 pl-2"
                              style={{ margin: '5px' }}>
                              {T.t('gen_total_student_count')} <b>&nbsp;({this.state.selected_total_student_count})</b>
                            </span>
                          </> : null}
                          <span className="action-info float-left pr-2 pl-2"
                            style={{ margin: '5px' }}>
                            {T.t('gen_total_exam_capacity')} <b>&nbsp;({this.state.selected_total_exam_capacity})</b>
                          </span>
                          <span className="action-info float-left pr-2 pl-2"
                            style={{ margin: '5px' }}>
                            {T.t('gen_total_lecture_capacity')} <b>&nbsp;({this.state.selected_total_lecture_capacity})</b>
                          </span>
                        </> : null}
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <button
                          id='button_cancel'
                          className="tertiary float-left pr-2 pl-2"
                          style={{ margin: '5px', cursor: 'not-allowed' }}
                          disabled={true}
                        >
                          <i className="material-icons mr-2">close</i>
                          <span>
                            {T.t('gen_cancel_selection')} <b>&nbsp;(0)</b>
                          </span>
                        </button>
                        <button
                          id='button_add_selecteds'
                          className="tertiary float-left pr-2 pl-2"
                          style={{ margin: '5px', cursor: 'not-allowed' }}
                          disabled={true}
                        >
                          <i className="material-icons mr-2">input</i>
                          <span>{T.t('gen_add_selecteds')}</span>
                        </button>
                      </React.Fragment>
                    )}
                  </div>
                </div>
                <div
                  className={`white-container mt-4 collapse ` + (this.state.filterIsOpen ? `show` : ``)}
                  id="advance-search"
                >
                  <div className="advance-search d-block mt-3">
                    <Formik
                      initialValues={ClassroomSearchInitialValues}
                      enableReinitialize={true}
                      onSubmit={(values, actions) => {
                        this.onFilterClassroom(values, actions);
                      }}
                      onReset={this.onFormReset}
                    >
                      {(props: FormikProps<Types.IFilterClassroom>) => {
                        return (
                          <form onSubmit={props.handleSubmit}>
                            <div className="row">
                              <div className="col-12">
                                <h6>{T.t('gen_filter_parameters')}</h6>
                              </div>
                              <div className="col-md-3 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="classroom_code"
                                    name="classroom_code"
                                    value={props.values.classroom_code}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="classroom_code">{T.t('gen_classroom_code')}</label>
                                  <i className="material-icons">filter_1</i>
                                </div>
                              </div>
                              <div className="col-md-3 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="name"
                                    name="name"
                                    value={props.values.name}
                                    onChange={props.handleChange}
                                    type="text"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="name">{T.t('gen_classroom_name')}</label>
                                  <i className="material-icons">title</i>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_campus')}</label>
                                    <Select
                                      id='select_campus'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.campuses
                                          ? this.props.selectOptions.campuses
                                          : []
                                      }
                                      placeholder={T.t('gen_select_campus')}
                                      value={props.values.campuses ? props.values.campuses : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('campuses', list);
                                        props.setFieldValue('campus_ids', list.map((item) => item.value));
                                        if (list.length < 1) {
                                          props.setFieldValue('buildings', null);
                                          props.setFieldValue('building_ids', null);
                                        }
                                        this.getBuildingsByCampusesAtClassrooms(list.map((item) => item.value));
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_campus')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_building')}</label>
                                    <Select
                                      key={props.values.campuses ? 'buildings' : 0}
                                      isDisabled={!props.values.campuses || props.values.campuses.length < 1}
                                      menuIsOpen={
                                        props.values.campuses
                                          ? (props.values.campuses.length === 0
                                            ? false
                                            : undefined)
                                          : undefined
                                      }
                                      id='select_building'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={this.props.buildings_related_campuses ? this.props.buildings_related_campuses : []}
                                      placeholder={T.t('gen_select_building')}
                                      value={props.values.buildings ? props.values.buildings : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('buildings', list);
                                        props.setFieldValue('building_ids', list.map((item) => item.value));
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_building')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_classroom_features')}</label>
                                    <Select
                                      id='select_feature'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={classroomFeatureOptions}
                                      placeholder={T.t('gen_select_feature')}
                                      value={props.values.classroom_features}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('classroom_features', list);
                                        props.setFieldValue(
                                          'classroom_feature_codes',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_feature')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="add-custom-tag mb-3">
                                  <div className="react-select-container">
                                    <label>{T.t('gen_classroom_type')}</label>
                                    <Select
                                      id='select_type'
                                      className="react-select"
                                      isMulti={true}
                                      filterOption={(option: any, query: any) =>
                                        option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                                      }
                                      closeMenuOnSelect={false}
                                      options={
                                        this.props.selectOptions && this.props.selectOptions.classroom_types
                                          ? this.props.selectOptions.classroom_types
                                            .map(_ => ({ value: _.value, label: ClassroomTypesLabel(_, T) }))
                                            .filter(filterClassroomTypesForEventModuleStatus(this.props.general_settings))
                                          : []
                                      }
                                      placeholder={T.t('gen_select_type')}
                                      value={props.values.classroom_types ? props.values.classroom_types : null}
                                      onChange={(
                                        options: ValueType<Types.ISelectOption> | ValueType<Types.ISelectOption[]>
                                      ) => {
                                        const list: Array<Types.ISelectOption> = options
                                          ? (options as Array<Types.ISelectOption>)
                                          : [];
                                        props.setFieldValue('classroom_types', list);
                                        props.setFieldValue(
                                          'classroom_type_ids',
                                          list.map((item) => item.value)
                                        );
                                      }}
                                      noOptionsMessage={(): string => T.t('gen_select_no_type')}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="building_floor"
                                    name="building_floor"
                                    value={props.values && props.values.building_floor}
                                    onChange={props.handleChange}
                                    type="number"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="building_floor">{T.t('gen_floor')}</label>
                                </div>
                              </div>
                              <div className="col-md-3 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="door_order"
                                    name="door_order"
                                    value={props.values.door_order}
                                    onChange={props.handleChange}
                                    type="number"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="door_order">{T.t('gen_door_number')}</label>
                                </div>
                              </div>
                              <div className="col-md-3 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="exam_capacity"
                                    name="exam_capacity"
                                    value={props.values.exam_capacity}
                                    onChange={props.handleChange}
                                    type="number"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="exam_capacity">{T.t('gen_exam_capacity')}</label>
                                </div>
                              </div>
                              <div className="col-md-3 mt-3">
                                <div className="form-input form-group date-picker">
                                  <input
                                    id="lecture_capacity"
                                    name="lecture_capacity"
                                    value={props.values.lecture_capacity}
                                    onChange={props.handleChange}
                                    type="number"
                                    required
                                  />
                                  <span className="highlight" />
                                  <span className="bar" />
                                  <label htmlFor="lecture_capacity">{T.t('gen_lecture_capacity')}</label>
                                </div>
                              </div>
                            </div>
                            <hr />
                            <div className="row mt-3">
                              <div className="col-6">
                                <button
                                  id='button_arrow_upward'
                                  type="button"
                                  onClick={() => {
                                    this.state.filterIsOpen = false;
                                    this.setState(this.state);
                                  }}
                                  className="mw-none mt-md-0 mt-2 mb-md-0 mb-2 ghost mr-2"
                                >
                                  <i className="material-icons">arrow_upward</i>
                                </button>
                                <button
                                  id='button_delete_sweep'
                                  type="reset"
                                  onClick={props.handleReset}
                                  className="mw-none danger mt-md-0 mt-2 mb-md-0 mb-2"
                                >
                                  <i className="material-icons">delete_sweep</i>
                                </button>
                              </div>
                              <div className="col-6 text-right">
                                <button
                                  id='button_search'
                                  type="button"
                                  className="primary mt-md-0 mt-2 mb-md-0 mb-2"
                                  onClick={() => props.handleSubmit()}
                                  disabled={props.isSubmitting}
                                >
                                  <i className="material-icons mr-2">search</i> {T.t('gen_search')}
                                </button>
                              </div>
                            </div>
                          </form>
                        );
                      }}
                    </Formik>
                  </div>
                </div>
                <div className="col-12">
                  <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                    <thead>
                      <tr>
                        <th data-cell="select">
                          <div className="tick-radio position-relative">
                            <input
                              id='select_all_definitions'
                              type="checkbox"
                              className="form-radio"
                              checked={
                                this.state.selected_ids &&
                                this.props.filters &&
                                this.state.selected_ids.length == this.props.filters.total
                              }
                              onChange={this.onSelectAll}
                            />
                          </div>
                        </th>
                        <SortedColumn
                          datacell="status"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_status')}
                          sortkey="status"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        {this.props.calledFromCourseTab === true ?
                          <SortedColumn
                            datacell="hours"
                            className="d-none d-lg-table-cell d-xl-table-cell text-center"
                            title={T.t('gen_hours')}
                            sortkey="hours"
                            sortedcolumn={this.state.filters.order_by}
                            sort={this.sort}
                          /> : null}
                        <SortedColumn
                          datacell="classroom_code"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_code')}
                          sortkey="classroom_code"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="name"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_name')}
                          sortkey="name"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="building_name"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_campus_and_building')}
                          sortkey="building_name"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="building_floor"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_floor')}
                          sortkey="building_floor"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="door_order"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_door_number')}
                          sortkey="door_order"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="exam_capacity"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_exam_capacity')}
                          sortkey="exam_capacity"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="lecture_capacity"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_lecture_capacity')}
                          sortkey="lecture_capacity"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="invigilator_count"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_invigilator_count')}
                          sortkey="invigilator_count"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <SortedColumn
                          datacell="classroom_type"
                          className="d-none d-lg-table-cell d-xl-table-cell text-center"
                          title={T.t('gen_type')}
                          sortkey="classroom_type"
                          sortedcolumn={this.state.filters.order_by}
                          sort={this.sort}
                        />
                        <th scope="col" className="text-center">
                          {T.t('gen_features')}
                        </th>
                        <th scope="col" className="text-center">
                          {T.t('gen_description')}
                        </th>
                      </tr>
                    </thead>
                    <tbody>{this.props.calledFromCourseTab === true ? TermClassroomTable : ClassroomTable}</tbody>
                  </table>
                  <div className="row-options justify-content-end">
                    <div
                      className="page-sorting d-flex align-items-center justify-content-center"
                      style={{ marginTop: '5px' }}
                    >
                      {(this.props.results && this.props.results.length > 0) ||
                        (this.props.term_classrooms && this.props.term_classrooms.length > 0) ? (
                        <Paginate filters={this.props.filters} onPageChange={this.onPageChange} onSizeChange={this.onSizeChange} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.IClassroomPageProps
): Types.IClassroomPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IClassroomPageProps = Object.assign({}, ownProps, {
    results: store.state.classroom_page && store.state.classroom_page.results,
    term_classrooms:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.classroom_hours &&
      store.state.examPeriodModal.classroom_hours.classroom_list,
    total_exam_capacity:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.classroom_hours &&
      store.state.examPeriodModal.classroom_hours.total_exam_capacity,
    total_lecture_capacity:
      store.state.examPeriodModal &&
      store.state.examPeriodModal.classroom_hours &&
      store.state.examPeriodModal.classroom_hours.total_lecture_capacity,
    filters: store.state.classroom_page && store.state.classroom_page.filters,
    all_ids: store.state.classroom_page && store.state.classroom_page.all_ids,
    defination_total_lecture_capacity: store.state.classroom_page && store.state.classroom_page.total_lecture_capacity,
    defination_total_exam_capacity: store.state.classroom_page && store.state.classroom_page.total_exam_capacity,
    selectOptions: store.state.select_options && store.state.select_options.classroomPage,
    buildings_related_campuses: store.state.select_options && store.state.select_options.buildings_related_campuses,
    term_id: store.state.term_id,
    general_settings: store.state.general_settings,
  });

  if (ownProps.calledFromCourseTab && store.state.examPeriodModal && store.state.examPeriodModal.classroom_hours) {
    newProps.filters = store.state.examPeriodModal.classroom_hours.classroom_hours_filters;
    newProps.all_ids = store.state.examPeriodModal.classroom_hours.all_ids;
  }

  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  return false;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(ClassroomAddModal);

export default container;
