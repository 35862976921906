import XLSX from 'xlsx';
import { IAppSettings } from '../../../store/types';
import MockAPIClient from '../../../services/mock-api-client';

declare const appSettings: IAppSettings;

export interface ExcelFileInformation {
    fileType: string,
    term_id: string
    file: File,
}

// endpoint for uploading excel files
const excelUploadEndpoint = `${appSettings.API_URL}/integration/excel-upload`;

// its not good to do this api call here, but for now we will do it here
export async function sendFileToApi(e: any, { file, fileType, term_id }: ExcelFileInformation) {
    try {
        const _papi: MockAPIClient = new MockAPIClient()

        let bstr = e.target.result;
        let xlsxData = XLSX.read(bstr, { type: 'binary' });

        const fileName = file.name;
        // determine bookType based on file extension
        const fileExtension = fileName.split('.').pop() || "";
        const supportedExtensions = ['xlsx', 'xls', 'csv'];
        if (!supportedExtensions.includes(fileExtension)) {
            throw new Error('Unsupported file type');
        }

        // to array buffer
        const wbout = XLSX.write(xlsxData, { bookType: fileExtension as any, type: "array" });

        // array buffer to blob
        const blob = new Blob([wbout], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });

        // file instance from blob
        const blobFile = new File([blob], fileName, { type: blob.type });

        const formData = new FormData();
        formData.append("file", blobFile);

        const xhr = new XMLHttpRequest();
        xhr.open("POST", excelUploadEndpoint, true);

        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Authorization", `Bearer ${_papi._accessToken}`);
        xhr.setRequestHeader("termId", term_id);
        xhr.setRequestHeader("fileType", fileType);
        // xhr.upload.onprogress = (event) => {
        //     if (event.lengthComputable) {
        //         const percentCompleted = (event.loaded / event.total) * 100;
        //         console.log(`upload excel progress: ${percentCompleted.toFixed(2)}%`);
        //     }
        // };

        xhr.onload = function () {
            if (xhr.status >= 200 && xhr.status < 300) {
                console.log("File uploaded successfully:", xhr.responseText);
            } else {
                console.error("Failed to upload file:", xhr.statusText);
            }
        };

        xhr.onerror = function () {
            console.error("XHR error:", xhr.statusText);
        };

        xhr.send(formData);
    } catch (error) {
        console.error('Failed to send file to API via XHR:', error);
        throw error;
    }
}
export function formatExcel<T>(e: any, headerMapping: Record<string, string[]>, excelFileInformation?: ExcelFileInformation): Array<T> {

    const normalizeString = (str: string): string => {
        const pattern = /[.,:;\t\r\n ]/g;
        const turkishToEnglishMap: Record<string, string> = {
            'ç': 'c',
            'ğ': 'g',
            'ı': 'i',
            'İ': 'i',
            'ö': 'o',
            'ş': 's',
            'ü': 'u',
            'Ç': 'c',
            'Ğ': 'g',
            'Ö': 'o',
            'Ş': 's',
            'Ü': 'u'
        };
        const replaceTurkishChars = (input: string): string => {
            return input
                .split('')
                .map(char => turkishToEnglishMap[char] || char)
                .join('');
        };
        return replaceTurkishChars(str.replace(pattern, '')).toLowerCase();
    }
    if (excelFileInformation) //send File to API
        sendFileToApi(e, excelFileInformation);

    // Normalize header mapping
    let normalizedHeaderMapping: Record<string, string[]> = {};
    for (let key in headerMapping) {
        const normalizedKeys = headerMapping[key].map(normalizeString);
        normalizedKeys.forEach((normalizedKey) => {
            if (!normalizedHeaderMapping[normalizedKey]) {
                normalizedHeaderMapping[normalizedKey] = [];
            }
            normalizedHeaderMapping[normalizedKey].push(key);
        });
    }

    let bstr = e.target.result;
    let wb = XLSX.read(bstr, { type: 'binary' });
    let wsname = wb.SheetNames[0];
    let ws = wb.Sheets[wsname];

    let jsonData = XLSX.utils.sheet_to_json(ws, { header: 1 });

    // remove empty rows
    jsonData = jsonData.filter((row: any) => {
        return row.some((cell: any) => cell !== undefined && cell !== null && cell.toString().trim() !== '');
    });

    let headersFromExcel = jsonData[0] as string[];

    // normalize headers
    let normalizedHeadersFromExcel = headersFromExcel.map(normalizeString)

    let headerIndices: { [key: string]: number } = {};
    for (let i = 0; i < normalizedHeadersFromExcel.length; i++) {
        let header = normalizedHeadersFromExcel[i];
        if (normalizedHeaderMapping[header]) {
            normalizedHeaderMapping[header].forEach((key) => {
                headerIndices[key] = i;
            });
        }
    }

    let data = jsonData.slice(1).map((row: any) => {
        let rowData: { [key: string]: any } = {};
        for (let key in headerIndices) {
            const cellValue = row[headerIndices[key]];
            rowData[key] = cellValue != null ? cellValue.toString() : ""
        }
        return rowData;
    }) as Array<T>;

    return data;
}
