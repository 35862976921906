import * as React from 'react';
import DOMPurify from 'dompurify';
import cn from "../ui/Tailwind";

interface WarningProps {
    show?: boolean
    children?: string | React.ReactChild
    classNames?: string,
    warnings?: Record<string, string>
    warningKey?: string
}

const Warning: React.FC<WarningProps> = ({ show, children, classNames, warnings, warningKey }) => {

    if (warnings && warningKey && warnings[warningKey]) {
        const sanitizedHtmlString = DOMPurify.sanitize(warnings[warningKey]);
        children = <div dangerouslySetInnerHTML={{ __html: sanitizedHtmlString }} />
    }

    return <div
        className={cn(
            "yellow-warning",
            "tw-duration-150",
            { 'tw-opacity-100': show },
            { 'tw-opacity-0': !show },
            classNames)}>
        {children}
    </div>
}

export default Warning