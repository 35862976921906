import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Log } from 'ng2-logger';
import Select from 'react-select';
import * as Actions from '../../../store/actions/general';
import * as Constants from '../../../store/constants/all';
import * as Types from '../../../store/types';
import { SolutionFilterInitialValues } from '../../../store/constants/solution-const';
import MainLayout from '../../layouts/main-layout';
import APlanHeader from '../../../components/templates/aplan-header';
import Spinner from '../../../components/templates/spinner';
import SolutionGrid from './solution-calendar';
import { constants } from '../../../store/constants/solution-const';
import Translator from '../../../services/translate-factory';
const T = Translator.create();
const Logger = Log.create('SolutionPage');

function getInitialState(): Types.ISolutionPageState {
  const initialValues: Types.ISolutionPageState = {
    activeTab: constants.SOLUTION_GET_SINGLE_TABLES,
    filters: Object.assign({}, SolutionFilterInitialValues),
    solution: { name: '', solutionId: -1, termId: -1, createDate: '', explanation: '' },
    solutionList: [],
    selectOptions: [],
    selectedOption: { label: '', value: '' },
    tabManager: [
      { key: constants.SOLUTION_GET_SINGLE_TABLES, value: true },
      { key: constants.SOLUTION_GET_BY_PROGRAMS, value: false },
      { key: constants.SOLUTION_GET_BY_INSTRUCTORS, value: false },
      { key: constants.SOLUTION_GET_BY_CLASSROOMS, value: false },
      { key: constants.SOLUTION_GET_BY_STUDENTS, value: false },
      { key: constants.SOLUTION_GET_BY_INVIGILATORS, value: false },
      { key: constants.SOLUTION_GET_INVIGILATION_COUNTS, value: false }
    ]
  };
  return Object.assign({}, initialValues);
}

class SolutionPage extends Component<Types.ISolutionPageProps, Types.ISolutionPageState> {
  state: Types.ISolutionPageState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };


  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    moment.locale('tr');
    /*let regexObject = window.location.pathname.match(/([^\/]+$)/); // gets the id of the calendar
    let id = regexObject ? parseInt(regexObject[0], 10) : undefined;*/
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);

    this.getGeneralSettings();

    this.setState({
      ...this.state,
      term_id: id,
      filters: {
        ...this.state.filters,
        term_id: id
      }
    });

    let model: Types.IFilterSolutionModel = {
      term_id: id,
      active_tab: '0',
      page: 1,
      total: -1,
      size: 10,
      solution_id: 0
    };

    this.props.dispatch(
      Actions.ApiRequest(Constants.solution.SOLUTION_GET_BY_TERM, model, 'solution-schedule-spinner')
    );
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.props.dispatch(
      Actions.ApiRequest(Constants.solution.COURSE_SOLUTION_GET_SINGLE_TABLES, { reset: true }, 'solution-schedule-spinner')
    );
  }

  getGeneralSettings() {
    this.props.dispatch(Actions.ApiRequest(Constants.setting.GET_GENERAL_SETTINGS, 'general-settings-spin'));
  }

  selectActiveTab = (tabName: string) => {
    let tabs =
      this.state.tabManager &&
      this.state.tabManager.map((tab) => {
        return tab.key == tabName ? { key: tab.key, value: true } : { key: tab.key, value: false };
      });

    let activeTab = tabs && tabs.find((item) => item.value == true);
    let tabConstant = activeTab && activeTab.key;

    this.setState({
      tab_reloaded: true,
      tabManager: tabs,
      activeTab: tabConstant
    });
  };

  isActiveTab = (tabName: string) => {
    let activeTab = this.state.tabManager && this.state.tabManager.find((item) => item.key == tabName);
    if (activeTab) {
      return activeTab.value;
    } else return false;
  };

  onSelectSolution = (option: Types.ISelectOption) => {
    const selectedSolution = this.state.solutionList.find(
      (solution: Types.ISolutionItem) => solution.solutionId === option.value
    );
    const solutionIndex = this.state.solutionList.findIndex(
      (solution: Types.ISolutionItem) => solution.solutionId === option.value
    );

    const date = moment(selectedSolution!.createDate).format('D MMMM dddd YYYY');
    const hour = moment(selectedSolution!.createDate).format('HH:mm');

    this.setState(
      {
        ...this.state,
        filters: {
          ...this.state.filters,
          solution_id: option.value
        },
        solution: {
          ...selectedSolution!,
          createDate: `(${T.t('gen_create_date')}: ${date}, Saat ${hour})`,
          name: `Çözüm ${selectedSolution && selectedSolution.solutionId}`
        },
        selectedOption: option
      }
    );
  };

  saveSolutionToPublish = (publisher: boolean) => {
    this.props.dispatch(
      Actions.ShowModal({
        title: publisher ? T.t('gen_publish_solution') : T.t('gen_unpublish_solution'),
        body: publisher ? T.t('gen_publish_solution_warning') : T.t('gen_publish_solution_remove_warning'),
        name: 'save_solution',
        icon: 'info',
        iconColor: publisher ? 'blue' : 'red',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          let SolutionModel: Types.IFilterSolutionModel = {
            term_id: this.state.term_id,
            active_tab: '0',
            page: 1,
            total: -1,
            size: 10,
            solution_id: this.state.solution.solutionId
          };

          const callback = () => {
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.solution.SOLUTION_GET_BY_TERM,
                SolutionModel,
                'solution-schedule-spinner'
              )
            );

            this.props.dispatch(
              Actions.ApiRequest(Constants.solution.SOLUTION_ACTIVITY_LIST_SEARCH, this.state.filters, 'solution-schedule-spinner')
            );
          };

          const model = {
            term_id: this.state.term_id,
            solutionId: this.state.solution.solutionId,
            publisher: publisher
          };

          this.props.dispatch(
            Actions.ApiRequest(Constants.solution.SOLUTION_PUBLISH_SOLUTION, model, undefined, callback)
          );
        }
      })
    );
  };

  copySolution = () => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_copy_solution'),
        body: (
          <div className="form-input form-group">
            <p className="text-center">{T.t('gen_solution_copy_info').replace('{0}', this.state.solution.solutionId).replace('{1}', this.state.term_id)}</p>
          </div>
        ),
        name: 'copy_solution',
        icon: 'info',
        iconColor: 'blue',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          let SolutionModel: Types.IFilterSolutionModel = {
            term_id: this.state.term_id,
            active_tab: '0',
            page: 1,
            total: -1,
            size: 10,
            solution_id: this.state.solution.solutionId
          };

          const resultCallback = (result: any, status: number) => {
            if (status === 200 && result != null && result.solution_copied_id != 0) {
              this.props.dispatch(Actions.Notification(T.t('notification_solution_copied').replace('{0}', result.solution_id).replace('{1}', result.solution_copied_id), 'gen_success'));
            }

            this.props.dispatch(
              Actions.ApiRequest(
                Constants.solution.SOLUTION_GET_BY_TERM,
                SolutionModel,
                'solution-schedule-spinner'
              )
            );

            this.setState(this.state);
          };

          const model = {
            term_id: this.state.term_id,
            solution_id: this.state.solution.solutionId,
            term_type: this.props.term_type,
          };

          this.props.dispatch(
            Actions.ApiRequest(Constants.solution.SOLUTION_COPY_SOLUTION, model, undefined, resultCallback)
          );
        }
      })
    );
  };

  deleteSolution = () => {
    if (this.state.solution && this.state.solution.isPublished) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete_solution'),
          body: T.t("gen_solution_process_info"),
          name: 'delete_course',
          icon: 'error_outline',
          iconColor: 'red',
          cancel: T.t('gen_close')
        })
      );
    } else {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_delete_solution'),
          body: (
            <p className="text-center">{T.t('gen_solution_delete_info').replaceAll('{0}', this.state.solution.solutionId)}</p>
          ),
          name: 'delete_solution',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            let SolutionModel: Types.IFilterSolutionModel = {
              term_id: this.state.term_id,
              active_tab: '0',
              page: 1,
              total: -1,
              size: 10,
              solution_id: 0
            };

            const resultCallback = (result: any, status: number) => {
              if (status === 200 && result != null && result != 0) {
                this.props.dispatch(Actions.Notification(T.t('notification_solution_deleted').replace('{0}', result), 'gen_success'));
              }

              this.props.dispatch(
                Actions.ApiRequest(
                  Constants.solution.SOLUTION_GET_BY_TERM,
                  SolutionModel,
                  'solution-schedule-spinner'
                )
              );

              this.setState(this.state);
            };

            const model = {
              term_id: this.state.term_id,
              solution_id: this.state.solution.solutionId,
            };

            this.props.dispatch(
              Actions.ApiRequest(Constants.solution.SOLUTION_DELETE_SOLUTION, model, undefined, resultCallback)
            );
          }
        })
      );
    }
  };

  static getDerivedStateFromProps(props: Types.ISolutionPageProps, state: Types.ISolutionPageState) {
    let hasNewState: boolean = false;
    const publishedSolution = props.model && props.model.solutionList && props.model.solutionList.find((item) => item.isPublished == true);
    const currentSolution = state.solutionList && state.solutionList.find((item) => item.isPublished == true);
    if (
      (props && props.model && props.model.solutionList) && (
        (props.model.solutionList.length && !state.solutionList.length) ||
        ((publishedSolution && publishedSolution.solutionId) !== (currentSolution && currentSolution.solutionId)) ||
        (props.model.solutionList.length != state.solutionList.length)
      )
    ) {
      hasNewState = true;

      // Sort solutions by createDate from newest to oldest.
      // const orderedSolutionList = props.model.solutionList.sort((a: any, b: any) =>
      //   a.createDate < b.createDate ? 1 : a.createDate > b.createDate ? -1 : 0
      // );
      const orderedSolutionList = props.model.solutionList;

      const selectOptions = orderedSolutionList.map((solution: Types.ISolutionItem, index: number) => {
        const date = moment(solution.createDate).format('D/MM/YYYY - HH:mm');
        return {
          label: `${T.t('gen_solution')} ${solution.solutionId} (${date}` + (solution.copiedDate != undefined ? ` | ${moment(solution.copiedDate).format('D/MM/YYYY - HH:mm')})` : `)`),
          value: solution.solutionId
        };
      });

      state.solutionList = orderedSolutionList;
      state.selectOptions = selectOptions;

      const publishedOption = orderedSolutionList.find(
        (solution: Types.ISolutionItem) => solution.isPublished === true
      );

      if (publishedOption) {
        const index = orderedSolutionList.findIndex((solution: Types.ISolutionItem) => solution.isPublished === true);

        const date = moment(orderedSolutionList[index].createDate).format('D MMMM dddd YYYY');
        const hour = moment(orderedSolutionList[index].createDate).format('HH:mm');

        state.selectedOption = selectOptions[index];
        state.solution = {
          ...orderedSolutionList[index],
          createDate: `(${T.t('gen_create_date')}: ${date}, Saat ${hour})`,
          name: `${T.t('gen_solution')} ${state.selectedOption.value}`
        };
      } else {
        const date = moment(orderedSolutionList[0].createDate).format('D MMMM dddd YYYY');
        const hour = moment(orderedSolutionList[0].createDate).format('HH:mm');

        state.selectedOption = selectOptions[0];
        state.solution = {
          ...orderedSolutionList[0],
          createDate: `(${T.t('gen_create_date')}: ${date}, Saat ${hour})`,
          name: `${T.t('gen_solution')} ${state.selectedOption.value}`
        };
      }
    }

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    const solution = this.state.solution;
    if (this.props.user && (this.props.user.role === 'e' || this.props.user.role === 'd' || this.props.user.role === 'p' || this.props.user.role === 'r')) {
      if (this.props.general_settings && this.props.general_settings.restrict_solution_to_instructors) {
        this.state.activeTab = Constants.solution.SOLUTION_GET_BY_INSTRUCTORS;
      } else {
        this.state.activeTab = constants.SOLUTION_GET_SINGLE_TABLES;
      }
    }
    return (
      <MainLayout header={<APlanHeader />}>
        <div className="container-fluid">
          <div className="row align-items-center">
            <div className="col-md-6 col-sm-6 col-12">
              <h4 className="mb-1">
                {solution.name}
                <span className="ml-2" style={{ fontSize: '14px', fontWeight: 'normal' }}>
                  {solution.createDate}
                </span>
                {solution.isPublished && (
                  <span className="ml-1 badge badge-danger" style={{ fontSize: '14px', fontWeight: 'normal' }}>
                    {T.t('gen_published_uppercase')}
                  </span>
                )}
              </h4>
              <span>{solution.explanation}</span>
            </div>
            <div className="col-md-6 col-sm-6 col-12">
              <div className="row align-items-center">
                <div className="col-md-6 mt-4">
                  <div className="add-custom-tag">
                    <div className="react-select-container">
                      <Select
                        id='select_solution'
                        className="react-select"
                        isMulti={false}
                        closeMenuOnSelect={true}
                        options={this.state.selectOptions}
                        placeholder={T.t('gen_select_solution')}
                        onChange={(option: any) => this.onSelectSolution(option)}
                        value={this.state.selectedOption}
                        noOptionsMessage={(): string => T.t('gen_select_no_solution')}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6 mt-2">
                  <div className="row align-items-center with-gap">

                    {solution.isPublished && (this.props.user && this.props.user.role === 's') ? (
                      <button id='button_unpublish' className="tertiary" onClick={() => this.saveSolutionToPublish(false)}>
                        <i className="material-icons">remove</i>{T.t('gen_unpublish')}
                      </button>
                    ) : (this.state.solutionList.length && this.props.user && (this.props.user.role === 's') ? (
                      <button id='button_publish' className="primary" onClick={() => this.saveSolutionToPublish(true)}>
                        <i className="material-icons">done</i>{T.t('gen_publish')}
                      </button>
                    ) : null)}

                    {(this.props.user && this.props.user.role === 's' && this.state.selectOptions && this.state.selectOptions.length != 0) ? (
                      <>
                        <button id='button_solution_copy'
                          className="secondary float-right"
                          onClick={() => this.copySolution()}>
                          <i className="material-icons">content_copy</i>{T.t('gen_copy')}
                        </button>
                        <button id='button_solution_delete'
                          className="danger float-right"
                          onClick={() => this.deleteSolution()}>
                          <i className="material-icons">delete</i>{T.t('gen_delete')}
                        </button>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Spinner name="solution-schedule-spinner" />
        <div className="main editor-screen-main" style={{ display: 'block' }}>
          <div
            className="white-container mt-4 editor-screen collapse editor-screen"
            style={{ display: 'block', minHeight: '100vh' }}
          >
            <div className="row">
              <Spinner name="exam-period-modal-tab-spin" />
              <div className="col-12">
                {this.props.user && this.props.user.role !== 'e' && this.props.user.role !== 'd' && this.props.user.role !== 'p' && this.props.user.role !== 'r' && (
                  <nav className="inner-page-nav">
                    <div className="nav nav-tabs nav-fill mb-4" id="nav-tab" role="tablist">
                      <a
                        className="nav-item nav-link active"
                        onClick={() => this.selectActiveTab(Constants.solution.SOLUTION_GET_SINGLE_TABLES)}
                        id="dates-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_all_filters')}
                      </a>
                      <a
                        className="nav-item nav-link"
                        onClick={() => this.selectActiveTab(Constants.solution.SOLUTION_GET_BY_PROGRAMS)}
                        id="classrooms-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_program_filters')}
                      </a>
                      <a
                        className="nav-item nav-link"
                        onClick={() => this.selectActiveTab(Constants.solution.SOLUTION_GET_BY_INSTRUCTORS)}
                        id="course-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_instructor_filters')}
                      </a>
                      <a
                        className="nav-item nav-link"
                        onClick={() => this.selectActiveTab(Constants.solution.SOLUTION_GET_BY_CLASSROOMS)}
                        id="personnals-tab"
                        data-toggle="tab"
                        role="tab"
                        aria-controls="nav-home"
                        aria-selected="true"
                      >
                        {T.t('gen_classroom_filters')}
                      </a>
                    </div>
                  </nav>
                )}
                <div className="tab-content" id="nav-tabContent">
                  <div
                    className={
                      this.isActiveTab(Constants.solution.SOLUTION_GET_INVIGILATION_COUNTS)
                        ? 'tab-pane fade'
                        : 'tab-pane fade show active'
                    }
                    id="singleTables"
                    role="tabpanel"
                    aria-labelledby="singleTables-tab"
                  >
                    <SolutionGrid solutionPublishmentInfo={solution} activeTab={this.state.activeTab} solutionId={this.state.selectedOption.value} />
                  </div>
                  <div
                    className={
                      this.isActiveTab(Constants.solution.SOLUTION_GET_INVIGILATION_COUNTS)
                        ? 'tab-pane fade show active'
                        : 'tab-pane fade'
                    }
                    id="singleTables"
                    role="tabpanel"
                    aria-labelledby="singleTables-tab"
                  >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </MainLayout>
    );
  }
}

const mapStateToProps = (
  store: Types.IPersistedState,
  ownProps: Types.ISolutionPageProps
): Types.ISolutionPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.ISolutionPageProps = Object.assign({}, ownProps, {
    model: store.state.solution_page && store.state.solution_page.solution,
    filters: store.state.solution_page && store.state.solution_page.filters,
    user: store.state.user,
    settings: store.state.settings,
    term_type: store.state.term_type,
    general_settings: store.state.general_settings
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.solution_page) {
    return (
      !!equal(
        prev.state.solution_page && prev.state.solution_page.solution,
        next.state.solution_page && next.state.solution_page.solution
      ) &&
      !!equal(
        prev.state.solution_page && prev.state.solution_page.filters,
        next.state.solution_page && next.state.solution_page.filters
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(SolutionPage);

export default container;
