import { Formik, FormikActions, FormikProps } from 'formik';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'reactstrap';
import Paginate from '../../components/table/paginate';
import SortedColumn from '../../components/table/sorted-column';
import Announce from '../../components/templates/announce';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import ChangeSolutionModal from './course-timeslot-edit-modal';
import ChangeInvigilatorModal from './course-invigilator-edit-modal';
import ChangeClassroomModal from './course-classroom-edit-modal';
import ChangeSingleExamModal from './single-exam-edit-modal';
import ChangeSingleCourseModal from './single-course-edit-modal';
import { DistributorModalSearchInitialValues, DistributorJobStatus } from '../../store/constants/distributor-const';
import * as Types from '../../store/types';
import Translator from '../../services/translate-factory';
import { SectionTypes } from '../../store/constants/enums';

const T = Translator.create();
const L = Log.create('DistributorStatusHistoryModal');

class DistributorStatusHistoryModal extends Component<any, any> {
  state: Types.IDistributorModalState = {
    filters: DistributorModalSearchInitialValues,
    statusTableIsOpen: false,
    filterIsOpen: false,
    statusHistoryList: [],
    changeSolutionModalIsOpen: false,
    reassignInvigilatorsManualModalIsOpen: false,
    changeClassroomModalIsOpen: false,
    solutionResultIsOpen: false,
    is_error_ignorable: false,
    page: 1,
    total: -1,
    size: 10,
    best_success_rate: 0,
    mean_success_rate: 0,
    best_success_rate_classroom: 0
  };

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        L.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    var allNumbersFromPath = (window.location.pathname).replace(/[^0-9]/g, ' ').trim().split(/\s+/);
    let id = parseInt(allNumbersFromPath[allNumbersFromPath.length - 1], 10);
    this.state.filters.term_id = id;
    this.getStatusHistoryDistributions();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getStatusHistoryDistributions() {
    this.state.filters.job_id = this.props.jobId;
    this.props.dispatch(
      Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_GET_STATUS_HISTORY, this.state.filters, 'ep-student-list')
    );
  }

  showInfoModalStatusHistory = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_download_all_info'),
          body: T.t('gen_download_all_question'),
          name: 'download_status_history',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const model = {
              termId: this.props.term_id,
              sectionType: SectionTypes.Distribution,
              sectionId: !!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id,
              parameters: [],
              autoDownload: true
            };
            this.props.dispatch(
              Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_DOWNLOAD_HISTORY, model, '',
                (result: string, status: number) => {
                  if (status === 200) {
                    this.openLinkInNewTab(result);
                  }
                })
            )
          }
        })
      );
    }
  };

  openLinkInNewTab(url: string) {
    var win = window.open(url, '_blank');
    win && win.focus();
  }

  showInfoConfirmOptionalErrors = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_distribution_confirm_optional_errors_title'),
          body: T.t('gen_distribution_confirm_optional_errors_body'),
          name: 'distribution_confirm_optional_errors',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            let job_id = !!this.props.result.distribution_number ? this.props.result.distribution_number : this.props.distribution_in_progress_status.job_id;
            this.props.dispatch(Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_CONFIRM_DISTRIBUTION, job_id, '',
              (result: string, status: number) => {
                if (status === 200) {
                  window.location.reload();
                }
              })
            )
          }
        })
      );
    }
  };

  showInfoReassignInvigilatorsAuto = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_distribution_reassign_invigilators_auto_title'),
          body: T.t('gen_distribution_reassign_invigilators_auto_body'),
          name: 'distribution_reassign_invigilators_auto',
          icon: 'info',
          iconColor: 'blue',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            let job_id = !!this.props.result.distribution_number ? this.props.result.distribution_number : this.props.distribution_in_progress_status.job_id;
            this.props.dispatch(Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_REASSIGN_INVIGILATORS_AUTO, job_id, '',
              (result: string, status: number) => {
                if (status === 200) {
                  window.location.reload();
                }
              })
            );
          }
        })
      );
    }
  };

  handleChange(e: React.ChangeEvent<HTMLInputElement>, onSubmitSolution: boolean) {
    if (onSubmitSolution) {
      this.state.explanation = e.target.value;
      this.setState(this.state);
    }
  }

  onSubmitSolution = (e: React.MouseEvent<HTMLSpanElement>) => {
    if (e && e.target) {
      this.props.dispatch(
        Actions.ShowModal({
          title: T.t('gen_submit_solution'),
          body:
            <React.Fragment>
              <Formik
                initialValues={DistributorModalSearchInitialValues}
                enableReinitialize={true}
                onSubmit={(values, actions) => {
                }}
                onReset={this.onFormReset}
              >
                {(props: FormikProps<Types.IDistributorPageProps>) => {
                  const { values, handleChange, handleBlur, handleSubmit } = props;
                  if (!(this.state.explanation && this.state.explanation.length)) this.setState({ explanation: this.props.explanation });
                  return (
                    <div className="row">
                      {T.t('gen_submit_solution_question')}
                      <div className="col-md-12 pt-3">
                        <div className="form-input form-group date-picker">
                          <input
                            id="explanation"
                            name="explanation"
                            value={this.props.explanation}
                            onChange={handleChange}
                            onBlur={(e) => this.handleChange(e, true)}
                            type="text"
                            required
                          />
                          <span className="highlight" />
                          <span className="bar" />
                          <label htmlFor="description">{'* ' + T.t('gen_description')}</label>
                        </div>
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </React.Fragment>,
          name: 'submit_solution',
          icon: 'info',
          iconColor: 'green',
          confirm: T.t('gen_yes'),
          cancel: T.t('gen_cancel'),
          onConfirm: () => {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                window.location.reload();
              }
            };

            const model = {
              job_id: !!this.props.jobId ? this.props.jobId : this.props.distribution_in_progress_status.job_id,
              explanation: this.state.explanation,
            };

            if (this.state.explanation && this.state.explanation.length > 0) {
              this.props.dispatch(Actions.ApiRequest(Constants.disributor.DISTRIBUTOR_SUBMIT_SOLUTION, model, '', resultCallback));
            } else {
              this.props.dispatch(
                Actions.ShowModal({
                  title: T.t("gen_error"),
                  body: T.t("gen_submit_description_left_blank"),
                  name: 'submit_failed',
                  icon: 'error_outline',
                  iconColor: 'red'
                }));
            }
          }
        })
      );
    }
  };

  onFilterDistributionList(model: Types.IFilterDistributorModal, FormActions: FormikActions<Types.IFilterDistributorModal>) {
    this.state.filters = model;
    this.state.filters.page = 1;
    this.state.filterIsOpen = true;
    this.setState(this.state);
    this.getStatusHistoryDistributions();
    FormActions.setSubmitting(false);
  }

  onSolutionChange = () => {
    this.switchChangeSolutionModalStatus();
  }

  switchChangeSolutionModalStatus = () => {
    this.setState({
      ...this.state,
      changeSolutionModalIsOpen: !this.state.changeSolutionModalIsOpen
    });
  };

  onReassignInvigilatorsManual = () => {
    this.switchReassignInvigilatorsManualModalStatus();
  }

  switchReassignInvigilatorsManualModalStatus = () => {
    this.setState({
      ...this.state,
      reassignInvigilatorsManualModalIsOpen: !this.state.reassignInvigilatorsManualModalIsOpen
    });
  };

  onClassroomChange = () => {
    this.switchChangeClassroomModalStatus();
  }

  switchChangeClassroomModalStatus = () => {
    this.setState({
      ...this.state,
      changeClassroomModalIsOpen: !this.state.changeClassroomModalIsOpen
    });
  };

  onSingleExamChange = () => {
    this.switchChangeSingleExamModalStatus();
  }

  switchChangeSingleExamModalStatus = () => {
    this.setState({
      ...this.state,
      changeSingleExamModalIsOpen: !this.state.changeSingleExamModalIsOpen
    });
  };

  onSingleCourseChange = () => {
    this.switchChangeSingleCourseModalStatus();
  }

  switchChangeSingleCourseModalStatus = () => {
    this.setState({
      ...this.state,
      changeSingleCourseModalIsOpen: !this.state.changeSingleCourseModalIsOpen
    });
  };

  setClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  setCloseModal = () => {
    this.setClose();
  };

  sort = (sortkey: string, order_by: string) => {
    this.state.filters.order_by = sortkey + '_' + order_by;
    this.setState(this.state);
    this.getStatusHistoryDistributions();
  };

  onPageChange = (page: number) => {
    this.state.filters.page = page;
    this.setState(this.state);
    this.getStatusHistoryDistributions();
  };

  onFormReset = () => {
    this.state.filters = Object.assign({}, DistributorModalSearchInitialValues);
    this.setState(this.state);
    this.getStatusHistoryDistributions();
  };

  static getDerivedStateFromProps(props: Types.IDistributorPageProps, state: Types.IDistributorPageState) {
    let hasNewState: boolean = false;

    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    let termType = this.props && this.props.term_type > -1 ? this.props.term_type : -1;

    let statusHistoryList = this.props && this.props.result && this.props.result.status_history_list;

    let statusHistoryMessage = this.props.result && this.props.result.status_message ? this.props.result.status_message : T.t('gen_failed')

    let isConfirmOptionalErrorsButtonVisible = this.props && this.props.result ?
      (!this.props.result.is_error_fatal && this.props.result.status == DistributorJobStatus.ImpossibilityTest_Error)
      : false;

    let bestSuccessRate = this.props.result ? this.props.result.best_success_rate : 0;
    let meanSuccessRate = this.props.result ? this.props.result.mean_success_rate : 0;
    let bestSuccessRateClassroom = this.props.result ? this.props.result.best_success_rate_classroom : 0;

    let isPublishStage = this.props.result && this.props.result.status && this.props.result.solution_count ?
      (this.props.result.solution_count > 0 && (
        (termType == 1 && (this.props.result.status == DistributorJobStatus.InvigilatorAssignment_Ready || this.props.result.status == DistributorJobStatus.Completed_Successfully)) ||
        (termType == 0 && (this.props.result.status == DistributorJobStatus.ClassroomAssignment_Ready || this.props.result.status == DistributorJobStatus.Completed_Successfully))
      ))
      : false;


    let CompatibilitiesAndButtons = (
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            {bestSuccessRate > 0 ?
              <div className="col-md-2 col-sm-12 form-input form-group with-icon">
                <input
                  id="best_compatibility"
                  name="best_compatibility"
                  type="text"
                  value={bestSuccessRate}
                  disabled={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = e.target.value;
                    this.setState({
                      ...this.state,
                      best_compatibility: newValue
                    });
                  }}
                />
                <label htmlFor="status_log">
                  {termType == 1 ? T.t('gen_best_compatibility') :
                    (termType == 0 ? T.t('gen_course_best_compatibility') :
                      T.t('gen_error'))}
                </label>
              </div>
              : null}
            {meanSuccessRate > 0 ?
              < div className="col-md-2 col-sm-12 form-input form-group with-icon">
                <input
                  id="average_compatibility"
                  name="average_compatibility"
                  type="text"
                  value={meanSuccessRate}
                  disabled={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = e.target.value;
                    this.setState({
                      ...this.state,
                      average_compatibility: newValue
                    });
                  }}
                />
                <label htmlFor="status_log">{T.t('gen_average_compatibility')}</label>
              </div>
              : null}
            {termType == 0 && bestSuccessRateClassroom > 0 ?
              < div className="col-md-2 col-sm-12 form-input form-group with-icon">
                <input
                  id="best_compatibility_classroom"
                  name="best_compatibility_classroom"
                  type="text"
                  value={bestSuccessRateClassroom}
                  disabled={true}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const newValue = e.target.value;
                    this.setState({
                      ...this.state,
                      best_compatibility_classroom: newValue
                    });
                  }}
                />
                <label htmlFor="status_log">{T.t('gen_classroom_best_compatibility')}</label>
              </div>
              : null}
            <div className="col-12 mt-1">
              {isConfirmOptionalErrorsButtonVisible ?
                <button
                  id='button_list_sync'
                  className="secondary float-right pr-4 pl-4 mr-3"
                  onClick={this.showInfoConfirmOptionalErrors}>
                  <i className="material-icons">done_all</i>
                  <span>{T.t('gen_validate')}</span>
                </button>
                : null
              }
            </div>
            {
              termType == 1 && this.props.result != undefined && (isPublishStage
                || this.props.result.status == DistributorJobStatus.Calculations_Error
                || this.props.result.status == DistributorJobStatus.ClassroomAssignment_Error
                || this.props.result.status == DistributorJobStatus.InvigilatorAssignment_Error)
                ?
                <div className="col-md-12">
                  {this.props.result.status >= DistributorJobStatus.Calculations_Ready ?
                    <button
                      id='change_solution'
                      className="secondary float-left pr-2 pl-2 mr-3"
                      style={{ margin: "15px 0px 0px 0px" }}
                      onClick={this.onSolutionChange}
                    >
                      <i className="material-icons">grading</i>
                      <span> {T.t('gen_change_solution')}</span>
                    </button>
                    : null}
                  {this.props.result.status >= DistributorJobStatus.InvigilatorAssignment_Ready ?
                    <button
                      id='button_reassign_invigilator_auto'
                      className="secondary float-left pr-2 pl-4 mr-3"
                      style={{ margin: "15px 0px 0px 0px" }}
                      onClick={this.showInfoReassignInvigilatorsAuto}>
                      <i className="material-icons">people</i>
                      <span>{T.t('gen_change_invigilator') + ' (' + T.t('gen_automatic') + ')'}</span>
                    </button>
                    : null}
                  {this.props.result.status >= DistributorJobStatus.InvigilatorAssignment_Ready ?
                    <button
                      id='button_reassign_invigilator_manual'
                      className="secondary float-left pr-2 pl-4 mr-3"
                      style={{ margin: "15px 0px 0px 0px" }}
                      onClick={this.onReassignInvigilatorsManual}
                    >
                      <i className="material-icons">person_search</i>
                      <span>{T.t('gen_change_invigilator') + ' (' + T.t('gen_manual') + ')'}</span>
                    </button>
                    : null}
                  {this.props.result.status >= DistributorJobStatus.InvigilatorAssignment_Ready && this.props.result.status != DistributorJobStatus.InvigilatorAssignment_Error ?
                    <button
                      id='button_change_single_exam'
                      className="secondary float-left pr-2 pl-4 mr-3"
                      style={{ margin: "15px 0px 0px 0px" }}
                      onClick={this.onSingleExamChange}
                    >
                      <i className="material-icons">content_paste_go</i>
                      <span>{T.t('gen_change_single_exam')}</span>
                    </button>
                    : null}
                  {this.props.result.status >= DistributorJobStatus.InvigilatorAssignment_Ready && this.props.result.status != DistributorJobStatus.InvigilatorAssignment_Error ?
                    <button
                      id='submit_solution1'
                      className="primary float-right pr-4 pl-4 mr-3"
                      style={{ margin: "15px 0px 0px 0px" }}
                      onClick={this.onSubmitSolution}>
                      <i className="material-icons">publish</i>
                      <span>{T.t('gen_submit_solution')}</span>
                    </button>
                    : null}
                </div> :

                termType == 0 && this.props.result != undefined && (isPublishStage
                  || this.props.result.status == DistributorJobStatus.Calculations_Error
                  || this.props.result.status == DistributorJobStatus.ClassroomAssignment_Error)
                  ?
                  <div className="col-md-12">
                    {this.props.result.status >= DistributorJobStatus.Calculations_Ready ?
                      <button
                        id='change_solution'
                        className="secondary float-left pr-2 pl-2 mr-3"
                        style={{ margin: "15px 0px 0px 0px" }}
                        onClick={this.onSolutionChange}
                      >
                        <i className="material-icons">grading</i>
                        <span> {T.t('gen_change_solution')}</span>
                      </button>
                      : null}
                    {this.props.result.status >= DistributorJobStatus.ClassroomAssignment_Ready && this.state.status != DistributorJobStatus.ClassroomAssignment_Error ?
                      <button
                        id='button_change_classroom'
                        className="secondary float-left pr-2 pl-4 mr-3"
                        style={{ margin: "15px 0px 0px 0px" }}
                        onClick={this.onClassroomChange}>
                        <i className="material-icons mr-2" style={{ color: 'black' }}>business</i>
                        <span style={{ color: 'black' }}>{T.t('gen_change_classroom')}</span>
                      </button>
                      : null}
                    {this.props.result.status >= DistributorJobStatus.ClassroomAssignment_Ready && this.props.result.status != DistributorJobStatus.ClassroomAssignment_Error ?
                      <button
                        id='button_change_single_course'
                        className="secondary float-left pr-2 pl-4 mr-3"
                        style={{ margin: "15px 0px 0px 0px" }}
                        onClick={this.onSingleCourseChange}
                      >
                        <i className="material-icons mr-2" style={{ color: 'black' }}>content_paste_go</i>
                        <span style={{ color: 'black' }}>{T.t('gen_change_single_course')}</span>
                      </button>
                      : null}
                    {this.props.result.status >= DistributorJobStatus.ClassroomAssignment_Ready && this.props.result.status != DistributorJobStatus.ClassroomAssignment_Error ?
                      <button
                        id='submit_solution2'
                        className="primary float-right pr-4 pl-4 mr-3"
                        style={{ margin: "15px 0px 0px 0px" }}
                        onClick={this.onSubmitSolution}>
                        <i className="material-icons mr-2">publish</i>
                        <span>{T.t('gen_submit_solution')}</span>
                      </button>
                      : null}
                  </div>

                  : null}
          </div>
        </div>
      </div >
    );

    let StatusBar = (
      <div className="row">
        <div className="col-md-10 col-sm-12 mt-2">
          <span className="badge badge-secondary" style={{ fontSize: '95%', textAlign: 'center' }}>
            {statusHistoryMessage}
          </span>
        </div>
      </div>
    );

    let Table = (
      <div className="row">
        <div className="col-12">
          <div className="quick-actions">
            <hr />
            <div className="row">
              <div className="col-9 mt-1">
                <h6>{T.t('gen_status_history')}</h6>
                <span>{T.t('gen_status_history_info')}</span>
                <hr />
              </div>
              <div className="col-3 mt-1">
                <button
                  id='button_list_sync'
                  className="secondary float-right pr-4 pl-4 mr-3"
                  style={{ margin: "15px 0px 0px 0px" }}
                  onClick={this.showInfoModalStatusHistory}>
                  <i className="material-icons">cloud_download</i>
                  <span>{T.t('gen_download')}</span>
                </button>
              </div>
            </div>
          </div>
        </div >
        <div className="col-12">
          <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
            <thead>
              <tr>
                <th scope="col">
                  {T.t('gen_number')}
                </th>
                <SortedColumn
                  datacell="message"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_message')}
                  sortkey="message"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <SortedColumn
                  datacell="time"
                  className="d-none d-lg-table-cell d-xl-table-cell text-center"
                  title={T.t('gen_date') + ' ' + T.t('gen_time')}
                  sortkey="time"
                  sortedcolumn={this.state.filters.order_by}
                  sort={this.sort}
                />
                <th scope="col" className="text-center">
                  {T.t('gen_actions')}
                </th>
              </tr>
            </thead>
            <tbody>
              {statusHistoryList && statusHistoryList.length ? (
                statusHistoryList.map((item: any) => {
                  let statusHistoryId = item.id ? item.id : -1;
                  let formattedErrorMessages = item.message.formattedErrorMessage ? item.message.formattedErrorMessage : []
                  return (
                    <tr key={'status-history-' + statusHistoryId} data-title={item.distribution_number}>
                      <td style={{ width: '5%', verticalAlign: 'top' }} className="text-center" data-label={T.t('gen_number')}>
                        {item.id}
                      </td>
                      <td data-label={T.t('gen_message')}>
                        {item.message.errorMessage}
                        {formattedErrorMessages && formattedErrorMessages.length ?
                          <table className="aplan-table aplan-table-responsive table table-borderless table-striped table-hover sortable filter-table">
                            <thead>
                              <tr>
                                <th scope="col">
                                  {T.t('gen_number')}
                                </th>
                                <th className="text-center" scope="col">
                                  {T.t('gen_details')}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {formattedErrorMessages.map((item: any, index: any) => {
                                return (
                                  <tr key={'status-history-detail-' + index}>
                                    <td style={{ width: '5%', verticalAlign: 'top' }} className="text-center" data-label={T.t('gen_number')}>
                                      {index + 1}
                                    </td>
                                    <td data-label={T.t('gen_details')}>
                                      {item}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          : null}
                      </td>
                      <td style={{ width: '13%', verticalAlign: 'top' }} className="text-center" data-label={T.t('gen_date') + ' ' + T.t('gen_time')}>
                        {item.time}
                      </td>
                      <td style={{ width: '5%', verticalAlign: 'center', }} data-label={T.t('gen_actions')}>
                        <button
                          id='button_redirect'
                          data-toggle="tooltip"
                          title={T.t('gen_redirect')}
                          className="secondary square btn-sm"
                        // onClick={() => {
                        //   this.onStopDistribution(DistributorActions.Redirect, item)
                        // }}
                        >
                          <span className="d-block" data-target="#addNew">
                            <i className="material-icons">arrow_forward</i>
                          </span>
                        </button>

                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={24}>
                    <Announce title={T.t('gen_no_records_found')} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div >

    );

    return (
      <Modal
        className="pt-0"
        style={{ maxWidth: '95%', padding: '0 15px' }}
        isOpen={this.props.modalIsOpen}
        toggle={this.setCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h6 className="modal-title d-inline-flex align-items-center" id="exampleModalLabel">
              {T.t('gen_status_history')}
            </h6>
            <button id='button_close' type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.setCloseModal}>
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            {CompatibilitiesAndButtons}
            <div className="white-container mt-4">
              <div className="row">
                <div className="col-xl-12 col-lg-12 col-md-12 courses">
                  {StatusBar}
                  {Table}
                </div>
              </div>
            </div>
          </div>
          {this.state.changeSolutionModalIsOpen && (
            <ChangeSolutionModal
              jobId={this.props.jobId}
              modalIsOpen={this.state.changeSolutionModalIsOpen}
              onClose={this.switchChangeSolutionModalStatus}
            />
          )}
          {this.state.reassignInvigilatorsManualModalIsOpen && (
            <ChangeInvigilatorModal
              jobId={this.props.jobId}
              modalIsOpen={this.state.reassignInvigilatorsManualModalIsOpen}
              onClose={this.switchReassignInvigilatorsManualModalStatus}
            />
          )}
          {
            this.state.changeClassroomModalIsOpen && (
              <ChangeClassroomModal
                jobId={this.props.jobId}
                modalIsOpen={this.state.changeClassroomModalIsOpen}
                onClose={this.switchChangeClassroomModalStatus}
              />
            )
          }
          {this.state.changeSingleExamModalIsOpen && (
            <ChangeSingleExamModal
              jobId={this.props.jobId}
              modalIsOpen={this.state.changeSingleExamModalIsOpen}
              onClose={this.switchChangeSingleExamModalStatus}
            />
          )}
          {this.state.changeSingleCourseModalIsOpen && (
            <ChangeSingleCourseModal
              jobId={this.props.jobId}
              modalIsOpen={this.state.changeSingleCourseModalIsOpen}
              onClose={this.switchChangeSingleCourseModalStatus}
            />
          )}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.IDistributorPageProps): Types.IDistributorPageProps => {
  if (!store) {
    return ownProps;
  }
  const newProps: Types.IDistributorPageProps = Object.assign({}, ownProps, {
    result: store.state.distributor_status_history_page && store.state.distributor_status_history_page.result,
    term_type: store.state.term_type,
    term_id: store.state.term_id,
  });
  return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.examPeriodModal) {
    return (
      !!equal(
        prev.state.distributor_status_history_page && prev.state.distributor_status_history_page.result,
        next.state.distributor_status_history_page && next.state.distributor_status_history_page.result
      ) &&
      !!equal(
        prev.state && prev.state.term_type,
        next.state && next.state.term_type
      ) &&
      !!equal(
        prev.state && prev.state.term_id,
        next.state && next.state.term_id
      )
    );
  } else {
    return true;
  }
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(DistributorStatusHistoryModal);

export default container;
