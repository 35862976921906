import { Image as AntImage } from 'antd';
import React from 'react';
import { MdCloudDownload, MdRemoveRedEye } from 'react-icons/md';
import { Image } from '../../../../pages/sketches/tools/interfaces';
import { Image as ImageEnum } from '../../../../pages/sketches/tools/interfaces';
import Button from '../../../button';
import Translator from '../../../../services/translate-factory';
import fallbackImage from '../../../../assets/images/fallback.jpg';
import { Badge } from '../../../ui/Badge';
import { GoStar, GoStarFill } from "react-icons/go";

interface ImageCardProps {
  image: Image
  allImages?: ImageEnum[];
  onDelete: () => void;
  onPreview?: () => void;
  onImageChange?: (images: ImageEnum[]) => void;
  selectableHeader?: boolean;
}

const ImageCard: React.FC<ImageCardProps> = ({ onPreview, image, onDelete, onImageChange, allImages, selectableHeader }) => {
  const T = Translator.create();
  const parseTimestamp = (timestamp: number) => {
    return new Date(timestamp * 1000)
      .toLocaleString(undefined, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      });
  };

  const handleDownload = async () => {
    const fileURL = image.src;
    const fileName = image.name;
    try {
      const response = await fetch(fileURL);
      if (!response.ok) throw new Error('Download failed');

      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName || 'downloaded-file';
      link.click();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Download failed, opening in a new tab...', error);
      window.open(fileURL, '_blank');
    }
  };

  const isBase64Url = (url: string) => {
    return url.startsWith('data:');
  }

  const handleSelectFavoriteImage = () => {
    if (allImages) {
      const newImages = allImages.map((img) => {
        if (img.id === image.id) {
          return {
            ...img,
            isFavorited: !img.isFavorited
          }
        }
        return { ...img, isFavorited: false };
      });
      onImageChange && onImageChange(newImages);
    }
  }

  return (
    <div className={`image-card ${image.isFavorited ? 'favorited' : ''}`}>
      <div className="image-card__content">
        <div className="image-card__image-container">
          <AntImage
            preview={false}
            style={{
              objectFit: "contain",
              borderRadius: "4px",
              alignItems: "center",
              justifyItems: "center",
              maxHeight: "13rem"
            }}
            onClick={(e) => e.preventDefault()}
            src={image.src}
            fallback={fallbackImage}
            alt={image.alt} />
          {isBase64Url(image.src) && <Badge className="image-card__badge">{T.t("gen_new")}</Badge>}
        </div>
        <div className="image-card__controls">
          {selectableHeader && (
            <Button
              onClick={handleSelectFavoriteImage}
              size='md'
              onlyIcon
              color={image.isFavorited ? 'primary' : 'tertiary'}
              className=""
              title={image.isFavorited ? T.t("gen_head_image") : T.t("gen_set_head_image")}
            >
              {image.isFavorited ? <GoStarFill /> : <GoStar />}
            </Button>
          )}
          <div className="image-card__action-buttons">
            <Button
              onClick={handleDownload}
              size='disable'
              onlyIcon
              color='tertiary'
              className="square"
            >
              <MdCloudDownload />
            </Button>
            <Button
              size='disable'
              onlyIcon
              color='tertiary'
              onClick={onPreview}
              className="image-card__action-btn"
            >
              <MdRemoveRedEye />
            </Button>
          </div>
        </div>
      </div>
      <div className="image-card__footer">
        <div className="image-card__info">
          <span title={image.name} className="image-card__title"><b>{image.name}</b></span>
          <span className="image-card__timestamp">{image.timestamp && parseTimestamp(image.timestamp)}</span>
        </div>
        <Button size='sm' icon='delete' className='' color='red' onClick={onDelete}>
          {T.t("gen_delete")}
        </Button>
      </div>
    </div>
  );
};

export default ImageCard;
