import React from "react"

export const LegendComponent = ({ items }: { items: Array<{ title: string, bgColor: string, textColor: string }> }) => {
    return <div className="legend-component">
        {items.map((item, index) => <LegendItem key={item.title} {...item} />)}
    </div>
}

const LegendItem = ({ bgColor, textColor, title }: { bgColor: string, textColor: string, title: string }) => {
    return <div className="legend-item">
        <div style={{ backgroundColor: bgColor, color: textColor }} className="legend-box">{title[0]}</div>
        <span>{title}</span>
    </div>
}