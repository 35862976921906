import { Formik, FormikProps } from 'formik';
import moment from 'moment';
import 'moment/locale/tr';
import { Log } from 'ng2-logger';
import React, { Component } from 'react';
import Flatpickr from 'react-flatpickr';
import { connect } from 'react-redux';
import Announcement from '../../assets_aplan/images/announcement.svg';
import * as Actions from '../../store/actions/general';
import * as Constants from '../../store/constants/all';
import Select from 'react-select';
import { ValueType } from 'react-select/lib/types';
import * as Types from '../../store/types';
import * as GT from '../../tools/general-tools';
import Translator from '../../services/translate-factory';
import { TermWeekDatesInitialValues } from '../../store/constants/term-week-const';
import { TermWeeksValidation } from './validations/term-weeks-dates.val';

const T = Translator.create();
const Logger = Log.create('ExamDates');

function getInitialState(): Types.ITermWeekDatesState {
  return Object.assign({}, TermWeekDatesInitialValues);
}

class TermWeekDates extends Component<Types.ITermWeekDatesProps, Types.ITermWeekDatesState> {
  state: Types.ITermWeekDatesState = getInitialState();

  langChanged = () => {
    setTimeout(() => {
      try {
        this.forceUpdate();
      } catch (e) {
        Logger.error(e as string);
      }
    }, 1000);
  };

  componentDidMount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
    this.getTermWeekDates();
  }

  constructor(props: any) {
    super(props)
    this.state.locale = GT.getLocaleFromLangCode();
  }

  componentWillUnmount() {
    T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, this.langChanged);
  }

  getTermWeekDates() {
    let model: Types.IFilterExamPeriodCalendarModal = {
      term_id: this.props.term_id,
      page: 1,
      total: -1,
      size: 10,
      status: [1]
    };
    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.COURSE_PERIOD_GET_TERM_WEEK_DATES, model, 'exam-period-modal-tab-spin')
    );
    this.setState(this.state);
  }

  onClickCreate = (values: any) => {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status === 200 || status === 201) {
        this.props.dispatch(Actions.Notification('gen_term_week_dates_are_created', 'gen_success'))
        this.getTermWeekDates();
      }
    };
    let model = {
      start_date: values.start_date,
      end_date: values.end_date,
      term_id: this.props.term_id,
      total_weeks: values.total_weeks,
      holidays: this.state.holidays,
      exclusions: this.state.exclusions,
      term_week_schedule: this.state.term_week_schedule
    }
    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.COURSE_PERIOD_PUT_TERM_WEEK_DATES, model, 'exam-period-modal-tab-spin', resultCallback)
    );
  }

  onChangedWeekDates = (index: number) => {
    if ((this.state.term_week_schedule && this.state.term_week_schedule[index].start_date != this.state.term_week_schedule_start_values + 'T00:00:00'
      || this.state.term_week_schedule && this.state.term_week_schedule[index].end_date != this.state.term_week_schedule_end_values + 'T00:00:00')
      && this.state.term_week_schedule_start_values != 'NaN-NaN-NaN' && this.state.term_week_schedule_start_values != undefined && this.state.term_week_schedule_start_values != null
      && this.state.term_week_schedule_end_values != 'NaN-NaN-NaN' && this.state.term_week_schedule_end_values != undefined && this.state.term_week_schedule_end_values != null
    ) {
      let startDate = new Date(this.state.term_week_schedule_start_values);
      let endDate = new Date(this.state.term_week_schedule_end_values);

      if (index == 0) {
        if (startDate >= endDate) {
          this.props.dispatch(Actions.Notification(T.t('gen_week_dates_selected_start_week_notification_info'), T.t('gen_warning'), 'danger'))
        } else {
          this.onClickChangeWeekDates(index);
        }
      }

      if (index != 0 && this.state.term_week_schedule && this.state.term_week_schedule.length - 1 != index) {
        let nextStartDate = new Date(this.state.term_week_schedule && this.state.term_week_schedule[index + 1].start_date);
        let prevEndDate = new Date(this.state.term_week_schedule && this.state.term_week_schedule[index - 1].end_date);
        if (prevEndDate > startDate) {
          this.props.dispatch(Actions.Notification(T.t('gen_week_dates_selected_must_be_grater_than_prev'), T.t('gen_warning'), 'danger'))
        } else if (nextStartDate < endDate) {
          this.props.dispatch(Actions.Notification(T.t('gen_week_dates_selected_must_be_less_than_next'), T.t('gen_warning'), 'danger'))
        } else if (startDate > endDate || nextStartDate < startDate) {
          this.props.dispatch(Actions.Notification(T.t('gen_week_dates_selected_start_week_notification_info'), T.t('gen_warning'), 'danger'))
        } else if (endDate < startDate || prevEndDate > endDate) {
          this.props.dispatch(Actions.Notification(T.t('gen_week_dates_selected_end_week_notification_info'), T.t('gen_warning'), 'danger'))
        } else {
          this.onClickChangeWeekDates(index);
        }
      }

      if (this.state.term_week_schedule && this.state.term_week_schedule.length - 1 == index) {
        if (endDate <= startDate) {
          this.props.dispatch(Actions.Notification(T.t('gen_week_dates_selected_end_week_notification_info'), T.t('gen_warning'), 'danger'))
        } else {
          this.onClickChangeWeekDates(index);
        }
      }
    } else {
      this.props.dispatch(Actions.Notification(T.t('gen_week_dates_notification_info'), T.t('gen_warning'), 'danger'))
    }
  }

  onClickChangeWeekDates = (index: number) => {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status === 200 || status === 201) {
        this.props.dispatch(Actions.Notification((index + 1) + T.t('gen_week_dates_are_updated'), 'gen_success'))
        this.getTermWeekDates();
      }
    };
    let newAddedTermWeekSchedule = [
      {
        week_no: index + 1,
        start_date: this.state && this.state.term_week_schedule_start_values,
        end_date: this.state && this.state.term_week_schedule_end_values
      }
    ]
    const filtered_state_term_week_schedule = this.state && this.state.term_week_schedule!.filter((item) => item.week_no !== (index + 1));
    let model = {
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      term_id: this.props.term_id,
      total_weeks: this.state.total_weeks,
      holidays: this.state.holidays,
      exclusions: this.state.exclusions,
      term_week_schedule:
        [...filtered_state_term_week_schedule, ...newAddedTermWeekSchedule].sort(function (a, b) {
          return a.week_no - b.week_no;
        })
    }
    this.props.dispatch(
      Actions.ApiRequest(Constants.exam_period.COURSE_PERIOD_PUT_TERM_WEEK_DATES, model, 'exam-period-modal-tab-spin', resultCallback)
    );

    this.state.term_week_schedule_start_values = undefined;
    this.state.term_week_schedule_end_values = undefined;
    this.setState(this.state);
  }

  onEnterHolidayDates = () => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_dates_of_holidays'),
        body:
          <React.Fragment>
            <Formik
              initialValues={this.state}
              enableReinitialize={true}
              onSubmit={(values, _actions) => {
                this.onClickCreate(values)
              }}
            >
              {(props: FormikProps<Types.ITermWeekDatesState>) => {
                const { values, handleChange, handleBlur, handleSubmit } = props;
                this.state.holiday_name = props.values.holiday_name;
                if ((this.state && this.state.term_week_schedule && this.state.term_week_schedule.map((i: any) => i.start_date))!.length === 0) {
                  return (
                    <div>
                      <img
                        style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          width: '40%'
                        }}
                        src={Announcement}
                      />
                      <br />
                      <p style={{ textAlign: 'center' }}>{T.t('gen_term_week_dates_will_be_created_warning')}</p>
                    </div>
                  )
                } else {
                  let date = this.state.term_week_schedule!.map(x => x.week_no + '. ' + x.start_date.split('T')[0] + ' / ' + x.end_date.split('T')[0]);
                  const dateSelectOptions = date.map((item: any) => ({ label: item, value: item.split('. ')[1] }));
                  return (
                    <div className="row">
                      <div className="col-md-12 form-input pt-3">{T.t('gen_holiday_info')}</div>
                      <div className="col-md-12 form-input pt-3">
                        <div className="add-custom-tag">
                          <div className="react-select-container">
                            <Select
                              className="react-select"
                              isMulti={false}
                              filterOption={(option: any, query: any) =>
                                option.label.toLocaleLowerCase('TR').includes(query.toLocaleLowerCase('TR'))
                              }
                              closeMenuOnSelect={true}
                              options={dateSelectOptions}
                              placeholder={T.t('gen_select_holiday_week')}
                              value={this.state.date_exclution ? this.state.date_exclution : null}
                              onChange={(
                                options: ValueType<Types.ISelectOption>,
                              ) => {
                                const list: Types.ISelectOption = options
                                  ? (options as Types.ISelectOption)
                                  : { label: '', value: '' };

                                props.setFieldValue('date_exclution', list);
                                this.state.date_exclution = list;
                              }}
                              noOptionsMessage={(): string => T.t('gen_select_no_dates')}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 form-input form-group with-icon mt-3 float-left">
                        <Flatpickr
                          placeholder={T.t('gen_select_dates')}
                          value={this.state.holidays_start_date}
                          disabled={this.state && this.state.date_exclution === undefined ? true : false}
                          options={{
                            dateFormat: 'Y-m-d',
                            mode: 'single',
                            locale: this.state.locale,
                            defaultDate: this.state.holidays_start_date,
                            maxDate: this.state && this.state.date_exclution && this.state.date_exclution.value.split(' / ')[1],
                            minDate: this.state && this.state.date_exclution && this.state.date_exclution.value.split(' / ')[0]
                          }}
                          onClose={(value) => {
                            this.state.holidays_start_date = value.map((item) => moment(item).format('YYYY-MM-DD'))[0];
                          }}
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label htmlFor="term_start_date">{T.t('gen_holiday_start_date')}</label>
                      </div>
                      <div className="col-md-6 form-input form-group with-icon mt-3 float-left">
                        <Flatpickr
                          placeholder={T.t('gen_select_dates')}
                          value={this.state.holidays_end_date}
                          disabled={this.state && this.state.holidays_start_date === undefined ? true : false}
                          options={{
                            dateFormat: 'Y-m-d',
                            mode: 'single',
                            locale: this.state.locale,
                            defaultDate: "today",
                            maxDate: this.state && this.state.date_exclution && this.state.date_exclution.value.split(' / ')[1],
                            minDate: this.state && this.state.holidays_start_date
                          }}
                          onClose={(value) => {
                            this.state.holidays_end_date = value.map((item) => moment(item).format('YYYY-MM-DD'))[0];
                          }}
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label htmlFor="term_end_date">{T.t('gen_holiday_end_date')}</label>
                      </div>
                      <div className="col-md-12 form-input form-group with-icon mt-3">
                        <input
                          id="holiday_name"
                          name="holiday_name"
                          value={this.state.holiday_name}
                          onChange={props.handleChange}
                          type="text"
                          required
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label htmlFor="holiday_name">{T.t('gen_holiday_name_description')}</label>
                      </div>
                    </div>
                  )
                }

              }}
            </Formik>
          </React.Fragment>,
        name: 'dates_of_holidays',
        icon: 'info',
        iconColor: 'green',
        confirm: T.t('gen_add'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          if (this.state.holidays_end_date && this.state.holidays_start_date && this.state.holiday_name != "" && this.state.date_exclution != null) {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                this.state = TermWeekDatesInitialValues;
                this.getTermWeekDates();
              }
            };
            const first_date: any = moment(this.state.holidays_start_end_date_range && this.state.holidays_start_end_date_range[0]);
            const second_date: any = moment(this.state.holidays_start_end_date_range && this.state.holidays_start_end_date_range[1]);
            const total_day = second_date.diff(first_date, 'days');
            let newAddedHoliday = [
              {
                id: this.state.date_exclution.label.split('.')[0],
                start_date: this.state.holidays_start_date,
                end_date: this.state.holidays_end_date,
                name: this.state.holiday_name,
                total_days: total_day
              }
            ]
            const model = {
              start_date: this.state.start_date,
              end_date: this.state.end_date,
              term_id: this.props.term_id,
              total_weeks: this.state.total_weeks,
              term_week_schedule: this.state && this.state.term_week_schedule,
              holidays: this.state.holidays !== undefined ? [...this.state.holidays, ...newAddedHoliday] : [...newAddedHoliday],
              exclusions: this.state.exclusions
            };
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.COURSE_PERIOD_PUT_TERM_WEEK_DATES,
                model,
                'exam-period-modal-tab-spin',
                resultCallback
              )
            );
          } else {
            this.props.dispatch(Actions.Notification('gen_list_isnot_left_blank', 'gen_warning', 'warning'));
          }
        }
      })
    );
  }

  onEnterExclusionDates = () => {
    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_exclusion_week'),
        body:
          <React.Fragment>
            <Formik
              initialValues={this.state}
              enableReinitialize={true}
              onSubmit={(values, _actions) => {
                this.onClickCreate(values)
              }}
            >
              {(props: FormikProps<Types.ITermWeekDatesState>) => {
                const { values, handleChange, handleBlur, handleSubmit } = props;

                this.state.exclusion_name = props.values.exclusion_name;
                let date = values.term_week_schedule!.map(x => x.week_no + '. ' + x.start_date.split('T')[0] + ' / ' + x.end_date.split('T')[0]);
                const dateSelectOptions = date.map((item: any) => ({ label: item, value: item.split('. ')[1] }));

                return (
                  <div className="row">
                    <div className="col-md-12 float-center" >{T.t('gen_exclution_info')}</div>
                    <div className="col-md-12 form-input pt-3">
                      <div className="add-custom-tag">
                        <div className="react-select-container">
                          <Select
                            className="react-select"
                            isMulti={false}
                            filterOption={(option: any, query: any) =>
                              option.label.toLocaleLowerCase(T.getSelectedLanguage()).includes(query.toLocaleLowerCase(T.getSelectedLanguage()))
                            }
                            closeMenuOnSelect={true}
                            options={dateSelectOptions}
                            placeholder={T.t('gen_select_start_and_end_dates')}
                            value={this.state.date_exclution ? this.state.date_exclution : null}
                            onChange={(
                              options: ValueType<Types.ISelectOption>,
                            ) => {
                              const list: Types.ISelectOption = options
                                ? (options as Types.ISelectOption)
                                : { label: '', value: '' };

                              props.setFieldValue('date_exclution', list);
                              this.state.date_exclution = list;
                            }}
                            noOptionsMessage={(): string => T.t('gen_select_no_dates')}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12 form-input with-icon">
                      <input
                        id="exclusion_name"
                        name="exclusion_name"
                        value={props.values.exclusion_name}
                        onChange={props.handleChange}
                        type="text"
                        required
                      />
                      <span className="highlight" />
                      <span className="bar" />
                      <label htmlFor="exclusion_name">{T.t('gen_exclusion_name')}</label>
                    </div>
                  </div>
                )
              }}
            </Formik>
          </React.Fragment>,
        name: 'dates_of_exclusions',
        icon: 'info',
        iconColor: 'green',
        confirm: T.t('gen_add'),
        cancel: T.t('gen_cancel'),
        onConfirm: () => {
          if (this.state.date_exclution && this.state.exclusion_name != "") {
            const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
              if (status == 200) {
                this.state = TermWeekDatesInitialValues;
                this.getTermWeekDates();
              }
            };
            const first_date: any = moment(this.state.exclusions_start_end_date_range && this.state.exclusions_start_end_date_range[0]);
            const second_date: any = moment(this.state.exclusions_start_end_date_range && this.state.exclusions_start_end_date_range[1]);
            const total_day = second_date.diff(first_date, 'days');

            let newAddedExclusion = [
              {
                id: Number(this.state && this.state.date_exclution && this.state.date_exclution.label.split('. ')[0]),
                start_date: this.state && this.state.date_exclution && this.state.date_exclution.value.split(' / ')[0],
                end_date: this.state && this.state.date_exclution && this.state.date_exclution.value.split(' / ')[1],
                name: this.state.exclusion_name,
                total_days: total_day
              }
            ]
            const model = {
              start_date: this.state.start_date,
              end_date: this.state.end_date,
              term_id: this.props.term_id,
              total_weeks: this.state.total_weeks,
              term_week_schedule: this.state && this.state.term_week_schedule,
              holidays: this.state.holidays,
              exclusions: this.state.exclusions !== undefined ? [...this.state.exclusions, ...newAddedExclusion] : [...newAddedExclusion]
            };
            this.props.dispatch(
              Actions.ApiRequest(
                Constants.exam_period.COURSE_PERIOD_PUT_TERM_WEEK_DATES,
                model,
                'exam-period-modal-tab-spin',
                resultCallback
              )
            );
          } else {
            this.props.dispatch(Actions.Notification('gen_list_isnot_left_blank', 'gen_warning', 'warning'));
          }
        }
      })
    );
  }

  formatDate(date: any) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;
    return [year, month, day].join('-');
  }

  onDeleteExclution = (id: number) => {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status == 200) {
        this.getTermWeekDates();
      }
    };
    if (id) {
      this.props.dispatch(
        Actions.ApiRequest(
          Constants.exam_period.COURSE_PERIOD_DELETE_EXCLUTION,
          id,
          'exclution-delete-spin',
          resultCallback
        )
      );
    }
  };

  onDeleteHoliday = (id: number) => {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status == 200) {
        this.getTermWeekDates();
      }
    };
    if (id) {
      this.props.dispatch(
        Actions.ApiRequest(
          Constants.exam_period.COURSE_PERIOD_DELETE_HOLIDAY,
          id,
          'holiday-delete-spin',
          resultCallback
        )
      );
    }
  };

  onTermWeekDelete = () => {
    const resultCallback = (result: Types.IApiErrorResponse, status: number) => {
      if (status == 200) {
        this.props.dispatch(Actions.Notification('gen_term_week_dates_deleted', 'gen_success'))
        this.getTermWeekDates();
      }
    };

    this.props.dispatch(
      Actions.ShowModal({
        title: T.t('gen_term_week_dates_will_be_deleted_warning'),
        body: T.t('gen_are_you_sure_you_want_to_continue'),
        name: 'term_week_dates_delete',
        icon: 'error_outline',
        iconColor: 'red',
        confirm: T.t('gen_yes'),
        cancel: T.t('gen_no'),
        onConfirm: () => {
          const model = {
            start_date: '',
            end_date: '',
            term_id: this.props.term_id,
            total_weeks: this.state.total_weeks,
            term_week_schedule: this.state && this.state.term_week_schedule,
            holidays: this.state.holidays,
            exclusions: this.state.exclusions
          };
          this.props.dispatch(
            Actions.ApiRequest(
              Constants.exam_period.COURSE_PERIOD_PUT_TERM_WEEK_DATES,
              model,
              'exam-period-modal-tab-spin',
              resultCallback
            )
          );
        }
      })
    );
  };

  static getDerivedStateFromProps(props: Types.ITermWeekDatesProps, state: Types.ITermWeekDatesState) {
    let hasNewState: boolean = false;
    if (props.results_term_week_dates != undefined) {
      state = props.results_term_week_dates;
      hasNewState = true;
    }
    if (hasNewState) {
      return state;
    } else {
      return null;
    }
  }

  render() {
    return (
      <div className="row">
        <div className="col-xl-3 col-lg-3 col-md-4 mb-3 tw-sticky tw-top-20 tw-h-fit">
          <Formik
            initialValues={this.state}
            enableReinitialize={true}
            onSubmit={(values, _actions) => {
              this.onClickCreate(values)
            }}
            onReset={this.onTermWeekDelete}
            validationSchema={TermWeeksValidation(T)}
          >
            {(props: FormikProps<Types.ITermWeekDatesState>) => {
              const { values, handleChange, handleBlur, handleSubmit } = props;
              return (
                <form onSubmit={props.handleSubmit}>
                  <div className="generic-wrapper">
                    <div className="row">
                      <div className="col-12">
                        <h6 className="mb-3">{T.t('gen_term_week_dates')}</h6>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 form-input form-group with-icon mt-3">
                        <Flatpickr
                          placeholder={T.t('gen_select_dates')}
                          value={values.start_date}
                          disabled={(this.state && this.state.term_week_schedule && this.state.term_week_schedule.map((i: any) => i.start_date))!.length === 0 ? false : true}
                          options={{
                            dateFormat: 'Y-m-d',
                            mode: 'single',
                            locale: this.state.locale,
                            defaultDate: values.start_date
                          }}
                          onClose={(value) => {
                            values.start_date = value.map((item) => moment(item).format('YYYY-MM-DD'))[0];
                            this.state.start_date = values.start_date;
                          }}
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label htmlFor="term_start_date">{T.t('gen_term_start_date')}</label>
                        {props.errors.start_date && props.submitCount > 0 && (
                          <div className="error">{props.errors.start_date}</div>
                        )}
                      </div>
                      <div className="col-md-6 form-input form-group with-icon mt-3">
                        <Flatpickr
                          placeholder={T.t('gen_select_dates')}
                          value={values.end_date}
                          disabled={(this.state && this.state.term_week_schedule && this.state.term_week_schedule.map((i: any) => i.start_date))!.length === 0 ? false : true}
                          options={{
                            dateFormat: 'Y-m-d',
                            mode: 'single',
                            locale: this.state.locale,
                            defaultDate: "today"
                          }}
                          onClose={(value) => {
                            values.end_date = value.map((item) => moment(item).format('YYYY-MM-DD'))[0];
                            this.state.end_date = values.end_date
                          }}
                        />
                        <span className="highlight" />
                        <span className="bar" />
                        <label htmlFor="term_end_date">{T.t('gen_term_end_date')}</label>
                        {props.errors.end_date && props.submitCount > 0 && (
                          <div className="error">{props.errors.end_date}</div>
                        )}
                      </div>
                      <div className="form-input form-group float-left m-4">
                        <h6 > {T.t('gen_total_number_of_weeks')} : </h6>
                      </div>
                      <div className="form-input form-group float-left w-25">
                        <input
                          className="text-center pb-1"
                          id="total_weeks"
                          name="total_weeks"
                          disabled={true}
                          value={props.values.total_weeks}
                          onChange={props.handleChange}
                          type="number"
                        />
                        <span className="highlight" />
                        <span className="bar" />
                      </div>
                    </div>
                    <div className="col-12 text-right">
                      {(this.state && this.state.term_week_schedule && this.state.term_week_schedule.map((i: any) => i.start_date))!.length === 0 ? (
                        <button
                          type="button"
                          onClick={() => {
                            props.handleSubmit();
                          }}
                          className="primary mt-md-0 mt-3 mb-md-0 mb-2 float-none w-100"
                          style={{ outline: 'none ' }}
                        >
                          <i className="material-icons mr-2 text-left">add</i>
                          <span>{T.t('gen_create')}</span>
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="danger mt-md-0 mt-2 mb-md-0 mb-2 float-none w-100"
                          style={{ outline: 'none ' }}
                          onClick={props.handleReset}
                        >
                          <i className="material-icons mr-2 text-left">close</i>
                          <span>{T.t('gen_delete')}</span>
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              );
            }}
          </Formik>
        </div>
        {/* TERM WEEK SCHEDULE */}
        <div className="col-xl-9 col-lg-9 col-md-8 collapse" id="aplan-time-matrix" style={{ display: 'block' }}>
          <div className="generic-wrapper pt-4">
            <Formik
              initialValues={this.state}
              enableReinitialize={true}
              onSubmit={(values, _actions) => {
                this.onClickCreate(values)
              }}
            >
              {(props: FormikProps<Types.ITermWeekDatesState>) => {
                const { values, handleChange, handleBlur, handleSubmit } = props;
                if ((this.state && this.state.term_week_schedule && this.state.term_week_schedule.map((i: any) => i.start_date))!.length === 0) {
                  return (
                    <div>
                      <img
                        style={{
                          display: 'block',
                          marginLeft: 'auto',
                          marginRight: 'auto',
                          width: '40%'
                        }}
                        src={Announcement}
                      />
                      <br />
                      <p style={{ textAlign: 'center' }}>{T.t('gen_term_week_dates_will_be_created_warning')}</p>
                    </div>
                  )
                } else {
                  return (
                    <div className="col-12 mb-4">
                      <table className="aplan-table aplan-table-responsive table table-striped table-hover sortable filter-table" style={{ border: '1px #f2f2f2 solid' }}>
                        <tbody>
                          {this.state && this.state.term_week_schedule && this.state.term_week_schedule.map((i: any, index: any) => {
                            values.term_week_schedule_values = [i.start_date, i.end_date]
                            return (
                              <tr key={index} style={{ width: '100%' }}>
                                <td style={{ width: '20%' }} className="text-center">
                                  {i.week_no}. {T.t('gen_week')}
                                </td>
                                <td style={{ width: '80%' }}>
                                  <form className="col-12" onSubmit={props.handleSubmit}>
                                    <div className="row">
                                      <div className="col-md-5 form-input form-group with-icon mt-3">
                                        <Flatpickr
                                          placeholder={T.t('gen_select_dates')}
                                          value={values.term_week_schedule_values[0]}
                                          options={{
                                            dateFormat: 'Y-m-d',
                                            mode: 'single',
                                            locale: this.state.locale,
                                            defaultDate: values.term_week_schedule_values[0],
                                            maxDate: values.term_week_schedule_values[1],
                                            minDate: values.term_week_schedule_values[0]
                                          }}
                                          onClose={(value) => {
                                            values.term_week_schedule_start_values = this.formatDate(value.map((item) => item.toDateString()));
                                            this.state.term_week_schedule_start_values = this.formatDate(value.map((item) => item.toDateString()));
                                            this.state.term_week_no = index;
                                          }}
                                        />
                                        <span className="highlight" />
                                        <span className="bar" />
                                        <label htmlFor="week_start_date">{T.t('gen_week_start_date')}</label>
                                      </div>
                                      <div className="col-md-5 form-input form-group with-icon mt-3">
                                        <Flatpickr
                                          placeholder={T.t('gen_select_dates')}
                                          value={values.term_week_schedule_values[1]}
                                          options={{
                                            dateFormat: 'Y-m-d',
                                            mode: 'single',
                                            locale: this.state.locale,
                                            defaultDate: values.term_week_schedule_values[1],
                                            maxDate: values.term_week_schedule_values[1],
                                            minDate: values.term_week_schedule_values[0]
                                          }}
                                          onClose={(value) => {
                                            values.term_week_schedule_end_values = this.formatDate(value.map((item) => item.toDateString()));
                                            this.state.term_week_schedule_end_values = this.formatDate(value.map((item) => item.toDateString()));
                                            this.state.term_week_no = index;
                                          }}
                                        />
                                        <span className="highlight" />
                                        <span className="bar" />
                                        <label htmlFor="week_end_date">{T.t('gen_week_end_date')}</label>
                                      </div>
                                      <div className="col-2 ">
                                        <button
                                          type="button"
                                          className="tertiary mt-4 mb-2"
                                          style={{ outline: 'none ' }}
                                          onClick={() => {
                                            this.onChangedWeekDates(index);
                                          }}
                                        >
                                          <span>{T.t('gen_update')}</span>
                                        </button>
                                      </div>
                                    </div>
                                  </form>
                                </td>
                              </tr>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  )
                }
              }}
            </Formik>
            {/* EXCLUSIONS */}
            {
              this.props.results_term_week_dates.term_week_schedule && this.props.results_term_week_dates.term_week_schedule.map((i: any) => i.start_date)!.length === 0 ? null :
                <>
                  <div className="row align-items-center mt-4 mb-2">
                    <div className="col-3 ml-3 mb-2">
                      <button
                        type="button"
                        onClick={() => {
                          this.onEnterExclusionDates();
                        }}
                        className="tertiary"
                      >
                        <span>{T.t('gen_enter_dates_of_exclusions')}</span>
                      </button>
                    </div>
                    <div className="col-6 text-center">
                      <h5>{T.t('gen_exclusions')}</h5>
                    </div>
                    <div className="col-3"></div>
                  </div>
                  <div className="col-12 mb-4">
                    <table className="aplan-table aplan-table-responsive table table-striped table-hover sortable filter-table" style={{ border: '1px #f2f2f2 solid' }}>
                      <thead>
                        <tr>
                          <th scope="col" className="text-center">
                            {T.t('gen_exclusion_name')}
                          </th>
                          <th scope="col" className="text-center">
                            {T.t('gen_week_start_date')}
                          </th>
                          <th scope="col" className="text-center">
                            {T.t('gen_week_end_date')}
                          </th>
                          <th scope="col" className="text-center">
                            {T.t('gen_exclusion_total_days')}
                          </th>
                          <th scope="col" className="text-center">
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.exclusions && this.state.exclusions.map((i: any, index: any) => {
                          return (
                            <tr key={index}>
                              <td className="text-center">
                                {i.name}
                              </td>
                              <td className="text-center">
                                {i.start_date.split("T")[0]}
                              </td>
                              <td className="text-center">
                                {i.end_date.split("T")[0]}
                              </td>
                              <td className="text-center">
                                {i.total_days}
                              </td>
                              <td className="text-center">
                                <button
                                  className="ms-table-delete-button"
                                  onClick={() => this.onDeleteExclution(i.id)}
                                >
                                  <i className="material-icons pr-1 pl-1">delete_outline</i>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                  {/* HOLIDAYS */}
                  <div className="row align-items-center mt-6 mb-2">
                    <div className="col-3 ml-3">
                      <button
                        type="button"
                        onClick={() => {
                          this.onEnterHolidayDates();
                        }}
                        className="tertiary"
                      >
                        <span>{T.t('gen_enter_dates_of_holidays')}</span>
                      </button>
                    </div>
                    <div className="col-6 text-center">
                      <h5>{T.t('gen_dates_of_holidays')}</h5>
                    </div>
                    <div className="col-3"></div>
                  </div>
                  <div className="col-12">
                    <table className="aplan-table aplan-table-responsive table table-striped table-hover sortable filter-table" style={{ border: '1px #f2f2f2 solid' }}>
                      <thead>
                        <tr>
                          <th scope="col" className="text-center">
                            {T.t('gen_holiday_name')}
                          </th>
                          <th scope="col" className="text-center">
                            {T.t('gen_holiday_start_date')}
                          </th>
                          <th scope="col" className="text-center">
                            {T.t('gen_holiday_end_date')}
                          </th>
                          <th scope="col" className="text-center">
                            {T.t('gen_holiday_total_days')}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.holidays && this.state.holidays.map((i: any, index: any) => {
                          return (
                            <tr key={index}>
                              <td className="text-center">
                                {i.name}
                              </td>
                              <td className="text-center">
                                {i.start_date.split("T")[0]}
                              </td>
                              <td className="text-center">
                                {i.end_date.split("T")[0]}
                              </td>
                              <td className="text-center">
                                {i.total_days}
                              </td>
                              <td className="text-center">
                                <button
                                  className="ms-table-delete-button"
                                  onClick={() => this.onDeleteHoliday(i.id)}
                                >
                                  <i className="material-icons pr-1 pl-1">delete_outline</i>
                                </button>
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store: Types.IPersistedState, ownProps: Types.ITermWeekDatesProps): Types.ITermWeekDatesProps => {
  if (!store || !store.state) {
    return ownProps;
  }
  const newProps: Types.ITermWeekDatesProps = Object.assign({}, ownProps, {
    state: store.state,
    results_term_week_dates: store.state && store.state.examPeriodModal && store.state.examPeriodModal.resultsTermWeekDates,
    term_id: store.state.term_id
  });
  return newProps;
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
  if (next.state.integration) {
    return (
      !!equal(
        prev.state.examPeriodModal && prev.state.examPeriodModal.resultsTermWeekDates,
        next.state.examPeriodModal && next.state.examPeriodModal.resultsTermWeekDates
      ) &&
      !!equal(
        prev.state && prev.state.term_id,
        next.state && next.state.term_id
      )
    );
  } else {
    return true;
  }
};

const container = connect(mapStateToProps, dispatchProps, null, {
  areStatesEqual
})(TermWeekDates);

export default container;
