import * as Yup from 'yup';
import Translator from '../../../services/translate-factory';
import { specialCharacterRegex } from '../../../util/regex';

export const ClassroomFormValidation = (T: Translator) => Yup.object().shape(classroomFormFields(T));

export const classroomFormFields = (T: Translator) => ({
  classroom_code: Yup.string().required(T.t('gen_cannot_leave_empty')).max(200, T.t('gen_can_be_up_to_n').replace('{0}', '200'))
    .matches(specialCharacterRegex,
      T.t('gen_cannot_enter_special_characters'),
    ),
  name: Yup.string().required(T.t('gen_cannot_leave_empty')).max(50, T.t('gen_can_be_up_to_n').replace('{0}', '50'))
    .matches(specialCharacterRegex,
      T.t('gen_cannot_enter_special_characters'),
    ),
  classroom_type: Yup.string().required(T.t('gen_cannot_leave_empty')),
  building_id: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  door_order: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  building_floor: Yup.number().required(T.t('gen_cannot_leave_empty')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  description: Yup.string().max(1000, T.t('gen_can_be_up_to_n').replace('{0}', '1000')),
  area: Yup.number().min(0, T.t('gen_cannot_be_less_than_n').replace('{0}', '0')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  volume: Yup.number().min(0, T.t('gen_cannot_be_less_than_n').replace('{0}', '0')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  count_windows: Yup.number().min(0, T.t('gen_cannot_be_less_than_n').replace('{0}', '0')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  invigilator_count: Yup.number().min(0, T.t('gen_cannot_be_less_than_n').replace('{0}', '0')).max(99, T.t('gen_can_be_up_to_n_number').replace('{0}', '99')),
  exam_capacity: Yup.number().min(0, T.t('gen_cannot_be_less_than_n').replace('{0}', '0')).max(1000, T.t('gen_can_be_up_to_n_number').replace('{0}', '1000')),
  lecture_capacity: Yup.number().min(0, T.t('gen_cannot_be_less_than_n').replace('{0}', '0')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
  user_capacity: Yup.number().min(0, T.t('gen_cannot_be_less_than_n').replace('{0}', '0')).max(9999999, T.t('gen_can_be_up_to_n_number').replace('{0}', '9999999')),
})