import React from 'react';
import { IAppState, IPageProps, IAction } from '../../store/types';
import * as Actions from '../../store/actions/general';
import imgLogo from '../../assets/logos/ct_long.png';
import { connect } from 'react-redux';
import { Log } from 'ng2-logger';
const L = Log.create('LoaderPage');

class LoaderPage extends React.Component<any, any> {
  render() {
    return (
      <div className="site-loader">
        <div className="loader-container">
          <div className="loader-content">
            <div className="spinner-wrapper">
              <div className="spinner">
                <div className="half-circle"></div>
              </div>
              <div className="logo-wrapper">
                <img src={imgLogo} alt="Logo" className="logo" />
              </div>
            </div>
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: IAppState, ownProps: any): any => {
  if (!state) {
    return ownProps;
  }
  return ownProps;
};

const dispatchProps = (dispatch: React.Dispatch<IAction>) => ({
  dispatch
});

const component = connect(mapStateToProps, dispatchProps)(LoaderPage);

export default component;
