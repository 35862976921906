import React from "react";
import ReactSwitch, { ReactSwitchProps } from "react-switch";

const Switch: React.FC<ReactSwitchProps> = (props) => {
    return (
        <ReactSwitch
            handleDiameter={20}  // Varsayılan handle boyutu
            height={25}          // Varsayılan yükseklik
            width={40}           // Varsayılan genişlik
            offColor="#E8ECED"   // Kapalı durum rengi
            onColor="#358730"    // Açık durum rengi
            checkedIcon={false}  // Tik ikonunu kaldır
            uncheckedIcon={false} // Çarpı ikonunu kaldır
            {...props}           // Kullanıcının geçtiği diğer tüm proplar
        />
    );
};

export default Switch;
