import React, { useState, useEffect } from 'react'
import cn, { flexCenter, flexCol, flexIC, flexJB, flexRow, gap1, gap2, gridCol1, gridCol2, gridCol3 } from '../../ui/Tailwind'
// UPPY CSS
import '@uppy/image-editor/dist/style.min.css';
import '@uppy/status-bar/dist/style.min.css';
import "@uppy/core/dist/style.css";
import "@uppy/dashboard/dist/style.css";
// UPPY
import Uppy from '@uppy/core'
import { Dashboard } from '@uppy/react'
import English from '@uppy/locales/lib/en_US'
import Turkish from '../../../util/UppyLocalization'
import XHR from '@uppy/xhr-upload';
import ImageEditor from '@uppy/image-editor'
import Compressor from '@uppy/compressor';
// General
import { connect } from 'react-redux';
import * as Actions from '../../../store/actions/general';
import * as Types from '../../../store/types';
import * as GT from '../../../tools/general-tools';
import Translator from '../../../services/translate-factory';
import Paginate from '../../table/paginate'
import EditableTitle from './ui/EditableTitle'
import UploadButton from './ui/UploadButton'
import ImageGrid from './ui/ImageGrid'
import MockAPIClient from '../../../services/mock-api-client';
import { BuildingFloor, Image } from '../../../pages/sketches/tools/interfaces'
import Button from '../../button'
import { Modal } from 'antd'
import { debounce } from 'lodash';
import * as Constants from '../../../store/constants/all';
import Warning from '../../warning';
import Switch from '../../Input/switch';
import SelectField from '../../Input/select-field';

const T = Translator.create();

declare const appSettings: Types.IAppSettings;

interface ImageDropzoneProps {
    uploadAreaText: string;
    editPlaceHolder?: string;
    title?: string;
    imageList?: Image[] | Image;
    type: "default" | "building";
    maxFiles?: number;
    maxLengthFileNames?: number;
    gridCol?: 1 | 2 | 3;
    subTitle?: string;
    setImages: (newImages: Image[] | Image) => void
    setBuildingName?: (newImages: string | undefined) => void
    setStatus?: (newStatus: boolean) => void
    setFloor?: (newFloor: BuildingFloor) => void
    floor?: BuildingFloor
    dispatch?: any;
    errorMessage?: string;
    selectableHeader?: boolean;
    checkImageIsSqure?: boolean;
    activeToggle?: boolean;
    activeStatus?: boolean;
    selectableFloors?: Types.ISelectOption[]
}

const ImageDropzoneIn: React.FC<Readonly<ImageDropzoneProps>> = ({
    setImages,
    dispatch,
    subTitle,
    gridCol = 2,
    maxFiles = 10,
    maxLengthFileNames = 20,
    editPlaceHolder,
    uploadAreaText,
    title,
    type = "default",
    imageList,
    setBuildingName = (newVal) => { },
    setFloor,
    setStatus,
    floor,
    errorMessage,
    selectableHeader,
    checkImageIsSqure = false,
    activeToggle = false,
    activeStatus = false,
    selectableFloors
}) => {
    // Uppy
    const [uppyLocale, setUppyLocale] = useState(English);
    const [uppy, setUppy] = useState<Uppy>();
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 4; // Sayfa başına görüntü sayısı
    const [padding, setPadding] = useState(0)
    const [modalVisible, setModalVisible] = useState(false)
    const [disableUpload, setDisableUpload] = useState(false)

    const [, forceRender] = React.useReducer(x => x + 1, 0);

    const handleLanguageChange = React.useCallback(
        debounce(() => {
            forceRender(1);
            reloadUppyLocale()
        }, 1000),
        []
    );
    useEffect(() => {
        T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        T.addListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        window.scrollTo(0, 0);
        return () => {
            T.removeListener(Constants.gen.CORE_CHANGE_LANGUAGE, handleLanguageChange);
        };
    }, [])

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const getPaginatedImages = () => {
        if (maxFiles === 1) {
            return imageList
        }
        const filteredImages = Array.isArray(imageList)
            ? imageList.filter((image) => !image.deleted)
            : [];
        const startIndex = (currentPage - 1) * pageSize;
        if (padding !== startIndex) {
            setPadding(startIndex)
        }
        return filteredImages.slice(startIndex, startIndex + pageSize);
    };


    const _papi: MockAPIClient = new MockAPIClient()

    const getCurrentTimestamp = (): number => {
        return Math.floor(Date.now() / 1000);
    };

    useEffect(() => {
        reloadUppyLocale()
    }, []);

    const reloadUppyLocale = () => {
        const appLocale = GT.getLocaleCode();
        if (appLocale === 'tr-TR') {
            setUppyLocale(Turkish);
        } else {
            setUppyLocale(English);
        }
    }

    const getNewUppyInstance = () => {
        const instance = new Uppy({
            autoProceed: false,
            restrictions: {
                minNumberOfFiles: null,
                maxNumberOfFiles: maxFiles,
                maxFileSize: 5041230, // 5MB
                allowedFileTypes: ['image/jpeg', 'image/png', 'image/bmp'],
            },
            locale: uppyLocale
        })
            .use(ImageEditor)
            .use(XHR, {
                endpoint: `${appSettings.API_URL}/integration/validate-image`,
                fieldName: 'file',
                headers: {
                    Authorization: `Bearer ${_papi._accessToken}`,
                },
                formData: true,
            })
            .use(Compressor, { quality: 0.9 })
            .on("complete", async (result: any) => {
                const successfulFiles = result.successful;

                if (successfulFiles.length > 0) {
                    let updatedImages: Image[] = []
                    if (imageList) {
                        updatedImages = Array.isArray(imageList) ? imageList : [imageList]
                    }

                    const uploadedImage: any[] = getUploadFile(successfulFiles, updatedImages);

                    const newImages = [...updatedImages, ...uploadedImage];
                    setImages(newImages);

                    setModalVisible(false);
                }
            })

        if (checkImageIsSqure) {
            instance
                .on("file-added", imageSquareHandler)
                .on('file-editor:complete', imageSquareHandler)
        }

        return instance
    }

    const imageSquareHandler = (file: any) => {
        try {
            const fileBlob = file.data;
            const image = new window.Image();
            const objectURL = URL.createObjectURL(fileBlob);

            image.src = objectURL;

            image.onload = () => {
                const { width, height } = image;

                // isSquare
                if (width === height) {
                    setDisableUpload(false)
                } else {
                    setDisableUpload(true)
                }

                // release memmory
                URL.revokeObjectURL(objectURL);
            };

        } catch (error) {
            console.error('Image cannot be controlled: ', file.name);
        }
    }

    // Uppy config
    useEffect(() => {
        const uppyInstance = getNewUppyInstance();
        setUppy(uppyInstance);

        return () => {
            uppyInstance && uppyInstance.destroy()
        };
    }, [uppyLocale, maxFiles, imageList]);

    const getUploadFile = (successfulFiles: any[], updatedImages: Image[] | undefined) => {

        return successfulFiles.map((file, index) => {
            const base64SRC = `data:${file.type};base64,${file.response.body.container}`
            return ({
                id: getCurrentTimestamp() + "_" + (updatedImages && updatedImages.length + index),
                src: file.uploadURL || base64SRC || "", // file.uploadURL yüklenen dosyanın URL'si olacak
                name: file.name,
                timestamp: getCurrentTimestamp(),
                index: updatedImages && updatedImages.length + index,
                base64string: file && file.response && file.response.body && file.response.body.container,
                deleted: false,
            })
        });
    };

    const showRemoveModal = (callback: () => void) => {
        dispatch(
            Actions.ShowModal({
                title: T.t('gen_delete_action'),
                body: T.t("gen_continue"),
                name: 'building_delete',
                icon: 'delete',
                iconColor: 'red',
                confirm: T.t('gen_yes'),
                cancel: T.t('gen_cancel'),
                onConfirm: callback
            }))
    }

    const handleDelete = (index: string) => {
        showRemoveModal(() => removeImage(index))
    };

    const handleDeleteFloor = () => {
        showRemoveModal(() => removeFloor())
    }

    const removeFloor = () => {
        if (setFloor && floor) {
            setFloor({
                ...floor,
                name: "deleted",
                deleted: true
            })
        }
    }

    const changeActiveFloor = (checked: boolean) => {
        if (setFloor && floor) {
            setFloor({
                ...floor,
                isActive: checked
            })
        }
    }

    useEffect(() => {
        fixPaginatedImages()
    }, [imageList])

    const removeImage = (index: string) => {
        if (Array.isArray(imageList)) {
            const updateImages = imageList.map(p => {
                if (p.id === index) {
                    return {
                        ...p,
                        deleted: true
                    }
                }
                return p
            })

            setImages(updateImages);
        } else if (imageList) {
            setImages({
                ...imageList,
                deleted: true
            })
        }
    }

    const fixPaginatedImages = () => {
        if (!checkPageHasImages()) {
            if (currentPage > 1)
                handlePageChange(currentPage - 1)
        }
    }

    const checkPageHasImages = () => {
        const paginatedImages = getPaginatedImages()
        if (Array.isArray(paginatedImages)) {
            return paginatedImages.length > 0
        } else {
            return paginatedImages && !paginatedImages.deleted
        }
    }

    const getGrid = () => {
        switch (gridCol) {
            case 1:
                return gridCol1
            case 2:
                return gridCol2
            case 3:
                return gridCol3
            default:
                return gridCol1
        }
    }
    const shouldShowUploadButton = () => {
        if (Array.isArray(imageList)) {
            const nonDeletedImagesCount = imageList.filter(image => !image.deleted).length;
            return nonDeletedImagesCount < maxFiles;
        } else {
            if (imageList)
                return imageList.deleted;
            return true
        }
    };
    const showTitleHeader = () => {
        return title || subTitle || editPlaceHolder || floor
    }

    return (
        <div className={cn(flexCol, gap2)}>
            {showTitleHeader() && <div className={getGrid()}>
                <h6 className={cn(flexRow, gap2, flexIC, flexJB)}>
                    {type === "building" ? (
                        <div className={cn(flexCol, 'tw-w-full')}>
                            <div className='tw-flex tw-flex-row tw-justify-between tw-items-center tw-w-full'>
                                <EditableTitle
                                    onSave={setBuildingName}
                                    title={title}
                                    subTitle={subTitle}
                                    editPlaceHolder={editPlaceHolder}
                                />
                                <div className={cn(flexRow, gap2, "tw-justify-end")}>
                                    {
                                        activeToggle &&
                                        <div className={cn(flexRow, gap1, flexIC)}>
                                            <div style={{
                                                color: floor && floor.isActive ? '#19213A' : 'gray',
                                                fontSize: '12px'
                                            }}>
                                                {T.t('gen_show_and_hide')}
                                            </div>
                                            <Switch
                                                id="show_public_course_solution"
                                                className="react-switch tw-ml-0"
                                                onChange={changeActiveFloor}
                                                checked={floor && floor.isActive || false}
                                            />
                                        </div>
                                    }
                                    {
                                        floor &&
                                        <Button key={floor.id} color='genericRed' onClick={() => handleDeleteFloor()}>
                                            {T.t("gen_remove_floor")}
                                        </Button>
                                    }
                                </div>
                            </div>
                            {errorMessage && <span className='tw-text-red-500 tw-text-xs'>{errorMessage}</span>}
                        </div>
                    ) : (
                        <>
                            <div>
                                {title}
                            </div>
                            {
                                activeToggle && setStatus &&
                                <div className={cn(flexRow, gap1, flexIC)}>
                                    <div
                                        style={{
                                            color: activeStatus ? '#19213A' : 'gray',
                                            fontSize: '12px'
                                        }}>
                                        {T.t('gen_show_and_hide')}
                                    </div>
                                    <Switch
                                        id="show_public_course_solution"
                                        className="react-switch tw-ml-0"
                                        onChange={setStatus}
                                        checked={activeStatus}
                                    />
                                </div>
                            }
                        </>
                    )
                    }
                </h6>
            </div>}
            {type === "building" && floor ? <SelectField
                T={T}
                marginBottom={false}
                name="floor"
                value={floor.relatedFloors}
                onChange={(value) => {
                    if (!setFloor) return
                    setFloor({
                        ...floor,
                        relatedFloors: value
                    })
                }}
                closeMenuOnSelect={false}
                options={selectableFloors}
                id="relatedFloors"
                placeholder={T.t('gen_select_related_floor')}
                isMulti={true}
            />
                : null
            }
            {
                shouldShowUploadButton() &&
                <UploadButton
                    onClick={() => setModalVisible(true)}
                    uploadAreaText={uploadAreaText}
                />
            }
            <ImageGrid
                gridStr={getGrid()}
                allImages={imageList || []}
                images={getPaginatedImages() || []}
                maxFiles={maxFiles}
                padding={padding}
                onDelete={handleDelete}
                selectableHeader={selectableHeader}
                onImageChange={setImages}
            />
            {maxFiles !== 1 && Array.isArray(imageList) && (
                <div className="row-options justify-content-end">
                    <div className="page-sorting d-flex align-items-center justify-content-center" style={{ marginTop: '5px' }}>
                        <Paginate
                            filters={{
                                page: currentPage,
                                size: pageSize,
                                total: imageList.filter((image) => !image.deleted).length,
                            }}
                            onPageChange={handlePageChange}
                        />
                    </div>
                </div>
            )}
            <Modal
                open={modalVisible}
                zIndex={1500}
                width='fit-content'
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}

                onCancel={() => setModalVisible(false)}
                afterClose={() => {
                    if (uppy) {
                        uppy.destroy()
                        const uppyInstance = getNewUppyInstance();
                        setUppy(uppyInstance);
                    }
                }}
            >
                <div className={cn(flexCenter, "tw-px-6", checkImageIsSqure ? "tw-pt-6" : "tw-py-6")}>
                    {
                        uppy && (
                            <div className={cn(flexCol, gap1, "tw-w-full tw-max-w-[40rem]")}>
                                <Dashboard
                                    note={checkImageIsSqure
                                        ? T.t('gen_upload_square_image')
                                        : T.t('gen_max_5_mb_file')}
                                    uppy={uppy}
                                    showProgressDetails
                                    proudlyDisplayPoweredByUppy={false}
                                    hideUploadButton={disableUpload}
                                />
                                {checkImageIsSqure && disableUpload && <Warning show={true}>
                                    <div className="tw-flex-wrap tw-items-center tw-gap-1 tw-break-words tw-inline-flex">
                                        {T.t('gen_institution_image_warning')}
                                    </div>
                                </Warning>}
                            </div>
                        )
                    }
                </div>
            </Modal>
        </div>
    );
};
// 
const mapStateToProps = (
    store: Types.IPersistedState,
    ownProps: ImageDropzoneProps
): ImageDropzoneProps => {
    if (!store) {
        return ownProps;
    }

    const newProps: ImageDropzoneProps = Object.assign({}, ownProps, {
        results: store.state.exam_period_page && store.state.exam_period_page.results,
        user: store.state.user,

    });
    return newProps;
};

const equal = require('deep-equal');
const areStatesEqual = (next: Types.IPersistedState, prev: Types.IPersistedState) => {
    return equal(next, prev);
};

const dispatchProps = (dispatch: any) => ({ dispatch });

const ImageDropzone = connect(mapStateToProps, dispatchProps, null, {
    areStatesEqual
})(ImageDropzoneIn);

export default ImageDropzone;
