import { put, takeEvery, all, take, select, call } from 'redux-saga/effects';
import * as Actions from '../actions/general';
import { constants } from '../constants/distributor-const';
import * as Types from '../types';
import MockAPIClient from '../../services/mock-api-client';
import Translator from '../../services/translate-factory';

const T = Translator.create();

function* getDistributors(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSearch(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getDistributorStatusHistory(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSearchStatusHistory(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* cancelDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionCancel(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_distribution_stopped', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}


function* getDistributorsInProgressStatus(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSearchInProgressStatus(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* createDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionCreate(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else if (response && response.status === 406) {
    yield put(Actions.Notification('notification_distribution_term_not_valid', 'gen_warning', 'danger'));
  } else if (response && (response.status === 403 || response.status === 409)) {
    yield put(
      Actions.ShowModal({
        body: content,
        title: T.t('gen_pending'),
        name: 'gen_pending',
        icon: 'warning',
        iconColor: 'red',
      })
    );
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* confirmDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionConfirm(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* downloadHistoryDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionDownloadHistory(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* stopCalculationDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionStopCalculation(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* resetMaxNumberOfAttemptsDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.ResetMaxNumberOfAttemptsDistribution(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else if (response && response.status === 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* reassignInvigilatorsAutoDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionReassignInvigilatorsAuto(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* solutionChangeGetSelectOptionsDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSolutionChangeGetSelectOptions(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getInfoSolutionChangeDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionGetInfoSolutionChange(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* setCourseTimeSlotDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSetCourseTimeSlot(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* solutionChangeDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSolutionChange(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else if (response && response.status === 406) {
    yield put(
      Actions.ShowModal({
        body: content,
        title: T.t('gen_warning'),
        name: 'deleted_courses_exist',
        icon: 'warning',
        iconColor: 'red',
        cancel: T.t('gen_close')
      })
    );
  } else if (response && response.status === 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* invigilatorChangeGetSelectOptionsDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let response: Response = yield MockAPI.GetResponse(MockAPI.DistributionInvigilatorChangeGetSelectOptions(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getInfoInvigilatorChangeDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionGetInfoInvigilatorChange(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* setExamInvigilatorDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSetExamInvigilator(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* invigilatorChangeDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionInvigilatorChange(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else if (response && response.status === 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* singleExamChangeGetSelectOptionsDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSingleExamChangeGetSelectOptions(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getInfoSingleExamChangeDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionGetInfoSingleExamChange(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* singleExamChangeDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSingleExamChange(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else if (response && response.status === 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* singleCourseChangeGetSelectOptionsDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSingleCourseChangeGetSelectOptions(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getInfoSingleCourseChangeDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionGetInfoSingleCourseChange(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* singleCourseChangeDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSingleCourseChange(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else if (response && response.status === 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* classroomChangeGetSelectOptionsDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  let response: Response = yield MockAPI.GetResponse(MockAPI.DistributionClassroomChangeGetSelectOptions(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* getInfoClassroomChangeDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionGetInfoClassroomChange(action.body));
  let content: any = null;

  if (response && response.status === 200) {
    content = yield MockAPI.GetContent(response);
  }
  if (action.callback && content) {
    action.callback(content);
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* setCourseClassroomDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSetCourseClassroom(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* classroomChangeDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionClassroomChange(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else if (response && response.status === 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* autoChangeDeletedCoursesDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionAutoChangeDeletedCourses(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
    yield put(Actions.Notification('notification_process_started', 'gen_success'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

function* submitSolutionDistribution(action: Types.IApiRequestAction): Generator<any, any, any> {
  const MockAPI: MockAPIClient = new MockAPIClient();
  const response: Response = yield MockAPI.GetResponse(MockAPI.DistributionSubmitSolution(action.body));
  let content: any = yield MockAPI.GetContent(response);

  if (response && response.status === 200) {
  } else if (response && response.status === 409) {
    yield put(Actions.Notification(content, 'gen_warning', 'danger'));
  } else {
    yield put(Actions.Notification('gen_error_occurred', 'gen_warning', 'danger'));
  }
  yield put(Actions.ApiResponse(action, response, content));
}

export function* watchDistributorSearch() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_GET_DISTRIBUTION_LIST, getDistributors);
}

export function* watchDistributorStatusHistorySearch() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_GET_STATUS_HISTORY, getDistributorStatusHistory);
}

export function* watchDistributorCancel() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_CANCEL_DISTRIBUTION, cancelDistribution);
}

export function* watchDistributorSearchInProgressStatus() {
  yield takeEvery((action: any) => action.target === constants.GET_DISTRIBUTION_IN_PROGRESS_STATUS, getDistributorsInProgressStatus);
}

export function* watchDistributorCreate() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_CREATE_DISTRIBUTION, createDistribution);
}

export function* watchDistributorConfirm() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_CONFIRM_DISTRIBUTION, confirmDistribution);
}

export function* watchDistributorDownloadHistory() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_DOWNLOAD_HISTORY, downloadHistoryDistribution);
}

export function* watchDistributorStopCalculation() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_STOP_CALCULATION, stopCalculationDistribution);
}

export function* watchDistributorResetMaxNumberOfAttempts() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_RESET_MAX_NUMBER_OF_ATTEMPTS, resetMaxNumberOfAttemptsDistribution);
}

export function* watchDistributorReassignInvigilatorsAuto() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_REASSIGN_INVIGILATORS_AUTO, reassignInvigilatorsAutoDistribution);
}

export function* watchDistributorSolutionChangeGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_SOLUTION_CHANGE_GET_SELECT_OPTIONS, solutionChangeGetSelectOptionsDistribution);
}

export function* watchDistributorGetInfoSolutionChange() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_GET_INFO_SOLUTION_CHANGE, getInfoSolutionChangeDistribution);
}

export function* watchDistributorSetCourseTimeSlot() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_SOLUTION_CHANGE_SET_COURSE_TIMESLOT, setCourseTimeSlotDistribution);
}

export function* watchDistributorSolutionChange() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_SOLUTION_CHANGE, solutionChangeDistribution);
}

export function* watchDistributorInvigilatorChangeGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_INVIGILATOR_CHANGE_GET_SELECT_OPTIONS, invigilatorChangeGetSelectOptionsDistribution);
}

export function* watchDistributorGetInfoInvigilatorChange() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_GET_INFO_INVIGILATOR_CHANGE, getInfoInvigilatorChangeDistribution);
}

export function* watchDistributorSetExamInvigilator() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_INVIGILATOR_CHANGE_SET_EXAM_INVIGILATOR, setExamInvigilatorDistribution);
}

export function* watchDistributorInvigilatorChange() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_INVIGILATOR_CHANGE, invigilatorChangeDistribution);
}

export function* watchDistributorSingleExamChangeGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_SINGLE_EXAM_CHANGE_GET_SELECT_OPTIONS, singleExamChangeGetSelectOptionsDistribution);
}

export function* watchDistributorGetInfoSingleExamChange() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_GET_INFO_SINGLE_EXAM_CHANGE, getInfoSingleExamChangeDistribution);
}

export function* watchDistributorSingleExamChange() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_SINGLE_EXAM_CHANGE, singleExamChangeDistribution);
}

export function* watchDistributorSingleCourseChangeGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_SINGLE_COURSE_CHANGE_GET_SELECT_OPTIONS, singleCourseChangeGetSelectOptionsDistribution);
}

export function* watchDistributorGetInfoSingleCourseChange() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_GET_INFO_SINGLE_COURSE_CHANGE, getInfoSingleCourseChangeDistribution);
}

export function* watchDistributorSingleCourseChange() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_SINGLE_COURSE_CHANGE, singleCourseChangeDistribution);
}

export function* watchDistributorClassroomChangeGetSelectOptions() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_CLASSROOM_CHANGE_GET_SELECT_OPTIONS, classroomChangeGetSelectOptionsDistribution);
}

export function* watchDistributorGetInfoClassroomChange() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_GET_INFO_CLASSROOM_CHANGE, getInfoClassroomChangeDistribution);
}

export function* watchDistributorSetCourseClassroom() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_CLASSROOM_CHANGE_SET_COURSE_CLASSROOM, setCourseClassroomDistribution);
}

export function* watchDistributorClassroomChange() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_CLASSROOM_CHANGE, classroomChangeDistribution);
}

export function* watchDistributorAutoChangeDeletedCourses() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_CLASSROOM_CHANGE_AUTO_CHANGE_DELETED_COURSES, autoChangeDeletedCoursesDistribution);
}

export function* watchDistributorSubmitSolution() {
  yield takeEvery((action: any) => action.target === constants.DISTRIBUTOR_SUBMIT_SOLUTION, submitSolutionDistribution);
}